import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import Layout from "../common/Layout";
import Loading from "../../core/common/Loading";
import fetchw from "../../util/fetchw";
import {useAppContext} from "../../core/common/AppContext";
import ProfileTooltip from "../components/ProfileTooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RenderSeasonName from "../admin/season/SeasonUtils";
import {swrParams, useDebug} from "../../util/hooks";
import useSWR from "swr";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up('sm')]: {
    rosterColumns: {
      webkitColumnCount: 3,
      mozColumnCount: 3,
      columnCount: 3
    }
  }
}))

function Roster() {
  const [{currentSeason}] = useAppContext()
  const classes = useStyles();
  const debug = useDebug()

  const {
    data: roster,
    error: rosterError
  } = useSWR(`/api/ensemble/roster/${currentSeason?.id}`, fetchw, swrParams(debug))

  const {
    data: sections,
    error: sectionError
  } = useSWR("/api/ensemble/admin/section/list", fetchw, swrParams(debug))

  const [sectionLeads, setSectionLeads] = useState(null)
  useEffect(() => {
    if (!sectionLeads && sections && !sectionError) {
      var ids = []
      for (const sect of sections) {
        if (sect.leaderPid) {
          ids.push(sect.leaderPid)
        }
      }
      setSectionLeads(ids)
    }
  }, [sectionLeads, setSectionLeads, sections, sectionError])

  const [count, setCount] = useState(-1)
  useEffect(() => {
    if (roster && !rosterError && count === -1) {
      console.log("ROSTER", roster)
      var c = 0;
      if (roster.status !== 204) {
        roster.map((ir) => {
          ir.members.map((mem) => {
            c++;
            return null;
          })
          return null;
        })
      }
      setCount(c)
    }
  }, [count, setCount, roster, rosterError])

  if (!currentSeason) {
    console.error("No season set")
    return <Layout>Sorry, the roster cannot be displayed.</Layout>
  }

  if (sectionError) {
    console.error("Error loading sections", rosterError)
    return <Layout>Sorry, the roster cannot be displayed.</Layout>
  }
  if (rosterError) {
    console.error("Error loading roster", rosterError)
    return <Layout>Sorry, the roster cannot be displayed.</Layout>
  }
  if (debug && roster) {
    console.log("Roster count is ", count)
  }
  if (!roster) {
    return <Loading msg={"roster"}/>
  }
  if (!sectionLeads) {
    return <Loading msg={"section leads"}/>
  }
  if (count < 0) {
    return <Loading msg={"count"}/>
  }
  if (roster && count >= 0 && sectionLeads) {
    return <Layout>
      <Typography variant={"h3"}>Member Roster for the <RenderSeasonName season={currentSeason}/> Season</Typography>
      <Typography variant={"body2"} gutterBottom>Total registered members: {count}</Typography>
      {roster.status !== 204 &&
          <>
            <Typography variant={"subtitle2"} gutterBottom>Mouse-over or tap/hold a name to view details.</Typography>
            <div className={classes.rosterColumns}>
              {
                roster.map((ir, key) => {
                  return <div key={key}>
                    <Typography variant={"h6"}>{ir.instrument.name}</Typography>
                    {
                      ir.members.map((mem, k2) => {
                        return <RosterEntry key={k2} instrument={ir.instrument} member={mem}
                                            isSectionLead={sectionLeads.includes(mem.pid)}/>
                      })
                    }
                  </div>
                })
              }
              <div>&nbsp;</div>
              <div>* section lead</div>
            </div>
          </>
      }
    </Layout>
  } else {
    return <Loading msg="roster"/>
  }

}


function RosterEntry({instrument, member, isSectionLead}) {
  return <>

    <span
      data-pid={member.pid}
      data-org={member.oid}
    >
      <ProfileTooltip uiprofile={member} placement={"top"}>
        <Typography variant={"body2"}>{member.fallbackStageName}{isSectionLead ? " *" : ""}</Typography>
      </ProfileTooltip>
    </span>
  </>
}

export default Roster