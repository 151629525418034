import React, {useEffect, useState} from 'react';
import fetchw from "../../../util/fetchw";
import {useDebug, useProfile} from "../../../util/hooks";
import PrettyDate from "../../../core/common/PrettyDate";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {EditButton} from "../../../core/common/theme/WarholButtons";
import {NavLink} from "react-router-dom";

export default function AttendanceCommitNextEvent(props) {

    const {profile} = useProfile()
    const debug = useDebug()

    const [loaded, setLoaded] = useState(false)
    const [entity, setEntity] = useState(null);
    useEffect(() => {
        if (!loaded && profile) {
            fetchw(`/api/ensemble/attendance/next/${profile.id}`)
                .then((res) => {
                    if (res.status === 204) {
                        if (debug) console.debug("No next event found");
                    } else {
                        setEntity(res);
                        if (debug) console.log("Loaded next attendance", res)
                    }
                    setLoaded(true)
                }, (error) => {
                    if (debug) console.log("Error loading next attendance ", error)
                })
        }
    }, [loaded, debug, profile, entity, setEntity])

    if (!entity) return null;

    const eventStartDate = Date.parse(entity.start)

    if (entity.yes) {
        return <>
            <Typography gutterBottom variant="h5">Attendance</Typography>
            <Typography component="div" variant="body1">
                <Box p={0} color="success.main">
                    You have committed to come to our next event ({entity.eventname} <PrettyDate
                    unixDate={eventStartDate}
                    format="ccc MMM dd yyyy 'at' h:mm a"
                />)
                    <EditButton component={NavLink} to={"/ensemble/attendance/commit"} fullWidth>Update Attendance
                        RSVP</EditButton>
                </Box>
            </Typography>
        </>
    }
    if (entity.no) {
        return <>
            <Typography gutterBottom variant="h5">Attendance</Typography>
            <Typography component="div" variant="body1">
                <Box p={0} color="error.main">You have informed us that you will not come to our next event
                    ({entity.eventname} <PrettyDate
                        unixDate={eventStartDate}
                        format="ccc MMM dd yyyy 'at' h:mm a"
                    />)
                    <EditButton component={NavLink} to={"/ensemble/attendance/commit"} fullWidth>Update Attendance
                        RSVP</EditButton>
                </Box>
            </Typography>
        </>
    }
    return <>
        <Typography gutterBottom variant="h5">Attendance</Typography>
        <Typography component="div" variant="body1">
            <Box p={0} color="warning.main">
                Please confirm your attendance at ({entity.eventname} <PrettyDate
                unixDate={eventStartDate}
                format="ccc MMM dd yyyy 'at' h:mm a"
            />)
                <EditButton component={NavLink} to={"/ensemble/attendance/commit"} fullWidth>Update Attendance
                    RSVP</EditButton>
            </Box>
        </Typography>
    </>
}