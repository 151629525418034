import React from 'react';
import Layout from "./common/Layout";
import ContactInfo from "./components/ContactInfo";
import ParentContactInfo from "./components/ParentContactInfo";
import EmergencyInfo from "./components/EmergencyInfo";

function ContactInfoPage() {
  return <Layout>
    <ContactInfo open={true}/>
    <ParentContactInfo open={true}/>
    <EmergencyInfo open={true}/>
  </Layout>
}

export default ContactInfoPage