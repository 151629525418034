import React from 'react';
import Layout from "../../common/Layout";
import {styled, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import fetchw from "../../../util/fetchw";
import Loading from "../../../core/common/Loading";
import useSWR from "swr";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import PrettyDate from "../../../core/common/PrettyDate";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover,
}));

export default function AttendanceGrid() {
    const {data, error} = useSWR(`/api/ensemble/attendance/season`, fetchw, {
        revalidateOnFocus: false,
        dedupingInterval: 15 * 60 * 1000,
    })

    if (!data && !error) return <Layout><Loading msg={"Loading attendance grid report"}/></Layout>
    if (!data) return <Layout><Typography>No data found.</Typography></Layout>
    // console.log("Grid:", data);


    let inst = "not known"
    return (
        <Layout>
            <Typography variant={"h2"}>Attendance This Season</Typography>
            <Table size="small">
                <TableHead>
                    <TableRow key={"headerRow"}>
                        <TableCell key={"memb"}>Member</TableCell>
                        <TableCell key={"abs"}>Absences</TableCell>
                        {
                            data.events.map((evnt, headerRowIndex) => {
                                const eventStartDate = Date.parse(evnt.startTime)
                                return <TableCell key={headerRowIndex}>
                                    <Typography sx={{textOrientation: 'sideways', writingMode: 'vertical-lr'}}>
                                        <PrettyDate unixDate={eventStartDate} format="MMM dd "/>
                                        {evnt.eventType === "rehearsal" ? "" : "(" + evnt.eventType + ")"}
                                    </Typography>
                                </TableCell>
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.multiAttendances.map((userAtt, index) => {
                            let printInst = false;
                            if (userAtt.profile.instrument !== inst) {
                                inst = userAtt.profile.instrument;
                                printInst = true;
                            }
                            let misses = 0;
                            userAtt.attendances.forEach(att => {
                                if (att.attendanceRecorded && att.didAttend === false) {
                                    misses++;
                                }
                            })
                            return <React.Fragment key={inst + "-" + index}>
                                {printInst && <StyledTableRow>
                                    <TableCell colSpan={data.events.length + 2}><Typography
                                        variant={"h6"}>{inst}</Typography></TableCell>
                                </StyledTableRow>}
                                <TableRow key={index}>
                                    <TableCell>{userAtt.profile.fallbackStageName}</TableCell>
                                    <TableCell>{misses}</TableCell>
                                    {
                                        userAtt.attendances.map((att, i2) => {

                                            if (att.attendanceRecorded === false)
                                                return <TableCell key={i2}></TableCell>
                                            if (att.didAttend === false) {
                                                return <TableCell key={i2}><Box color="error.main">NO</Box></TableCell>
                                            } else {
                                                return <TableCell key={i2}><Box
                                                    color="success.main">YES</Box></TableCell>
                                            }
                                        })
                                    }
                                </TableRow>
                            </React.Fragment>
                        })
                    }
                </TableBody>
            </Table>

        </Layout>
    )
}
