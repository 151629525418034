import React, {useEffect, useState} from 'react'
import {useGlobalContext} from "./GlobalContext";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import BugReportIcon from '@material-ui/icons/BugReport';
import RequireRole from "./RequireRole";
import {useDebug} from "../../util/hooks";

export default DebugLink;

function DebugLink() {

  const debug = useDebug()

  const [, update] = useGlobalContext()

  const [db, setDb] = useState(debug);

  useEffect(() => {
    update({debug: db})
  }, [debug, update, db])

  function toggle() {
    if (db) {
      console.log("Disabling Debug");
      setDb(false)
    } else {
      console.log("Enabling Debug");
      setDb(true);
    }
  }

  return (
    <RequireRole role={"sysadmin"}>
      <ListItem button onClick={toggle}>
        <ListItemIcon><BugReportIcon/></ListItemIcon>
        <ListItemText>{debug ? "Disable Debug" : "Enable Debug"}</ListItemText>
      </ListItem>
    </RequireRole>
  )
}
