export function getFirstAttributeValue(object, attributeName) {
  // console.debug("getFirstAttributeValue - attributeName: "+attributeName, object);
  if (object && object.attributes) {
    if (object.attributes[attributeName]) {
      const values = object.attributes[attributeName]
      if (values) {
        if (values[0]) {
          const attrdata = values[0]
          switch (attrdata.type) {
            case "INTEGER":
              return attrdata.intVal
            case "DECIMAL":
              return attrdata.decVal
            case "STRING":
              return attrdata.stringVal
            case "TEXT":
              return attrdata.textVal
            case "BOOLEAN":
              return attrdata.boolVal
            case "DATE":
              return attrdata.dateVal
            case "BINARY":
              return attrdata.value // special handling
            default:
          }
          console.warn("UNEXPECTED ATTRIBUTE TYPE " + attrdata.type + " and value is:", values[0].type)
          return values[0].value
        }
      }
    }
  }
  return null;
}

export function getAllAttrData(object, attributeName) {
  // console.debug("getAllAttrData - attributeName: " + attributeName, object);
  if (object && object.attributes) {
    if (object.attributes[attributeName]) {
      const values = object.attributes[attributeName]
      if (values) {
        let outvals = [];
        for (const v of values) {
          // console.log("getAllAttrData found data for attribute "+attributeName+":",v)
          outvals.push(v);
        }
        return outvals;
      }
    }
  }
  return null;
}

export function hasMultipleValues(object, attributeName) {
  const values = getAllAttrData(object, attributeName)
  return values && values.length > 1
}

export function setSingleAttributeValue(object, attributeName, type, value) {
  if (object.attributes == null) {
    object.attributes = {}
  }
  // clear all other values and set new value
  object.attributes[attributeName] = []
  object.attributes[attributeName][0] = {}
  object.attributes[attributeName][0].name = attributeName
  object.attributes[attributeName][0].type = type

  // save this one for backward compatibility
  object.attributes[attributeName][0].value = value;

  // this is where it should go though.
  switch (type) {
    case "INTEGER":
      object.attributes[attributeName][0].intVal = value;
      break;
    case "DECIMAL":
      object.attributes[attributeName][0].decVal = value;
      break;
    case "STRING":
      object.attributes[attributeName][0].stringVal = value;
      break;
    case "TEXT":
      object.attributes[attributeName][0].textVal = value;
      break;
    case "BOOLEAN":
      object.attributes[attributeName][0].boolVal = value;
      break;
    case "DATE":
      object.attributes[attributeName][0].dateVal = value;
      break;
    case "BINARY":
      object.attributes[attributeName][0].value = value;
      break; // special handling
    default:
      console.error("Unexpected type " + type);
      object.attributes[attributeName][0].value = value;
  }
}

export function entityAsFormData(entity) {
  const fd = new FormData();
  const fileMap = extractFileParts(entity);
  // console.log("Entity about to be added to FormData:",entity)
  // console.log("Entity attrs about to be added to FormData:", entity.attributes);
  fd.append("jsonData", new Blob([JSON.stringify(entity)], {type: 'application/json'}))
  fd.append("foo", new Blob([JSON.stringify({bart: "simpson"})], {type: 'application/json'}))
  // console.log("got the filemap", fileMap)
  for (const [attrName, fileArray] of Object.entries(fileMap)) {
    // console.log("k: " + attrName + ", v:", fileArray);
    if (fileArray) {
      for (const file of fileArray) {
        fd.append("files", file, (attrName + "/" + file.name));
      }
    }
  }

  return fd;
}

function extractFileParts(entity) {
  const files = new Map();
  if (entity && entity.attributes) {
    // console.log("Entity String "+JSON.stringify(entity, null, ' '))
    // console.log("Extracting file parts from entity:", entity)
    // console.log("Entity.attributes:", entity.attributes)
    const attributeHolder = entity.attributes;
    for (const attributeName of Object.keys(attributeHolder)) {
      let isBinary = false;
      // console.log("Found attribute name", attributeName);
      const attributeValues = attributeHolder[attributeName];
      // console.log("attr: ", attributeValues)
      for (const attrVal of attributeValues) {
        // console.log("att: ", attrVal)
        if (attrVal.type === "BINARY") {
          isBinary = true;
          const fileArray = attrVal.value
          if (fileArray) {
            // console.log("FILE!!!", fileArray)
            files[attributeName] = []
            for (const f of fileArray) {
              // console.log("F!!!", f);
              files[attributeName].push(f);
              // console.log("FILES IS", files)
            }
          }
        }
      }
      if (isBinary) {
        // console.log("Removing binary attribute from map", entity.attributes[attributeName]);
        delete entity.attributes[attributeName]
        // console.log("After Removing binary attribute from map attrs is ", entity.attributes);
        // console.log("After Removing binary attribute from map", entity);
      }
    }
  }
  return files;
}

export function prepareEntity(entity) {
  // console.log("Preparing entity", entity)
  delete entity.type
  delete entity.attributeNames
  delete entity.modifiedAttributeNames
  return entityAsFormData(entity);
}