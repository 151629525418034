import React from 'react'
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import CreateButton from "./theme/WarholButtons";

export default function (props) {
  const {
    singular,
    plural,
    uiPath
  } = props;

  return <>
    <Typography variant="h1" gutterBottom>Administer {plural}</Typography>
    <Typography variant="h2" gutterBottom>{singular} List</Typography>
    <p style={{textAlign: "right"}}>
      <CreateButton component={Link} to={uiPath + "/add"}>Add</CreateButton>
    </p>
  </>
}