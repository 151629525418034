import React, {useEffect, useState} from 'react'
import fetchw, {fetchwmp} from "../../../util/fetchw";
import Typography from "@material-ui/core/Typography";
import {useAppContext} from "../../../core/common/AppContext";
import Layout from "../../common/Layout";
import Loading from "../../../core/common/Loading";
import {FormControl, Table, TableContainer} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import RenderSeasonName from "../season/SeasonUtils";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import ProfileTooltip from "../../components/ProfileTooltip";
import NumberFormat from 'react-number-format';
import {DangerButton, SuccessButton} from "../../../core/common/theme/WarholButtons";
import {useDebug} from "../../../util/hooks";

export default function AdministerRegistrationPage() {

    const [{currentSeason}] = useAppContext()
    const [seasonList, setSeasonList] = useState(null)

    const [activeSeason, setActiveSeason] = useState(null);

    useEffect(() => {
        if (currentSeason && !activeSeason) {
            setActiveSeason(currentSeason)
        }
    }, [activeSeason, setActiveSeason, currentSeason])

    useEffect(
        () => {
            if (seasonList == null) {
                fetchw(`/api/ensemble/admin/season/list`)
                    .then(
                        (res) => {
                            setSeasonList(res);
                        },
                        (error) => {
                            console.error("error listing entities:", error);
                        }
                    );
            }
        }, [seasonList, setSeasonList]
    )


    if (seasonList && activeSeason) {
        return <Layout>
            <Typography variant="h2" gutterBottom>Registration Administration</Typography>
            <Typography variant={"h4"} gutterBottom><RenderSeasonName season={activeSeason}/> Dues Summary</Typography>
            <FormControl fullWidth>
                <InputLabel variant="outlined" id="selectSeasonLabelId">
                    Select the season
                </InputLabel>
                <Select
                    id={`selectSeason`}
                    variant="outlined"
                    defaultValue={activeSeason.id}
                    value={activeSeason.id}
                    label={"Select the season"} // length needs to match the InputLabel's string to ensure that a gap is left
                    labelId="selectSeasonLabelId"
                    onChange={(e) => {
                        seasonList.map((s) => {
                            if (s.id === e.target.value) {
                                setActiveSeason(s);
                            }
                            return null;
                        })
                    }}
                >
                    {seasonList.map((s) => {
                        return <MenuItem key={s.id} value={s.id}><RenderSeasonName season={s}/></MenuItem>
                    })}
                </Select>
                <FormHelperText>Usually registration would be administered for the current season, but to make updates
                    to other seasons,
                    select the season here.</FormHelperText>
            </FormControl>
            <RegisteredMemberListing season={activeSeason}/>

        </Layout>
    } else {
        return <Layout><Loading msg="dues summary"/></Layout>
    }
}

function RegisteredMemberListing(props) {
    const [season, setSeason] = useState(null);
    const [members, setMembers] = useState(null);

    useEffect(() => {
        if (props.season !== season) {
            setSeason(props.season)
            setMembers(null)
        }
    }, [props.season, season, setSeason])

    useEffect(
        () => {
            if (season && members == null) {
                fetchw(`/api/ensemble/dues/${season.id}/list-registered-members`)
                    .then((res) => {
                            setMembers(res);
                        }, (error) => {
                            console.error("error listing entities:", error);
                        }
                    );
            }
        }, [members, setMembers, season]
    )

    if (members) {
        return <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Member</TableCell>
                            <TableCell>Balance Due</TableCell>
                            <TableCell>Commands</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            members.map((item, idx) => {
                                return <MemberPaymentRow key={idx} holder={item}/>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    }
    return <Loading msg={"registered members"}/>
}

function MemberPaymentRow({holder}) {
    const debug = useDebug()
    const [{notifications}, setNotifications] = useAppContext();

    function doApprove() {
        holder.duesSummary.registrationComplete = true;
        if (debug) console.debug("Approving registration for profile " + holder.profile.pid + " for season " + holder.duesSummary.season.seasonStart)
        fetchwmp(`/api/ensemble/season/register/approve/${holder.duesSummary.season.id}/${holder.profile.pid}`, {
            method: "PUT"
        })
            .then((res) => {
                    notifications.push({
                        severity: "success",
                        body: `The registration of '${holder.profile.phandle}' has been recorded.`
                    })
                    setNotifications(notifications)
                }, (error => {
                    console.error("error recording registration approval", error)
                    switch (error.status) {
                        case 400:
                            notifications.push({
                                severity: "error",
                                body: "Saving the registration approval failed unexpectedly. Please contact the administrator."
                            })
                            setNotifications(notifications)
                            break;
                        default:
                            notifications.push({
                                severity: "error",
                                body: "Saving the registration approval failed unexpectedly. Please contact the administrator."
                            })
                            setNotifications(notifications)
                    }
                })
            )
    }

    function doReject() {
        holder.duesSummary.registrationComplete = false;
        if (debug) console.debug("Unapproving registration for profile " + holder.profile.pid + " for season " + holder.duesSummary.season.seasonStart)
        fetchwmp(`/api/ensemble/season/register/unapprove/${holder.duesSummary.season.id}/${holder.profile.pid}`, {
            method: "PUT"
        })
            .then((res) => {
                    notifications.push({
                        severity: "success",
                        body: `The un-registration of '${holder.profile.phandle}' has been recorded.`
                    })
                    setNotifications(notifications)
                }, (error => {
                    console.error("error recording registration rejection", error)
                    switch (error.status) {
                        case 400:
                            notifications.push({
                                severity: "error",
                                body: "Saving the registration rejection failed unexpectedly. Please contact the administrator."
                            })
                            setNotifications(notifications)
                            break;
                        default:
                            notifications.push({
                                severity: "error",
                                body: "Saving the registration rejection failed unexpectedly. Please contact the administrator."
                            })
                            setNotifications(notifications)
                    }
                })
            )
    }

    if (!holder) return null;

    return <>
        <TableRow key={holder.profile.pid}>
            <TableCell>
                <ProfileTooltip uiprofile={holder.profile}>
                    <Typography
                        variant={"body2"}>{holder.profile.fallbackStageName} ({holder.profile.instrument})</Typography>
                </ProfileTooltip>
            </TableCell>
            <TableCell><NumberFormat value={holder.duesSummary.balance} displayType={'text'} prefix={'$'}
                                     thousandSeparator={true}
                                     decimalScale={2}/></TableCell>
            <TableCell>
                {!holder.duesSummary.registrationComplete &&
                    <SuccessButton onClick={(e => doApprove())}>Approve</SuccessButton>}
                {holder.duesSummary.registrationComplete &&
                    <DangerButton onClick={(e => doReject())}>Reject</DangerButton>}
            </TableCell>
        </TableRow>
    </>

}
