import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import fetchw from "../../util/fetchw";
import {useDebug} from "../../util/hooks";

export default function ScheduleReport(props) {
    const {
        rid
    } = props

    const debug = useDebug()
    const [report, setReport] = useState(null);

    useEffect(() => {
        if (!report) {
            fetchw(`/api/swim/report/schedule/${rid}`)
                .then((res) => {
                    setReport(res);
                    if (debug) console.log("Loaded report", res)
                }, (error) => {
                    console.log("Error loading report ", error)
                })
        }
    }, [report, debug, rid])
    return (
        <>
            <Typography variant="h3">Schedule Report</Typography>
            {JSON.stringify(report)}

        </>
    )
}
