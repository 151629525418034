import React from 'react';
import DateTime from "luxon/src/datetime.js";

export function PrettyDate({unixDate, format}) {
    try {
        if (!unixDate) {
            unixDate = new Date().getTime();
        }
        const dt = DateTime.fromMillis(unixDate);
        return <>{dt.toFormat(format)}</>;
    } catch (error) {
        console.log(
            "Error formatting date. unixDate:" + unixDate + ", format:" + format,
            error
        );
        return <>Invalid input format {JSON.stringify(unixDate)}</>;
    }
}

export function now() {
    return DateTime.now().toFormat("x");
}

export default PrettyDate;
