import React, {useEffect, useState} from 'react'
import {useLocation, useParams, withRouter} from "react-router-dom";
import {FormControl} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import {useAppContext} from "../../../core/common/AppContext";
import Layout from "../../common/Layout";
import CrudFormBase from "../../../core/common/CrudFormBase";
import Loading from "../../../core/common/Loading";
import {prepareEntity} from "../../../util/remoteentityutils";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DynamicAttributeFields from "../../../core/common/crud/DynamicAttributeFields";

export default withRouter(Form)

function Form(props) {

  const location = useLocation();
  const readonly = location.pathname.indexOf("/view/") > -1;

  const [{notifications}, setNotifications] = useAppContext();

  // load data
  const {eid} = useParams();
  const [loadedForEdit, setLoadedForEdit] = useState(false)
  const [entity, setEntity] = useState(null);
  useEffect(() => {
    if (eid && !loadedForEdit) {
      fetchw(`/api/ensemble/admin/section/${eid}`)
        .then((res) => {
          setEntity(res);
          setLoadedForEdit(true)
        })
    }
  }, [eid, loadedForEdit, setEntity, setLoadedForEdit])

  const [profiles, setProfiles] = useState(null);
  useEffect(() => {
    if (!profiles) {
      fetchw("/api/ensemble/profiles")
        .then((res) => {
          setProfiles(res);
        })
    }
  }, [profiles, setProfiles])

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const method = loadedForEdit ? "POST" : "PUT"
    fetchwmp("/api/ensemble/admin/section", {
      method: method,
      body: prepareEntity(entity)
    })
      .then((res) => {
          notifications.push({severity: "success", body: `The section '${entity.name}' was saved successfully.`})
          setNotifications(notifications)
          props.history.push('/ensemble/admin/section');
        }, (error => {
          console.error("error adding entity", error)
          switch (error.status) {
            case 400:
              notifications.push({
                severity: "error",
                body: "Saving the section failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
              break;
            case 409:
              notifications.push({severity: "error", body: "Saving the section failed - duplicate entry."})
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: "Saving the section failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }
  if ((eid == null || loadedForEdit) && profiles !== null) {
    return (
      <Layout>
        <CrudFormBase singular="Section" plural="Sections" uiPath="/ensemble/admin/section"
                      loadedForEdit={loadedForEdit} submitHandler={handleSubmit}>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField disabled={true} id="id" label="ID" variant="outlined" defaultValue={entity?.id}/>
          </FormControl>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="name" label="Name" variant="outlined"
                       defaultValue={entity?.name} required
                       onChange={(e) => {
                         setEntity({...entity, name: e.target.value})
                       }}
            />
          </FormControl>

          <FormControl required fullWidth style={{margin: 15}} disabled={readonly}>
            <InputLabel id="profileLabel">Section Leader</InputLabel>
            <Select
              labelId="profileLabel"
              id="leaderPid"
              value={entity?.leaderPid ? entity.leaderPid : ''}
              onChange={(e) => {
                setEntity({...entity, leaderPid: e.target.value})
              }
              }
            >
              {
                profiles.map((p) => {
                  return <MenuItem key={p.id} value={p.id}>{p.handle}</MenuItem>
                })
              }
            </Select>
          </FormControl>

          <DynamicAttributeFields entity={entity} updateEntity={setEntity}
                                  attributeNames={["section.teamdriveurl"]}/>
        </CrudFormBase>
      </Layout>
    );
  } else {
    return <Loading msg="section form"/>
  }
}