import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";

const ErrorButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.error.light
    }
  }
}))(Button)

export const WarningButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.warning.light
    }
  }
}))(Button)

export const SuccessButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.success.light
    }
  }
}))(Button)

export const PrimaryButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.primary.light
    }
  }
}))(Button)

export const SecondaryButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.secondary.light
    }
  }
}))(Button)

export const DangerButton = ErrorButton

export const EditButton = Button

export const CancelButton = Button

export const DeleteButton = ErrorButton

export const SaveButton = PrimaryButton

export const CreateButton = Button

export default Button
