import React, {useEffect, useState} from 'react';
import fetchw from "../../util/fetchw";

export default function () {
  const [ping, setPing] = useState(null);

  useEffect(
    () => {
      if (ping == null) {
        fetchw('/ping').then(
          (result) => {
            setPing(result);
          },
          (error) => {
            setPing({
                time: "Today",
                result: "FETCH FAILURE",
                reason: error
              }
            )
            console.error("Ping failure:", error);
          }
        );
      }
    }, [ping, setPing]
  )
  if (ping) {
    return (
      <section>
        <div>Ping at {ping.time}. Result: <b>{ping.result}</b>: <em>{ping.reason}</em></div>
      </section>
    );
  } else {
    return (
      <section>
        <div>Pinging...</div>
      </section>
    )
  }
}