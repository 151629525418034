import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {useApplicationEnvironment, useDebug, useProfile} from "../../util/hooks";
import fetchw from "../../util/fetchw";
import {getFirstAttributeValue} from "../../util/remoteentityutils";
import RequireAttribute from "../../core/common/RequireAttribute";
import DynamicAttributeRenderer from "../../core/common/crud/DynamicAttributeRenderer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RenderProfileImage from "./RenderProfileImage";

function YourSectionInfo(props) {
  const {
    open = false
  } = props

  const {profile} = useProfile();
  const debug = useDebug()
  const {organization} = useApplicationEnvironment()
  const [instrument, setInstrument] = useState(null);
  const [instrumentLoading, setInstrumentLoading] = useState(false);

  useEffect(() => {
    const instrumentId = getFirstAttributeValue(profile, "music.instrument")
    if (!instrument && !instrumentLoading && instrumentId) {
      setInstrumentLoading(true);
      fetchw(`/api/ensemble/admin/instrument/${instrumentId}`).then((res) => {
        setInstrument(res)
      })
    }
  }, [instrument, instrumentLoading, setInstrument, setInstrumentLoading, profile])


  const [section, setSection] = useState(null);
  const [sectionLoading, setSectionLoading] = useState(false)
  useEffect(() => {
    if (instrument && !section && !sectionLoading) {
      setSectionLoading(true)
      fetchw(`/api/ensemble/admin/section/${instrument.sectionId}`)
        .then((res) => {
          setSection(res);
        })
    }
  }, [section, sectionLoading, setSection, setSectionLoading, instrument])


  const [leaderProfile, setLeaderProfile] = useState(null);
  const [leaderLoading, setLeaderLoading] = useState(false);
  useEffect(() => {
    if (!leaderProfile && !leaderLoading && section && section.leaderPid && section.leaderPid > 0) {
      setLeaderLoading(true)
      fetchw(`/api/profile/${section.leaderPid}`).then((res) => {
        setLeaderProfile(res)
        if (debug) console.log("Section leader:", res)
      }, (error) => {
        console.log("Error loading section leader", error)
      })
    }
  }, [debug, leaderProfile, leaderLoading, section, setLeaderLoading, setLeaderProfile])


  const [expanded, setExpanded] = useState(open)

  function toggle() {
    setExpanded(!expanded)
  }

  if (profile && instrument && section) {
    const teamDriveUrl = getFirstAttributeValue(section, "section.teamdriveurl")

    return <RequireAttribute attributes={["form.section.intro"]}>
      <Accordion expanded={expanded} onChange={toggle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant={"h4"}>My Section</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography variant={"subtitle1"}>
              <DynamicAttributeRenderer entity={organization} attribute={"form.section.intro"}/>
            </Typography>

            <Typography><strong>Instrument: </strong>{instrument.name}</Typography>
            <Typography><strong>Section: </strong>{section.name}</Typography>

            {leaderProfile ?
              <Typography><strong>Section Leader: </strong>
                <RenderProfileImage profile={leaderProfile}/>{' '}
                {leaderProfile.firstname} {leaderProfile.lastname}
              </Typography>
              : ""}

            {teamDriveUrl ? <>
              <Typography>
                <strong>{section.name} Team Drive: <a rel="noopener noreferrer" target="_blank" href={teamDriveUrl}>click
                  here to open</a></strong>
                {' '}
              </Typography>
              <Typography variant={"body1"}>The above link jumps to your section's team drive. Since you're already
                authenticated with Google to sign in to this app, you should also be able to access your section drive.
                If you are having trouble, you probably haven't been granted access to it yet. Simply request access
                when prompted, and your section leader will set it up for you.</Typography>
            </> : ""}
          </div>
        </AccordionDetails>
      </Accordion>
    </RequireAttribute>

  } else {
    return ""
  }
}

export default YourSectionInfo
