import React, {useEffect, useState} from 'react'
import fetchw from "../../../util/fetchw";
import Layout from "../../common/Layout";
import CrudListHeadings from "../../../core/common/CrudListHeadings";
import Loading from "../../../core/common/Loading";
import CrudListCommandBar from "../../../core/common/CrudListCommandBar";
import Button from "@material-ui/core/Button";
import {Table} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

export default function () {
  const [items, setItems] = useState(null)

  useEffect(
    () => {
      if (items == null) {
        fetchw(`/api/ensemble/admin/instrument/list`)
          .then(
            (res) => {
              setItems(res);
            },
            (error) => {
              console.error("error listing entities:", error);
            }
          );
      }
    }, [items, setItems]
  )


  function moveUp(event, instrumentId) {
    fetchw(`/api/ensemble/admin/instrument/${instrumentId}/move-up`)
      .then(
        (res) => {
          setItems(null); // todo: just tweak the list
        },
        (error) => {
          console.error("error re-ranking instruments:", error);
        }
      );
  }

  function moveDown(event, instrumentId) {
    fetchw(`/api/ensemble/admin/instrument/${instrumentId}/move-down`)
      .then(
        (res) => {
          setItems(null); // todo: just tweak the list
        },
        (error) => {
          console.error("error re-ranking instruments:", error);
        }
      );
  }

  if (items) {
    return <Layout>
      <CrudListHeadings singular="Instrument" plural="Instruments" uiPath="/ensemble/admin/instrument"/>
      <Table className="table table-striped">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Commands</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            items.map((item, index) =>
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  <CrudListCommandBar uiPath="/ensemble/admin/instrument" apiPath="/api/ensemble/admin/instrument"
                                      canDelete={true}
                                      entityInfo={{
                                        eid: item.id,
                                        label: item.name,
                                        enabled: true
                                      }}
                                      clearState={setItems}/>{' '}
                  <Button disabled={index === 0} onClick={(e) => moveUp(e, item.id)}
                          color="primary">Move Up</Button>{' '}
                  <Button disabled={index === items.length - 1} onClick={(e) => moveDown(e, item.id)}
                          color="primary">Move Down</Button>
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </Layout>
  } else {
    return <Layout><Loading msg="instruments"/></Layout>
  }
}