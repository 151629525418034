import React, {lazy, Suspense} from 'react'
import Loading from "../Loading";
import {useApplicationEnvironment} from "../../../util/hooks";

const AttributeRendererMap = {
  'INTEGER': lazy(() => import('./renderers/DefaultIntegerRenderer')),
  'DECIMAL': lazy(() => import('./renderers/DefaultDecimalRenderer')),
  'STRING': lazy(() => import('./renderers/DefaultStringRenderer')),
  'TEXT': lazy(() => import('./renderers/DefaultTextRenderer')),
  'BOOLEAN': lazy(() => import('./renderers/DefaultBooleanRenderer')),
  'DATE': lazy(() => import('./renderers/DefaultDateRenderer')),
  'BINARY': lazy(() => import('./renderers/DefaultBinaryRenderer')),
  "InstrumentNameDisplay": lazy(() => import ("./renderers/InstrumentNameDisplay")),
  "HtmlRenderer": lazy(() => import("./renderers/HtmlRenderer"))
}

export default function DynamicAttributeRenderer(props) {
  const {
    attribute,
    entity
  } = props
  const {attributeMap, attributeRenderers} = useApplicationEnvironment()
  if (attribute === null) return null;

  const attrObj = attributeMap.get(attribute);
  if (!attrObj) return null;


  if (attributeRenderers) {
    const LocatedRenderer = locateRenderer(attrObj, attributeRenderers)
    return <Suspense fallback={<Loading msg={"dynamic renderer1"}/>}>
      <LocatedRenderer entity={entity} attribute={attrObj}/>
    </Suspense>
  } else {
    return <Loading msg={"dynamic renderer3"}/>
  }
}

function locateRenderer(attribute, renderers) {
  // console.debug("Locating renderer for attribute ",attribute)

  const renderer = attribute.renderer
  let code = attribute.datatype
  if (renderer) {
    for (const rn of renderers) {
      if (rn.id === renderer) {
        if (rn.codeName) {
          code = rn.codeName
          break;
        }
      }
    }
  }
  return AttributeRendererMap[code];
}
