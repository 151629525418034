import React, {useEffect, useState} from 'react';
import Layout from "../../common/Layout";
import {Box, Button, Typography} from "@material-ui/core";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import {useParams} from "react-router-dom";
import Loading from "../../../core/common/Loading";
import PrettyDate from "../../../core/common/PrettyDate";
import {useDebug, useProfile} from "../../../util/hooks";
import CommitmentLegend from "./CommitmentLegend";
import {hasRole} from "../../../util/securityutils";
import {useAppContext} from "../../../core/common/AppContext";

function ExpectedAttendanceReport() {
    const {eid} = useParams();
    // console.log("EAR eid:", eid)
    const debug = useDebug()
    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded && eid) {
            const uri = `/api/ensemble/attendance/expected/${eid}`
            fetchw(uri)
                .then((res) => {
                        setData(res);
                        // console.log(res)
                        setLoaded(true)
                        if (debug) console.debug("Returned report data", res)
                    }, (error) => {
                        console.error("error reading report data", error);
                        setLoaded(true)
                    }
                );
        }
    }, [loaded, eid, debug]);

    const doReload = () => {
        setLoaded(false)
    }

    if (!loaded) return <Layout><Loading msg={"Loading expected attendance report"}/></Layout>
    if (!data) return <Layout><Typography>No data found.</Typography></Layout>

    const eventStartDate = Date.parse(data.event.startTime)
    const instrumentInfo = data.musiciansByInstrument;

    const yesCount = () => {
        let cnt = 0
        for (const ii of instrumentInfo) {
            for (const player of ii.players) {
                if (player.yes) cnt++
            }
        }
        return cnt
    }

    const maybeCount = () => {
        let cnt=0
        for(const ii of instrumentInfo) {
            for (const player of ii.players) {
                if (player.maybe) cnt++
            }
        }
        return cnt
    }

    const noCount = () => {
        let cnt=0
        for(const ii of instrumentInfo) {
            for (const player of ii.players) {
                if (player.no) cnt++
            }
        }
        return cnt
    }

    return (
        <Layout>
            <Typography variant={"h2"}>Expected Attendance Report</Typography>
            <Typography variant={"h5"}>{data.event.name}</Typography>
            <Typography variant={"h6"}>
                <PrettyDate
                    unixDate={eventStartDate}
                    format="ccc MMM dd yyyy 'at' h:mm a"
                />
            </Typography>
            <CommitmentLegend/>
            <Box p={3} color={"success.main"}>
                <Typography gutterBottom variant={"h5"}>Yes ({yesCount()})</Typography>
                <DumpInstrument instrumentInfo={instrumentInfo} playerFilterCallback={player => player.yes} eid={eid}
                                reloadCallback={doReload}/>
            </Box>
            <Box p={3} color={"error.main"}>
                <Typography gutterBottom variant={"h5"}>No ({noCount()})</Typography>
                <DumpInstrument instrumentInfo={instrumentInfo} playerFilterCallback={player => player.no} eid={eid}
                                reloadCallback={doReload}/>
            </Box>
            <Box p={3} color={"warning.main"}>
                <Typography gutterBottom variant={"h5"}>Maybe ({maybeCount()})</Typography>
                <DumpInstrument instrumentInfo={instrumentInfo} playerFilterCallback={player => player.maybe} eid={eid}
                                reloadCallback={doReload}/>
            </Box>
            <Box p={3} color={"warning.main"}>
                <Typography gutterBottom variant={"h5"}>Didn't respond</Typography>
                <DumpInstrument instrumentInfo={instrumentInfo}
                                playerFilterCallback={player => (!player.yes && !player.no && !player.maybe)} eid={eid}
                                reloadCallback={doReload}/>
            </Box>
        </Layout>
    )
}

const hasPeople = (players, playerFilterCallback) => {
    for (const n in players) {
        const result = playerFilterCallback(players[n])
        if (result) {
            return true;
        }
    }
    return false
}

const DumpInstrument = props => {
    const {
        instrumentInfo,
        playerFilterCallback,
        eid,
        reloadCallback
    } = props
    return <>
        {
            instrumentInfo.map((ii, index) => {
                const instrument = ii.instrument;
                const ppl = ii.players;
                if (hasPeople(ppl, playerFilterCallback)) {
                    return <span key={index}>
                        <Typography variant={"h6"}>{instrument.name}</Typography>
                        {ppl.map((player, pi) => {
                            // console.log("Player "+player.uiprofile.phandle, player)
                            if (playerFilterCallback(player)) {
                                return <RenderName key={pi} player={player} eid={eid} reloadCallback={reloadCallback}/>
                            } else {
                                return null
                            }
                        })}
                    </span>
                } else {
                    return null
                }
            })
        }
    </>
}

const RenderName = props => {
    const {
        player,
        eid,
        reloadCallback
    } = props
    const {profile} = useProfile()
    const [{notifications}, setNotifications] = useAppContext();
    const [override, setOverride] = useState(false)

    const setYes = () => {
        console.log("Set yes")
        recordCommitment(eid, player.pid, "yes")
    }
    const setNo = () => {
        console.log("Set no")
        recordCommitment(eid, player.pid, "no")
    }
    const setMaybe = () => {
        console.log("Set maybe")
        recordCommitment(eid, player.pid, "maybe")
    }
    const setClear = () => {
        console.log("Set clear")
        recordCommitment(eid, player.pid, "clear")
    }

    const recordCommitment = (eid, pid, answer) => {
        fetchwmp(`/api/ensemble/attendance/commit/${eid}/${pid}/${answer}`, {
            method: "PUT"
        })
            .then((res) => {
                    notifications.push({
                        severity: "success",
                        body: `The commitment has been recorded.`
                    })
                    setNotifications(notifications)
                    reloadCallback()
                }, (error => {
                    console.error("error recording attendance commitment approval", error)
                    switch (error.status) {
                        case 400:
                            notifications.push({
                                severity: "error",
                                body: "Saving the attendance commitment has failed unexpectedly. Please contact the administrator."
                            })
                            setNotifications(notifications)
                            break;
                        default:
                            notifications.push({
                                severity: "error",
                                body: "Saving the attendance commitment has failed unexpectedly. Please contact the administrator."
                            })
                            setNotifications(notifications)
                    }
                    reloadCallback()
                })
            )
    }
    if (!profile) return null
    if (!player) return null
    const showControl = hasRole(profile, "attendanceTaker")
    if (override) {
        return <Typography style={{paddingLeft: "2em"}}>
            {player.uiprofile.phandle}
            <Button size="small" variant={"text"} onClick={() => setYes()}>yes</Button>
            <Button size="small" variant={"text"} onClick={() => setNo()}>no</Button>
            <Button size="small" variant={"text"} onClick={() => setMaybe()}>maybe</Button>
            <Button size="small" variant={"text"} onClick={() => setClear()}>clear</Button>
        </Typography>
    } else {
        if (showControl) {
            return <Typography style={{paddingLeft: "2em"}}>
                {player.uiprofile.phandle}
                <Button size="small" variant={"text"} onClick={() => setOverride(!override)}>Change</Button>
            </Typography>

        } else {
            return <Typography style={{paddingLeft: "2em"}}>
                {player.uiprofile.phandle}
            </Typography>
        }
    }
}
export default ExpectedAttendanceReport
