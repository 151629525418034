import React, {useEffect, useState} from 'react';
import fetchw from "../../util/fetchw";
import Layout from "../common/Layout";
import Typography from "@material-ui/core/Typography";
import Loading from "../../core/common/Loading";
import {DataGrid} from "@material-ui/data-grid";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import {useApplicationEnvironment, useSeasons} from "../../util/hooks";
import {computeSeasonName} from "../admin/season/SeasonUtils";
import NumberFormat from "react-number-format";
import {Show} from "../components/RegistrationStatusTiny";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ProfileTooltip from "../components/ProfileTooltip";
import EditButton from '../../core/common/theme/WarholButtons'

const useStyles = makeStyles((theme) => ({
  bad: {
    color: theme.palette.secondary.main
  },
  good: {}
}))

function idColumn() {
  return {
    field: "id", headerName: "id", type: "number", width: 94, renderCell: (params) => {
      return <Tooltip title={"Edit profile"}><EditButton component={Link} variant={"text"}
                                                         to={`/admin/super/profile/edit/${params.value}`}
      >{params.value}</EditButton></Tooltip>
    }
  }
}

function fnameColumn() {
  return {field: "fname", headerName: "First Name", width: 150}
}

function lnameColumn() {
  return {field: "lname", headerName: "Last Name", width: 150}
}

function stagenameColumn() {
  return {field: "stagename", headerName: "Stage Name", width: 150}
}

// function schoolColumn() {
//   return {field: "school", headerName: "School", width: 150}
// }
//
// function gradeColumn() {
//   return {field: "grade", headerName: "Grade", width: 150}
// }

function sweaterSizeColumm() {
  return {field: "sweatersize", headerName: "Sweater Size", width: 150}
}

function smartMusicColumn() {
  return {field: "requiresmartmusic", headerName: "Requests Smartmusic", width: 150}
}

function phandleColumn() {
  return {
    field: "phandle", headerName: "Profile Handle", width: 250, renderCell: (params) => {
      return <> <ProfileTooltip uiprofile={params.data.uiprofile}>
        <Typography>
          {params.data.enabled ? <Enabled>{params.value}</Enabled> : <Disabled>{params.value}</Disabled>}
        </Typography>
      </ProfileTooltip>
      </>

    }
  }
}

function instrumentColumn() {
  return {field: "instrument", headerName: "Instrument", width: 175}
}

function emailColumn() {
  return {field: "email", headerName: "Email Address", width: 250}
}

function phoneColumn() {
  return {field: "phone", headerName: "Phone", width: 130}
}

function requirefolderColumn() {
  return {field: "requirefolder", headerName: "Folder?", width: 80}
}

function carols2020Column() {
  return {field: "carols2020", headerName: "Carols 2020", width: 250}
}

function volunteerColumn() {
  return {field: "volunteer", headerName: "Volunteer", width: 800}
}

function requireinstrumentColumn() {
  return {
    field: "requireinstrument", width: 130,
    renderHeader: (params) => (
        <div className="MuiDataGrid-colCellTitle"
             style={{fontSize: "smaller", lineHeight: "normal", whiteSpace: "normal", wordWrap: "normal"}}
             aria-label={"Needs Instrument?"}>{"Needs Instrument"}</div>
    )
  }
}

function pendingRoleColumn() {
  return {
    field: "hasRolePending", headerName: "Pending", width: 100, renderCell: (params) => {
      return params.value ? <RadioButtonCheckedIcon/> : <RadioButtonUncheckedIcon/>
    }
  }
}

function userRoleColumn() {
  return {field: "hasRoleUser", headerName: "User", width: 100, renderCell: (params) => renderBool(params.value)}
}

function memberRoleColumn() {
  return {
    field: "hasRoleMember",
    headerName: "Member",
    width: 100,
    renderCell: (params) => renderBool(params.value)
  }
}

function financeRoleColumn() {
  return {
    field: "hasRoleFinance",
    headerName: "Finance",
    width: 100,
    renderCell: (params) => renderBool(params.value)
  }
}

function attendanceRoleColumn() {
  return {
    field: "hasRoleAttendance",
    headerName: "Attendance",
    width: 120,
    renderCell: (params) => renderBool(params.value)
  }
}

function orgadminRoleColumn() {
  return {
    field: "hasRoleOrgadmin",
    headerName: "Admin",
    width: 100,
    renderCell: (params) => renderBool(params.value)
  }
}

function sysadminRoleColumn() {
  return {
    field: "hasRoleSysadmin",
    headerName: "Sysadmin",
    width: 100,
    renderCell: (params) => renderBool(params.value)
  }
}

function seasonRegisteredColumn(season) {
  return {
    field: "season:" + season.id + "_reg",
    width: 100,
    renderHeader: (params) => (
      <div className="MuiDataGrid-colCellTitle"
           style={{fontSize: "smaller", lineHeight: "normal", whiteSpace: "normal", wordWrap: "normal"}}
           aria-label={computeSeasonName(season) + " Registered"}>{computeSeasonName(season) + " Registered"}</div>
    ),
    renderCell: (params) => {
      return <Show ds={params.value} seasonid={season.id} pid={params.data.id}/>
    }
  }
}

function seasonDuesColumn(season) {
  return {
    field: "season:" + season.id + "_bal",
    width: 100,
    renderHeader: (params) => (
      <div className="MuiDataGrid-colCellTitle"
           style={{fontSize: "smaller", lineHeight: "normal", whiteSpace: "normal", wordWrap: "normal"}}
           aria-label={computeSeasonName(season) + " Balance"}>{computeSeasonName(season) + " Balance"}</div>
    ),
    renderCell: (params) => {
      return <NumberFormat value={params.value.balance}
                           displayType={'text'}
                           prefix={'$'}
                           thousandSeparator={true}
                           decimalScale={2}/>
    }
  }
}

function renderBool(value) {
  return value ? <RadioButtonCheckedIcon/> : <RadioButtonUncheckedIcon/>
}

// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function EnabledUsers() {

  const {seasons_data} = useSeasons();
  const {attributeMap} = useApplicationEnvironment()

  const cols = [];
  cols.push(idColumn())
  if (seasons_data) {
    seasons_data.map((season) => {
      cols.push(seasonRegisteredColumn(season))
      cols.push(seasonDuesColumn(season))
      return null;
    })
  }
  cols.push(phandleColumn())
  cols.push(fnameColumn())
  cols.push(lnameColumn())
  if (attributeMap?.get("ci.stagename")) cols.push(stagenameColumn())
  cols.push(instrumentColumn())
  cols.push(emailColumn())
  cols.push(phoneColumn())
  if (attributeMap?.get("music.requirefolder")) cols.push(requirefolderColumn())
  if (attributeMap?.get("music.requireinstrument")) cols.push(requireinstrumentColumn())

  const [data, setData] = useState(null);
  useEffect(() => {
    if (!data) {
      fetchw(`/api/ensemble/reports/profiles/list/enabled`)
        .then((res) => {
          setData(res);
        }, (error) => {
          console.log("Error loading all user data ", error)
        })
    }
  }, [data, setData])

  if (data) {
    return <Layout>
      <Typography variant={"h6"} color={"secondary"}>Administrative Report</Typography>
      <Typography variant={"h3"} gutterBottom>Enabled Member Profiles (all seasons)</Typography>
      <div style={{height: '75vh', paddingBottom: '5vh', border: "cyan 1px"}}>
        <DataGrid disableSelectionOnClick={true} columns={cols} rows={data}/>
      </div>
    </Layout>
  } else {
    return <Loading msg="all users report"/>
  }
}

export function MemberOptions() {

  const {seasons_data} = useSeasons();
  const {attributeMap} = useApplicationEnvironment()

  const cols = [];
  cols.push(idColumn())
  if (seasons_data) {
    seasons_data.map((season) => {
      cols.push(seasonRegisteredColumn(season))
      return null;
    })
  }
  cols.push(phandleColumn())
  cols.push(fnameColumn())
  cols.push(lnameColumn())
  if (attributeMap?.get("ci.stagename")) cols.push(stagenameColumn())
  cols.push(instrumentColumn())
  if (attributeMap?.get("ci.volunteer")) cols.push(volunteerColumn())
  if (attributeMap?.get("music.requirefolder")) cols.push(requirefolderColumn())
  if (attributeMap?.get("music.requireinstrument")) cols.push(requireinstrumentColumn())
  if (attributeMap?.get("music.requiresmartmusic")) cols.push(smartMusicColumn())
  if (attributeMap?.get("music.sweatersize")) cols.push(sweaterSizeColumm())
  if (attributeMap?.get("ci.2020.christmas.carols")) cols.push(carols2020Column())

  const [data, setData] = useState(null);
  useEffect(() => {
    if (!data) {
      fetchw(`/api/ensemble/reports/profiles/list/enabled`)
        .then((res) => {
          setData(res);
        }, (error) => {
          console.log("Error loading all user data ", error)
        })
    }
  }, [data, setData])

  if (data) {
    return <Layout>
      <Typography variant={"h6"} color={"secondary"}>Administrative Report</Typography>
      <Typography variant={"h3"} gutterBottom>Member Options</Typography>
      <div style={{height: '75vh', paddingBottom: '5vh', border: "cyan 1px"}}>
        <DataGrid disableSelectionOnClick={true} columns={cols} rows={data}/>
      </div>
    </Layout>
  } else {
    return <Loading msg="all users report"/>
  }
}


export function DisabledUsers() {

  const {seasons_data} = useSeasons();
  const {attributeMap} = useApplicationEnvironment()

  const cols = [];
  cols.push(idColumn())
  if (seasons_data) {
    seasons_data.map((season) => {
      cols.push(seasonRegisteredColumn(season))
      cols.push(seasonDuesColumn(season))
      return null;
    })
  }
  cols.push(phandleColumn())
  cols.push(pendingRoleColumn())
  cols.push(userRoleColumn())
  cols.push(memberRoleColumn())
  cols.push(financeRoleColumn())
  cols.push(attendanceRoleColumn())
  cols.push(orgadminRoleColumn())
  cols.push(sysadminRoleColumn())
  cols.push(fnameColumn())
  cols.push(lnameColumn())
  if (attributeMap?.get("ci.stagename")) cols.push(stagenameColumn())
  cols.push(instrumentColumn())
  cols.push(emailColumn())
  cols.push(phoneColumn())


  const [data, setData] = useState(null);
  useEffect(() => {
    if (!data) {
      fetchw(`/api/ensemble/reports/profiles/list/disabled`)
        .then((res) => {
          setData(res);
        }, (error) => {
          console.log("Error loading all disabled user data ", error)
        })
    }
  }, [data, setData])


  if (data) {
    return <Layout>
      <Typography variant={"h6"} color={"secondary"}>Administrative Report</Typography>
      <Typography variant={"h3"}>Disabled/Non-User/Non-Member Profiles (all seasons)</Typography>
      <div style={{height: '75vh', paddingBottom: '5vh', border: "cyan 1px"}}>
        <DataGrid disableSelectionOnClick={true} columns={cols} rows={data} pageSize={100}/>
      </div>
    </Layout>
  } else {
    return <Loading msg="all users report"/>
  }
}


export function PrivilegedUsers() {

  const cols = [];
  cols.push(idColumn())
  cols.push(phandleColumn())
  cols.push(fnameColumn())
  cols.push(lnameColumn())
  cols.push(pendingRoleColumn())
  cols.push(userRoleColumn())
  cols.push(memberRoleColumn())
  cols.push(financeRoleColumn())
  cols.push(attendanceRoleColumn())
  cols.push(orgadminRoleColumn())
  cols.push(sysadminRoleColumn())

  const [data, setData] = useState(null);
  useEffect(() => {
    if (!data) {
      fetchw(`/api/ensemble/reports/profiles/list/privileged`)
        .then((res) => {
          setData(res);
        }, (error) => {
          console.log("Error loading all disabled user data ", error)
        })
    }
  }, [data, setData])


  if (data) {
    return <Layout>
      <Typography variant={"h6"} color={"secondary"}>Administrative Report</Typography>
      <Typography variant={"h3"}>Privileged Profiles (all seasons)</Typography>
      <div style={{height: '75vh', paddingBottom: '5vh', border: "cyan 1px"}}>
        <DataGrid disableSelectionOnClick={true} columns={cols} rows={data} pageSize={100}/>
      </div>

    </Layout>
  } else {
    return <Loading msg="all users report"/>
  }
}

export default EnabledUsers

function Enabled({children}) {
  const classes = useStyles();
  return <span className={classes.good}>{children}</span>
}

function Disabled({children}) {
  const classes = useStyles();
  return <span className={classes.bad}>{children}</span>
}
