import React, {useEffect, useState} from 'react'
import {withCookies} from 'react-cookie';
import Loading from "./Loading";
import Login from "../Login";
import {ACCESS_TOKEN_KEY, XSRF_TOKEN_KEY} from "./constants";
import fetchw from "../../util/fetchw";
import {getUrlParameter} from "../../util/urlutils";
import {useSWRConfig} from "swr";
import {clearProfile} from "../../util/hooks";

export default withCookies(Authenticated);

function Authenticated(props) {
  const {
    cookies,
    children
  } = props;

  const oldStoredToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
  const token = getUrlParameter('token');
  // const error = getUrlParameter('error'); // not sure what to do with this yet.

  window.localStorage.setItem(XSRF_TOKEN_KEY, cookies.get("XSRF-TOKEN"))
  const [loaded, setLoaded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(oldStoredToken !== null);
  const [loading, setLoading] = useState(false);
  const {mutate} = useSWRConfig()

  useEffect(() => {
    if (token != null && token.length > 0) {
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
        console.log("Setting access token", token) // todo: urgent: remove
        setIsAuthenticated(true);
        setLoaded(false) // something changed - reload.
    }
  }, [token, setIsAuthenticated])


  useEffect(
    () => {
      if (isAuthenticated && !loading && loaded === false) {
        setLoading(true)
        fetchw('/api/profile')
          .then(
            (res) => {
              if (res.status === 401) {
                setIsAuthenticated(false)
                setLoaded(true)
                setLoading(false)
                clearProfile(mutate)
              } else {
                setIsAuthenticated(true)
                clearProfile(mutate) // todo: save this into state via swr
                setLoaded(true)
                setLoading(false)
                  console.log("Profile:", res)
              }
            },
              (error) => {
                console.error("Error in Authenticated:", error);
                setIsAuthenticated(false);
                clearProfile(mutate)
                setLoaded(true)
                setLoading(false)
              }
          );
      }
    }, [isAuthenticated, loaded, mutate, loading, setLoading, setIsAuthenticated]
  )

  if (isAuthenticated) {
    if (loaded) {
      return children
    } else {
      return <Loading msg="Authentication information"/>
    }
  } else {
    return <Login/>
  }
}