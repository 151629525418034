import React, {useEffect, useState} from 'react'
import {useLocation, useParams, withRouter} from "react-router-dom";
import {FormControl} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Layout from "../common/Layout";
import Loading from "../../common/Loading";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import CrudFormBase from "../../common/CrudFormBase";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {useAppContext} from "../../common/AppContext";
import {prepareEntity} from "../../../util/remoteentityutils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import {useApplicationEnvironment} from "../../../util/hooks";

export default withRouter(Form)

function Form(props) {
  const [{notifications}, setNotifications] = useAppContext();
  const location = useLocation();
  const readonly = location.pathname.indexOf("/view/") > -1;

  // load data
  const {aid} = useParams();
  const [loadedForEdit, setLoadedForEdit] = useState(false)
  const [attr, setAttr] = useState({});
  useEffect(() => {
    if (aid && !loadedForEdit) {
      fetchw(`/api/super/attribute/${aid}`)
        .then((res) => {
          setAttr(res);
          setLoadedForEdit(true)
        })
    }
  }, [aid, loadedForEdit, setAttr, setLoadedForEdit])

  const {attributeEditors, attributeRenderers} = useApplicationEnvironment()

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const endpoint = loadedForEdit ? `/api/super/attribute` : "/api/super/attribute"
    const method = loadedForEdit ? "POST" : "PUT"
    fetchwmp(endpoint, {
      method: method,
      body: prepareEntity(attr)
    })
      .then((res) => {
          notifications.push({severity: "success", body: `The attribute '${attr.name}' was saved successfully.`})
          setNotifications(notifications)
          props.history.push('/admin/super/attribute');
        }, (error => {
          console.error("error adding attribute", error)
          switch (error.status) {
            case 400:
              notifications.push({
                severity: "error",
                body: "Saving the attribute failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
              break;
            case 409:
              notifications.push({severity: "error", body: "Saving the attribute failed - duplicate entry."})
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: "Saving the attribute failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }

  if ((aid == null || loadedForEdit) && attributeEditors && attributeRenderers) {
    return (
        <Layout>
          <CrudFormBase singular="Attribute" plural="Attributes" uiPath="/admin/super/attribute"
                        loadedForEdit={loadedForEdit} submitHandler={handleSubmit}>

            <FormControl fullWidth style={{margin: 15}}>
              <TextField disabled={true} id="id" label="ID" variant="outlined"
                         defaultValue={attr?.id}
              />
            </FormControl>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="name" label="Name" variant="outlined" required
                       defaultValue={attr?.name}
                       onChange={(e) => {
                         setAttr({...attr, name: e.target.value})
                       }}
            />
            <FormHelperText>The code name for this attribute. This will not be displayed to users. Typical
              values: <code>ci.firstname</code>, <code>season.external-payment-link</code></FormHelperText>
          </FormControl>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="label" label="Label" variant="outlined" required
                       defaultValue={attr?.label}
                       onChange={(e) => {
                         setAttr({...attr, label: e.target.value})
                       }}
            />
            <FormHelperText>The main label that appears on the edit screens for this attribute.</FormHelperText>
          </FormControl>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="helptext" label="Help Text" variant="outlined"
                       defaultValue={attr?.helptext}
                       onChange={(e) => {
                         setAttr({...attr, helptext: e.target.value})
                       }}
            />
            <FormHelperText>Detailed helper text that helps people understand what to enter into the attribute. (This
              sentence is in the Help Text field.)</FormHelperText>
          </FormControl>

          <Grid container>
            <Grid xs={5}>
              <FormControl fullWidth style={{margin: 15}} disabled={readonly}>

                <InputLabel variant="outlined" id="dt-label">
                  Data type
                </InputLabel>
                <Select required
                        id="datatype"
                        variant="outlined"
                        label="Data type"
                        labelId="dt-label"
                        value={attr?.datatype ? attr.datatype : ''}
                        onChange={(e) => {
                          setAttr({...attr, datatype: e.target.value})
                        }
                        }
                >
                  <MenuItem value="INTEGER">Integer</MenuItem>
                  <MenuItem value="DECIMAL">Decimal</MenuItem>
                  <MenuItem value="STRING">String</MenuItem>
                  <MenuItem value="TEXT">Long text</MenuItem>
                  <MenuItem value="BOOLEAN">Boolean</MenuItem>
                  <MenuItem value="DATE">Date</MenuItem>
                  <MenuItem value="BINARY">Binary</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={1}> </Grid>
            <Grid xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={attr?.required}
                    onChange={(e) => {
                      setAttr({...attr, required: e.target.checked})
                    }}
                    name="required"
                    color="primary"
                  />
                }
                label="Required"
              />
            </Grid>
            <Grid xs={1}> </Grid>
            <Grid xs={2}>

              <FormControlLabel
                control={
                  <Switch
                    checked={attr?.multiple}
                    onChange={(e) => {
                      setAttr({...attr, multiple: e.target.checked})
                    }}
                    name="multiple"
                    color="primary"
                  />
                }
                label="Multiple"
              />
            </Grid>
            <Grid xs={1}> </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={5}>
              <FormControl fullWidth style={{margin: 15}} disabled={readonly}>

                <InputLabel variant="outlined" id="r-label">
                  Attribute Renderer
                </InputLabel>
                <Select
                  id="renderer"
                  variant="outlined"
                  label="Attribute Renderer"
                  labelId="r-label"
                  value={attr?.renderer ? attr.renderer : ''}
                  onChange={(e) => {
                    setAttr({...attr, renderer: e.target.value})
                  }
                  }
                >
                  <MenuItem value="">-- Use Default --</MenuItem>
                  {attributeRenderers.map((item) => {
                    return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  })}
                </Select>
                <FormHelperText>Select the display format for this attribute when it is presented in read-only mode. If
                  you are not sure what to choose, leave this blank or choose the default.</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={5}>
              <FormControl fullWidth style={{margin: 15}} disabled={readonly}>
                <InputLabel variant="outlined" id="e-label">
                  Attribute Editor
                </InputLabel>
                <Select
                  id="editor"
                  variant="outlined"
                  label="Attribute Editor"
                  labelId="e-label"
                  value={attr?.editor ? attr.editor : ''}
                  onChange={(e) => {
                    setAttr({...attr, editor: e.target.value})
                  }
                  }
                >
                  <MenuItem value="">-- Use Default --</MenuItem>
                  {attributeEditors.map((item) => {
                    return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  })}
                </Select>
                <FormHelperText>Select the editor format for this attribute when it is presented in forms. If you are
                  not sure what to choose, leave this blank or choose the default.</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={1}/>
          </Grid>
        </CrudFormBase>
      </Layout>
    );
  } else {
    return <Loading msg="attribute form"/>
  }
}