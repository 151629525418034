import React, {useEffect, useState} from 'react'
import {useAppContext} from "../../core/common/AppContext";
import {hasRole} from "../../util/securityutils";
import fetchw from "../../util/fetchw";
import Loading from "../../core/common/Loading";
import {useDebug, useProfile} from "../../util/hooks";

export default function (props) {
  const {
    children
  } = props

  const [bootstrapProcessed, setBootstrapProcessed] = useState(false)
  const [setupDone, setSetupDone] = useState(false)
  const {profile} = useProfile();
  const debug = useDebug()
  const [{notifications}, updateAppContext] = useAppContext()
  useEffect(() => {
    // run this once and only after we processed the bootstrap service
    if (!setupDone && !bootstrapProcessed) {

      // Does the user have augmented privs? Tell them to be careful.
      const augmentedPrivRoles = ["sysadmin", "orgadmin", "attendanceTaker"]
      let elevated = false;
      for (const r of augmentedPrivRoles) {
        if (hasRole(profile, r)) {
          elevated = true;
        }
      }
      if (elevated) {
        let msg = "You are running with elevated privileges ("
        let comma = false;
        for (const r of profile.roles) {
          if (comma) msg += ", "
          msg += r.name
          comma = true
        }
        msg += "). Please be careful."
        notifications.push({
          severity: "warning",
          body: msg
        })
        updateAppContext({
          notifications: notifications
        })
      }


      // Is the current user's role pending approval?  Tell them.
      if (hasRole(profile, "pendingApproval")) {
        notifications.push({
          severity: "info",
          title: "Your profile is pending approval.",
          body: "Please note your profile is in the pending state. You may complete registration, but some other features will not be available to you until your profile has been approved by our administrative staff."
        })
        updateAppContext({
          notifications: notifications
        })
      }

      setSetupDone(true)
    }
  }, [setupDone, bootstrapProcessed, profile, notifications, updateAppContext])

  useEffect(() => {
    if (!bootstrapProcessed) {
      fetchw("/api/ensemble/bootstrap")
        .then(res => {
          // notify admin of pending privs
          const pp = res.pending_profiles; // only present if the user is allowed to see them.
          if (pp) {
            if (pp.length > 0) {
              let names = ""
              for (const p of pp) {
                if (names.length > 0) names += ", "
                names += p.handle
              }
              notifications.push({severity: "info", title: "Profiles requiring approval", body: `${names}`})
              updateAppContext({notifications: notifications})
            }
          }

          // capture the current season
          if (res.current_season) {
            updateAppContext({currentSeason: res.current_season}) // available for everyone
            if (debug) console.log("Current season is ", res.current_season)
          } else {
            notifications.push({
              severity: "error",
              title: "Configuration error",
              body: "There is no active season configured."
            })
            updateAppContext({notifications: notifications})
          }

          setBootstrapProcessed(true)
        }, error => {
          console.error("Error setting pending profiles", error);
        })
    }
  }, [debug, bootstrapProcessed, setBootstrapProcessed, notifications, updateAppContext])
  if (!bootstrapProcessed) return <Loading msg={"Ensemble App"}/>
  return <>
    {children}
  </>
}