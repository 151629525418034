import React, {useEffect, useState} from 'react'
import {useAppContext} from "./AppContext";
import Loading from "./Loading";
import {useApplicationEnvironment} from "../../util/hooks";

function EnsureAttribute({attributes, children}) {
  const {attributeMap} = useApplicationEnvironment()
  const [{notifications}, setNotifications] = useAppContext();

  const [setupDone, setSetupDone] = useState(false);

  useEffect(() => {
    if (attributeMap && attributes && !setupDone) {
      for (var i = 0; i < attributes.length; i++) {
        const a = attributes[i]
        if (attributeMap.get(a) == null) {
          notifications.push({
            severity: "error",
            title: "Configuration Incomplete",
            body: "The attribute '" + a + "' is required but it is not yet configured."
          })
        } else {
          // attribute is set. No problem.
        }
      }
      setNotifications(notifications)
      setSetupDone(true)
    }

  }, [notifications, setNotifications, attributeMap, setupDone, setSetupDone, attributes])


  if (setupDone) {
    return children;
  } else {
    return <Loading msg={"configuration"}/>
  }
}

export default EnsureAttribute