import React from 'react';
import Layout from "./common/Layout";
import Loading from "../core/common/Loading";
import YourSectionInfo from "./components/YourSectionInfo";
import YourInstrumentInfo from "./components/YourInstrumentInfo";
import {useApplicationEnvironment} from "../util/hooks";

function SectionPage() {
  const {organization} = useApplicationEnvironment()

  if (organization) {
    return <Layout>
      <YourInstrumentInfo open={false}/>
      <YourSectionInfo open={true}/>
    </Layout>
  } else {
    return <Loading msg="section page"/>
  }
}

export default SectionPage