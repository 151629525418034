import React, {useEffect, useState} from 'react'
import Layout from "../common/Layout";
import fetchw from "../../../util/fetchw";
import Loading from "../../common/Loading";
import CrudListHeadings from "../../common/CrudListHeadings";
import CrudListCommandBar from "../../common/CrudListCommandBar";
import {Link} from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import RequireRole from "../../common/RequireRole";
import IconButton from "@material-ui/core/IconButton";
import {Table} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

export default function () {

  const [items, setItems] = useState(null)

  useEffect(
    () => {
      if (items == null) {
        fetchw(`/api/super/profile/list`)
          .then(
            (res) => {
              setItems(res);
            },
            (error) => {
              console.error("ERROR:", error);
            }
          );
      }
    }, [items, setItems]
  )

  if (items) {
    return <Layout>
      <CrudListHeadings singular="Profile" plural="Profiles" uiPath="/admin/super/profile"/>
      <Table className="table table-striped">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <RequireRole role={"sysadmin"}>
              <TableCell>User</TableCell>
              <TableCell>Organization</TableCell>
            </RequireRole>
            <TableCell>Handle</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Enabled</TableCell>
            <TableCell>Commands</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            items.map((item) =>
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <RequireRole role={"sysadmin"}>
                    <TableCell>
                      {item.uid}
                      {' '}<IconButton component={Link}
                                       to={`/admin/super/user/view/${item.uid}`}><VisibilityIcon/></IconButton>
                    </TableCell>
                    <TableCell>
                      {item.org}
                      {' '}<IconButton component={Link}
                                       to={`/admin/super/org/view/${item.org}`}><VisibilityIcon/></IconButton>
                    </TableCell>
                  </RequireRole>
                  <TableCell>{item.handle}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.enabled ? "yes" : "no"}</TableCell>
                  <TableCell>
                    <CrudListCommandBar uiPath="/admin/super/profile" apiPath="/api/super/profile"
                                        entityInfo={{
                                          eid: item.id,
                                          label: item.handle,
                                          enabled: item.enabled
                                        }}
                                        canDelete={false} clearState={setItems}/>
                  </TableCell>
                </TableRow>
            )
          }
        </TableBody>
      </Table>
    </Layout>
  } else {
    return <Layout><Loading msg="profiles"/></Layout>
  }
}