import React, {useEffect, useState} from 'react'
import {useLocation, withRouter} from "react-router-dom";
import {FormControl} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import fetchw from "../../util/fetchw";
import Loading from "./Loading";
import DynamicAttributeEditor from "./crud/DynamicAttributeEditor";
import {useApplicationEnvironment, useDebug,} from "../../util/hooks";

export default withRouter(FormBase)

function FormBase(props) {
  const {
    entity,
    updateEntity,
    isComponent,
    isPage
  } = props

  const location = useLocation();
  const readonly = location.pathname.indexOf("/view/") > -1;

  const debug = useDebug()
  const {subtypesForComponents, subtypesForPages} = useApplicationEnvironment()

  const [subtypes, setSubtypes] = useState(null)
  const [selectedSubtype, setSelectedSubtype] = useState(null)
  const [attributes, setAttributes] = useState(null);

  useEffect(
      () => {
        if (entity != null && subtypes == null && subtypesForPages != null && subtypesForComponents != null) {
          if (isPage) {
            const sst = subtypesForPages.get(entity.subtype)
          if (debug) console.log("isPage -  subtypesForPages", subtypesForPages);
          if (debug) console.log("isPage - Setting selected subtype to ", sst);
          setSelectedSubtype(sst)
          setSubtypes(subtypesForPages);
        } else if (isComponent) {
          const sst = subtypesForComponents.get(entity.subtype)
          if (debug) console.log("isComponent - subtypesForComponents ", subtypesForComponents);
          if (debug) console.log("isComponent - Setting selected subtype to ", sst);
          setSelectedSubtype(sst);
          setSubtypes(subtypesForComponents);
        } else {
          console.error("Illegal call to CrudFormAttributeSubtypeField - page vs component")
        }
      }
    }, [debug, subtypes, setSubtypes, entity, subtypesForComponents, subtypesForPages, isComponent, isPage]
  )

  useEffect(() => {
    if (selectedSubtype && attributes == null) {
      fetchw(`/api/super/subtype/${selectedSubtype.id}/attributes`)
        .then((res) => {
          setAttributes(res)
          if (debug) console.log("Loaded attributes for subtype", res)
        }, (error) => {
          console.error("Error loading attributes for subtypes: ", error);
        })
    }
  }, [debug, selectedSubtype, attributes, setAttributes])


  if (subtypes != null) {
    if (debug) console.log("Subtypes list is ", subtypes)
    if (debug) console.log("Entity subtype is ", entity?.subtype)
    return <>
      <hr/>
      <fieldset disabled={readonly}>

        <FormControl fullWidth required style={{margin: 15}} disabled={readonly}>
          <InputLabel id="orgLabel">Subtype</InputLabel>
          <Select
            labelId="orgLabel"
            id="org"
            value={entity?.subtype ? entity.subtype : ''}
            onChange={(e) => {
              updateEntity({...entity, subtype: e.target.value})
              setSelectedSubtype(subtypes.get(e.target.value))
              setAttributes(null)
            }}
          >
            {
              Array.from(subtypes.keys()).map(key => {
                return <MenuItem key={key} value={key}>{subtypes.get(key).name}</MenuItem>
              })
            }
          </Select>
        </FormControl>

        <hr/>
        {
          selectedSubtype ?
            attributes?.map((attr, index) => (
              <DynamicAttributeEditor key={index} entity={entity} attribute={attr.name}
                                      updateEntity={updateEntity} readonly={readonly}/>
            ))
            : ""
        }
      </fieldset>

    </>
  } else {
    return <Loading msg={"fields"}/>
  }
}