import React from 'react';
import Button from "@material-ui/core/Button";
import googleLogo from '../../img/google-logo.svg';
import {googleAuthUrl} from './constants';

function LoginButton() {

  function login() {
    window.location.href = googleAuthUrl()
  }

  return <Button fullWidth startIcon={<img src={googleLogo} alt={"G"} width={20} height={20}/>}
                 onClick={(e) => login()}>Log in with
    Google</Button>
}

export default LoginButton;