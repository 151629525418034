import React from 'react'
import Layout from "./common/Layout";
import Ping from "../common/Ping";
import Typography from "@material-ui/core/Typography";
import ThemeSummary from "../common/theme/ThemeSummary";

export default function () {
  return (
    <Layout>
      <Typography variant="h1">Warhol Admin Console</Typography>
      <p>&nbsp;</p>
      <Ping/>
      <p>&nbsp;</p>
      <ThemeSummary/>
    </Layout>
  )
}
