import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {useLocation, withRouter} from "react-router-dom";

export default withRouter(TransferList);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 300,
    height: 330,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function extractInitLeft(codes) {
  const result = [];
  if (codes != null) {
    codes.map((item) => {
      result.push(item)
      return result
    });
  }
  return result;
}

function TransferList(props) {
  const {
    selectionsLabel,
    initialSelectionCodes,
    optionsLabel,
    allOptionsCodeDescriptionMap,
    selectionChangedHandler
  } = props

  const location = useLocation();
  const readonly = location.pathname.indexOf("/view/") > -1;

  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const [left, setLeft] = React.useState(extractInitLeft(initialSelectionCodes));
  let initRight = []
  if (allOptionsCodeDescriptionMap) initRight = (not(Array.from(allOptionsCodeDescriptionMap.keys()), extractInitLeft(initialSelectionCodes)))
  const [right, setRight] = React.useState(initRight);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    selectionChangedHandler([])
  };

  const handleCheckedRight = () => {

    setRight(right.concat(leftChecked));
    const newLeft = not(left, leftChecked);
    setLeft(newLeft);
    setChecked(not(checked, leftChecked));
    selectionChangedHandler(newLeft)
  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked);
    setLeft(newLeft);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    selectionChangedHandler(newLeft)
  };

  const handleAllLeft = () => {
    const newLeft = left.concat(right);
    setLeft(newLeft);
    setRight([]);
    selectionChangedHandler(newLeft)
  };

  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value, index) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem disabled={readonly} key={index} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{'aria-labelledby': labelId}}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={allOptionsCodeDescriptionMap.get(value)}/>
            </ListItem>
          );
        })}
        <ListItem/>
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>
        <p><strong>{selectionsLabel}</strong></p>
        {customList(left)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0 || readonly}
            aria-label="move all left"
          >
            ≪
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || readonly}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || readonly}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0 || !left || readonly}
            aria-label="move all right"
          >
            ≫
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <p><strong>{optionsLabel}</strong></p>
        {customList(right)}
      </Grid>
    </Grid>
  );
}
