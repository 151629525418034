import React from 'react';
import LoginButton from "./common/LoginButton";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import {useAppInfo} from "../util/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

export default function () {
  const classes = useStyles();
  return (<>
    <CssBaseline/>
    <Backdrop open className={classes.backdrop}>
      <Container maxWidth="sm">
        <Card className={classes.card}>
          <CardContent>
            <Content/>
          </CardContent>
        </Card>
      </Container>
    </Backdrop>
  </>);
}

function Content() {
  const {appinfo, organization} = useAppInfo();
  return <>
    <Typography align="center" variant="h5">{organization.name}</Typography>
    <Typography align="center" variant="h5">{appinfo.short_app_name}</Typography>
    <Typography align="center" variant="h6" gutterBottom>Log in to your account</Typography>
    <Typography variant="body1" gutterBottom>
      Welcome! This application requires an active Google account for authentication and access to shared drives.
      When you log in, you will be asked to grant Kingsway Digital permission to view your email address, name, and
      public profile image.
    </Typography>
    <Typography variant="body1" gutterBottom>
      If you don't have a Google account, click on the button below and create one when prompted.
    </Typography>
    <LoginButton/>
  </>
}