import React from 'react'
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Layout from "../common/Layout";
import fetchw from "../../../util/fetchw";
import {useAppContext} from "../../common/AppContext";

export default function () {
    const [{notifications}, setNotifications] = useAppContext();

    const initializeSite = () => {
        fetchw(`/api/ensemble/init/attributes`)
            .then((res) => {
                if (res.status === 200) {
                    notifications.push({
                        severity: "success",
                        body: `Attributes initialized successfully`
                    })
                    setNotifications(notifications)
                } else {
                    notifications.push({
                        severity: "warning",
                        body: `Unexpected non-error response initializing attributes: ${res.status}`
                    })
                    setNotifications(notifications)
                }
            }, (error) => {
                console.error("Error initializing attributes ", error)
                notifications.push({
                    severity: "error",
                    body: `An error has occurred initializing attributes:`
                })
                setNotifications(notifications)
            })
    }
    return <>
        <Layout>
            <Typography variant={"h2"}>Superuser Tools</Typography>
            <Button onClick={e => initializeSite()}>Initialize Attributes for New Site</Button>
        </Layout>
    </>
}