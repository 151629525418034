import React, {useEffect, useState} from 'react'
import {useLocation, withRouter} from "react-router-dom";
import Loading from "../Loading";
import DynamicAttributeEditor from "./DynamicAttributeEditor";
import {useAppContext} from "../AppContext";
import {useApplicationEnvironment} from "../../../util/hooks";

export default withRouter(DynamicAttributeFields)

function DynamicAttributeFields(props) {
  const {
    entity,
    updateEntity,
    attributeNames
  } = props

  const location = useLocation();
  const readonly = location.pathname.indexOf("/view/") > -1;
  const {attributeMap} = useApplicationEnvironment()
  const [{notifications}, setNotifications] = useAppContext();

  const [attsToRender, setAttsToRender] = useState();
  const [checkingAtts, setCheckingAtts] = useState(false);

  useEffect(() => {
    if (attributeMap != null && attributeNames != null && attsToRender == null && !checkingAtts) {
      setCheckingAtts(true);
      const attrs = [];
      for (const attrName of attributeNames) {
        const attr = attributeMap.get(attrName);
        if (attr == null) {
          notifications.push({
            severity: "error",
            title: "Configuration Error",
            body: "The attribute '" + attrName + "' is required but it is not configured for this app. Please contact the administrator."
          })
          setNotifications(notifications)
        } else {
          attrs.push(attr);
        }
      }
      setAttsToRender(attrs);
      setCheckingAtts(false);
    }
  }, [attributeMap, attributeNames, attsToRender, checkingAtts, notifications, setNotifications])


  if (attsToRender) {
    return <>
      {attsToRender.map((attr, index) => {
        return <DynamicAttributeEditor key={index} entity={entity} attribute={attr.name}
                                       updateEntity={updateEntity} readonly={readonly}/>
      })}
    </>
  } else {
    return <Loading msg={"fields"}/>
  }
}