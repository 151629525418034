import React from 'react';
import Layout from "./common/Layout";
import Registration from "./components/Registration";
import Loading from "../core/common/Loading";
import {useApplicationEnvironment} from "../util/hooks";

function RegistrationPage() {
  const {organization} = useApplicationEnvironment()

  if (organization) {
    return <Layout>
      <Registration/>
    </Layout>
  } else {
    return <Loading msg="registration page"/>
  }
}

export default RegistrationPage