import React, {lazy, Suspense} from 'react'
import Loading from "../Loading";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {useApplicationEnvironment} from "../../../util/hooks";
import {getAllAttrData, hasMultipleValues} from "../../../util/remoteentityutils";

const AttributeEditorMap = {
  'INTEGER': lazy(() => import('./editors/DefaultIntegerEditor')),
  'DECIMAL': lazy(() => import('./editors/DefaultDecimalEditor')),
  'STRING': lazy(() => import('./editors/DefaultStringEditor')),
  'TEXT': lazy(() => import('./editors/DefaultTextEditor')),
  'BOOLEAN': lazy(() => import('./editors/DefaultBooleanEditor')),
  'DATE': lazy(() => import('./editors/DefaultDateEditor')),
  'BINARY': lazy(() => import('./editors/DefaultBinaryEditor')),
  'InstrumentPicker': lazy(() => import('./editors/InstrumentPicker')),
  'RequireFolderEditor': lazy(() => import('./editors/RequireFolderEditor')),
  'ThemeSelector': lazy(() => import('./editors/ThemeSelector')),
  'ChristmasYesNoMaybe': lazy(() => import('./editors/ChristmasYesNoMaybe')),
  'HtmlEditor': lazy(() => import('./editors/HtmlEditor'))
}


export default function DynamicAttributeEditor(props) {
  const {
    attribute,
    entity,
    updateEntity,
    readonly = false
  } = props
  const {isLoading, attributeEditors, attributeMap} = useApplicationEnvironment()
  if (entity === null || attribute == null) return null;
  if (isLoading) return null;
  const attrObj = attributeMap.get(attribute);
  if (!attrObj) return null;
  const LocatedEditor = locateEditor(attrObj, attributeEditors)
  if (LocatedEditor) {
    return <Suspense fallback={<Loading msg={"dynamic editor"}/>}>
      <ValidityLogger attribute={attribute} entity={entity}/>
      <LocatedEditor attribute={attrObj} entity={entity} updateEntity={updateEntity} readonly={readonly}/>
    </Suspense>
  } else {
    console.error("Call to DynamicAttributeEditor but no attribute was specified")
    return <Alert severity="error">
      <AlertTitle>Unexpected situation</AlertTitle>
      Could not locate an editor for the attribute '{props.attribute.name}'
    </Alert>;
  }
}

function ValidityLogger(props) {
  const {
    attribute,
    entity
  } = props
  if (!attribute.multiple && hasMultipleValues(entity, attribute)) {
    console.info("Attribute " + attribute + " in " + entity.type + ":" + entity.id + " should be single-valued but has multiple values.", getAllAttrData(entity, attribute))
  }
  return null
}

function locateEditor(attribute, editors) {
  const editor = attribute.editor
  let code = attribute.datatype;
  for (const ed of editors) {
    if (ed.id === editor) {
      if (ed.codeName) {
        code = ed.codeName
        break;
      }
    }
  }
  return AttributeEditorMap[code];
}
