import React from 'react';
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useAppInfo, useDebug, useProfile} from "../../util/hooks";
import Loading, {Error} from "../common/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 30,
        textAlign: "center",
    },
    footerLink: {
        textDecoration: "none",
        '&:hover': {
            textDecoration: "none"
        }
    },
    profilePhoto: {
        width: 16,
        height: 16,
        borderRadius: 50
    }
}))

export default function () {
    const classes = useStyles();

    const debug = useDebug()
    const {appinfo, organization, isLoading, isError} = useAppInfo()
    const {profile, isLoading: pLoading, isError: pError} = useProfile()

    if (isLoading || pLoading) return <Loading msg={"footer"}/>
    if (isError || pError) return <Error msg={"footer"}/>

    return (
        <footer className={classes.root}>
            <Typography variant={"caption"}>
                <div>{appinfo.app_name} v.{appinfo.app_version}</div>
                <div>Copyright &copy; 2021 <a className={classes.footerLink} href="https://kingsway.digital/">The
                    Kingsway
                    Digital Company</a></div>

                {debug ?
                    <>
                        <hr/>
                        <div>Build: {appinfo.app_build} Version {appinfo.app_version}</div>
                        <div>Organization: {organization.name} (id: {organization.id})</div>
                        <div>Profile: {profile.picture ? <img className={classes.profilePhoto} src={profile.picture}
                                                              alt={profile.handle}/> : ""} {profile.handle} (id: {profile.id},
                            uid: {profile.uid}) ({profile.roles.map((r) => {
                                return r + ' '
                            })})
                        </div>
                    </>
                    :
                    ""
                }
            </Typography>
        </footer>
    );
}
