import React, {useEffect, useState} from 'react'
import {useParams, withRouter} from "react-router-dom";
import {Checkbox, FormControl} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Layout from "../common/Layout";
import Loading from "../../common/Loading";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CrudFormBase from "../../common/CrudFormBase";
import {useAppContext} from "../../common/AppContext";
import {useAllOrganizations, useProfile} from "../../../util/hooks";
import {prepareEntity} from "../../../util/remoteentityutils";
import SubtypeFormForModule from "./SubtypeFormForModule";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RequireRole from "../../common/RequireRole";
import {hasRole} from "../../../util/securityutils";
import SaveButton from "../../../core/common/theme/WarholButtons"

const useStyles = makeStyles((theme) => ({
  attributeForm: {
    margin: theme.spacing(1),
    padding: theme.spacing(2)
  }
}))
export default withRouter(Form)

function Form(props) {
  const classes = useStyles();

  const {profile: actor} = useProfile()

  const [{notifications}, setNotifications] = useAppContext();

  // lookup lists
  const [allRoles, setAllRoles] = useState(null);

  // load data
  const {pid} = useParams();
  const [loadedForEdit, setLoadedForEdit] = useState(false)
  const [enhancedProfile, setEnhancedProfile] = useState(null);
  const [profile, setProfile] = useState({});
  useEffect(() => {
    if (pid && !loadedForEdit) {
      fetchw(`/api/super/profile/${pid}`)
        .then((res) => {
          setEnhancedProfile(res);
          setProfile(res.profile);
          setLoadedForEdit(true)
        })
    }
  }, [pid, loadedForEdit, setProfile, setLoadedForEdit, setEnhancedProfile])

  useEffect(() => {
    if (allRoles === null) {
      fetchw('/api/super/role/list')
        .then(
          (res) => {
            setAllRoles(res);
          },
          (error) => {
            console.error("Failure reading roles", error);
          }
        );
    }
  }, [allRoles, setAllRoles])


  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!hasRole(actor, "sysadmin") && !profile.org) {
      profile.org = actor.org;
    }
    const method = loadedForEdit ? "POST" : "PUT"
    const endpoint = profile.org === actor.org ? "/api/super/profile" : "/api/super/profile/provision"
    fetchwmp(endpoint, {
      method: method,
      body: prepareEntity(profile)
    })
        .then((res) => {
          notifications.push({severity: "success", body: `The profile '${profile.handle}' was saved successfully.`})
          setNotifications(notifications)
          props.history.push('/admin/super/profile');
        }, (error => {
          switch (error.status) {
            case 409:
              notifications.push({severity: "error", body: "Saving the user failed - duplicate."})
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: "Saving the user failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }

  const handleRoleCheckbox = (event) => {
    const role = event.target.name
    const proles = profile.roles ? profile.roles : []

    if (event.target.checked) {
      const n = proles.indexOf(role)
      if (n === -1) {
        proles.push(role); // not already in the list
      }
    } else {
      const n = proles.indexOf(role)
      if (n > -1) {
        proles.splice(n, 1)
      }
    }
    setProfile({...profile, roles: proles})
  }

  const profileHasRole = (role) => {
    if (role === null) return false
    if (profile === null) return false;
    if (profile.roles == null) return false;
    return profile.roles.includes(role)
  }
  if (actor == null) return <Loading msg={"actor"}/>

  if (allRoles !== null && (pid == null || loadedForEdit)) {
    let showAttributes;
    if (profile == null) {
      // new profile - save it first
      showAttributes = false
    } else if (profile.org === actor.org) {
      // already exists and same org - so ok to show attributes
      showAttributes = true
    } else {
      // can't save attributes in another org.
      showAttributes = false;
    }
    return (
        <Layout>
          <CrudFormBase singular="Profile" plural="Profiles" uiPath="/admin/super/profile"
                        loadedForEdit={loadedForEdit} submitHandler={handleSubmit}>
            <FormControl fullWidth style={{margin: 15}}>
              <TextField disabled={true} id="id" label="id" variant="outlined"
                         defaultValue={profile?.id}
              />
            </FormControl>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="uid" label="User ID (not editable after creation)" variant="outlined"
                       defaultValue={profile?.uid}
                       helperText={enhancedProfile?.user?.handle}
                       onChange={(e) => {
                         setProfile({...profile, uid: e.target.value})
                       }}
            />
          </FormControl>
          <RequireRole role={"sysadmin"}>
            <FormControl fullWidth required={true} style={{margin: 15}}>
              <InputLabel id="orgLabel">Organization</InputLabel>
              <ProfileOrgOptions profile={profile} setProfile={setProfile}/>
            </FormControl>
          </RequireRole>


          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="handle" label="Profile Handle" variant="outlined"
                       defaultValue={profile?.handle}
                       onChange={(e) => {
                         setProfile({...profile, handle: e.target.value})
                       }}
            />
          </FormControl>

          <FormControl fullWidth style={{margin: 15}}>

            <TextField id="pictureurl" label="Picture URL" variant="outlined"
                       defaultValue={profile?.picture}
                       onChange={(e) => {
                         setProfile({...profile, picture: e.target.value})
                       }}
            />
            {profile?.picture ?
              <img style={{marginTop: 10}} src={profile.picture} width={50}
                   alt={profile?.handle ? profile.handle : ""}/>
              : ""
            }
          </FormControl>


          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="email" label="Email Address" variant="outlined"
                       defaultValue={profile?.email}
                       onChange={(e) => {
                         setProfile({...profile, email: e.target.value})
                       }}
            />
          </FormControl>

          <FormControl component="fieldset" style={{margin: 15, display: "block"}}>
            <FormControlLabel label="Enabled" control={
              <Checkbox checked={profile?.enabled ? true : false}
                        onChange={(e) => {
                          setProfile({...profile, enabled: e.target.checked})
                        }}
                        name="enabled"/>
            }
            />
          </FormControl>

          <FormControl component="fieldset" style={{margin: 15, display: "block"}}>
            <FormLabel component="legend">Roles</FormLabel>
            <FormGroup>
              {
                allRoles.map((role, index) => {
                  return <FormControlLabel key={index} label={role}
                                           control={<Checkbox checked={profileHasRole(role)}
                                                              onChange={handleRoleCheckbox}
                                                              name={role}/>
                                           }/>
                })
              }
            </FormGroup>
          </FormControl>

        </CrudFormBase>

          {showAttributes &&
              <Paper elevation={3} className={classes.attributeForm}>
                <form onSubmit={handleSubmit}>
                  <SubtypeFormForModule entity={profile} updateEntity={setProfile} moduleName={"core"}/>
                  <SubtypeFormForModule entity={profile} updateEntity={setProfile} moduleName={"ensemble"}/>
                  <SaveButton type="submit">Save</SaveButton>
                </form>
              </Paper>
          }
      </Layout>
    );
  } else {
    return <Loading msg="profile form"/>
  }
}


function ProfileOrgOptions(props) {
  const {
    profile,
    setProfile
  } = props

  const orgMap = useAllOrganizations();


  if (orgMap) {
    return <Select
      required={true}
      labelId="orgLabel"
      id="org"
      value={profile?.org ? profile.org : ''}
      onChange={(e) => {
        setProfile({...profile, org: e.target.value})
      }}>
      {
        Array.from(orgMap.keys()).map(key => {
          return <MenuItem key={key} value={key}>{orgMap.get(key).name}</MenuItem>
        })
      }
    </Select>
  } else {
    return ""
  }
}