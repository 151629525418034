import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import fetchw from "../../util/fetchw";
import {useApplicationEnvironment, useDebug, useProfile} from "../../util/hooks";
import NumberFormat from 'react-number-format';
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {titleCase} from "../../util/stringutils";
import {getFirstAttributeValue} from "../../util/remoteentityutils";

function DuesStatus() {

  const {profile} = useProfile();
  const debug = useDebug()
  const {organization} = useApplicationEnvironment()
  const duesLabel = titleCase(getFirstAttributeValue(organization, "org.label.ensemble.dues"))

  const [entity, setEntity] = useState(null);
  useEffect(() => {
    if (!entity && profile) {
      fetchw(`/api/ensemble/dues/status`)
          .then((res) => {
            setEntity(res);
            if (debug) console.log("Loaded Dues Status", res)
          }, (error) => {
          if (debug) console.log("Is new, or error is ", error)
          const e = {
            org: profile.org,
            ownerPid: profile.id
          }
          setEntity(e)
        })
    }
  }, [debug, profile, entity, setEntity])


  if (entity && entity.didRegister) {
    if (entity.balance > 0.01) {
      return <section>
        <Typography gutterBottom variant="h5">{duesLabel} Outstanding: <NumberFormat value={entity.balance}
                                                                                     displayType={'text'}
                                                                                     prefix={'$'}
                                                                                     thousandSeparator={true}
                                                                                     decimalScale={2}/></Typography>
        <Button fullWidth component={Link} to={"/ensemble/dues"}>Payment Summary</Button>
      </section>
    } else {
      return <section>
        <Typography gutterBottom variant="h5">{duesLabel} Oustanding: $0</Typography>
        <p>Thank you for your payment.</p>
      </section>
    }
  } else {
    return ""
  }
}


export default DuesStatus