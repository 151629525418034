import React from 'react';
import {Box, Typography} from "@material-ui/core";

export default function CommitmentLegend() {

    return (
        <>
            <Typography variant={"body1"}>Commitment color code legend:</Typography>
            <Box>
                <Box color="success.main">Expected</Box>
                <Box color="error.main">Not expected</Box>
                <Box color="warning.main">Did not commit</Box>
            </Box>
        </>
    )
}
