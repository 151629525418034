import React, {useEffect, useState} from 'react'
import {useLocation, useParams, withRouter} from "react-router-dom";
import {FormControl} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import {useAppContext} from "../../../core/common/AppContext";
import Layout from "../../common/Layout";
import CrudFormBase from "../../../core/common/CrudFormBase";
import Loading from "../../../core/common/Loading";
import {prepareEntity} from "../../../util/remoteentityutils";
import DynamicAttributeFields from "../../../core/common/crud/DynamicAttributeFields";
import {DatePicker} from "@material-ui/pickers";

export default withRouter(Form)

function Form(props) {

  const location = useLocation();
  const readonly = location.pathname.indexOf("/view/") > -1;

  const [{notifications}, setNotifications] = useAppContext();

  // load data
  const {eid} = useParams();
  const [loadedForEdit, setLoadedForEdit] = useState(false)
  const [entity, setEntity] = useState(null);
  useEffect(() => {
    if (eid && !loadedForEdit) {
      fetchw(`/api/ensemble/admin/season/${eid}`)
        .then((res) => {
          setEntity(res);
          setLoadedForEdit(true)
        })
    }
  }, [eid, loadedForEdit, setEntity, setLoadedForEdit])


  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const method = loadedForEdit ? "POST" : "PUT"
    fetchwmp("/api/ensemble/admin/season", {
      method: method,
      body: prepareEntity(entity)
    })
      .then((res) => {
          notifications.push({severity: "success", body: `The season '${entity.seasonStart}' was saved successfully.`})
          setNotifications(notifications)
          props.history.push('/ensemble/admin/season');
        }, (error => {
          console.error("error adding entity", error)
          switch (error.status) {
            case 400:
              notifications.push({
                severity: "error",
                body: "Saving the season failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
              break;
            case 409:
              notifications.push({severity: "error", body: "Saving the season failed - duplicate entry."})
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: "Saving the season failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }

  if ((eid == null || loadedForEdit)) {
    return (
      <Layout>
        <CrudFormBase singular="Season" plural="Seasons" uiPath="/ensemble/admin/season"
                      loadedForEdit={loadedForEdit} submitHandler={handleSubmit}>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField disabled={true} id="id" label="ID" variant="outlined"
                       defaultValue={entity?.id}
            />
          </FormControl>

          <FormControl style={{margin: 15}}>
            <DatePicker id={`${entity?.id}_start`}
                        label="Season Start"
                        disabled={readonly}
                        variant="outlined"
                        value={entity?.seasonStart}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setEntity({...entity, seasonStart: e})
                        }}
            />
          </FormControl>


          <FormControl style={{margin: 15}}>
            <DatePicker id={`${entity?.id}_endDate`}
                        label="Season End"
                        disabled={readonly}
                        variant="outlined"
                        value={entity?.seasonEnd}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setEntity({...entity, seasonEnd: e})
                        }}
            />
          </FormControl>

          <DynamicAttributeFields entity={entity} updateEntity={setEntity}
                                  attributeNames={["season.dues"]}/>

          <DynamicAttributeFields entity={entity} updateEntity={setEntity}
                                  attributeNames={["season.external-payment-link"]}/>

        </CrudFormBase>
      </Layout>
    );
  } else {
    return <Loading msg="season form"/>
  }
}