import React from 'react';
import Layout from "./common/Layout";
import Loading from "../core/common/Loading";
import Dues from "./components/Dues";
import {useApplicationEnvironment} from "../util/hooks";

function DuesPage() {
  const {organization} = useApplicationEnvironment()

  if (organization) {
    return <Layout>
      <Dues open={true}/>
    </Layout>
  } else {
    return <Loading msg="dues page"/>
  }
}

export default DuesPage