import React, {useEffect, useState} from 'react'
import fetchw from "../../../util/fetchw";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useAppContext} from "../../../core/common/AppContext";
import Layout from "../../common/Layout";
import Loading from "../../../core/common/Loading";
import {Table} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Link} from "react-router-dom";
import {WarningButton} from "../../../core/common/theme/WarholButtons";

export default function () {

  const [items, setItems] = useState(null)
  const [{notifications}, setNotifications] = useAppContext();

  useEffect(
    () => {
      if (items == null) {
        fetchw(`/api/super/profile/list-pending`)
          .then(
            (res) => {
              setItems(res);
            },
            (error) => {
              console.error("ERROR:", error);
            }
          );
      }
    }, [items, setItems]
  )

  function makeMember(event, pid) {
    fetchw(`/api/ensemble/admin/profile/pending/${pid}/make-member`)
      .then((res) => {
        notifications.push({
          severity: "success",
          body: `The user profile (${pid}) has been made a member of the organization.`
        })
        setNotifications(notifications);
      }, (error) => {
        console.error(`Disabling user profile (${pid}) has failed.`, error)
        notifications.push({
          severity: "error",
          body: `The user profile (${pid}) could not be made a member. Please contact the administrator.`
        })
        setNotifications(notifications);
      })
      .catch(`Making the user profile (${pid}) a member has failed unexpectedly.`)
    setItems(null)
  }

  function disable(event, pid) {
    fetchw(`/api/super/profile/${pid}/disable`)
      .then((res) => {
        notifications.push({
          severity: "success",
          body: `The user profile (${pid}) has been successfully disabled.`
        })
        setNotifications(notifications);
      }, (error) => {
        console.error(`Disabling user profile (${pid}) has failed.`, error)
        notifications.push({
          severity: "error",
          body: `The user profile (${pid}) could not be disabled. Please contact the administrator.`
        })
        setNotifications(notifications);
      })
      .catch(`Disabling of user profile (${pid}) failed unexpectedly.`)
    setItems(null)
  }

  function enable(event, pid) {
    fetchw(`/api/super/profile/${pid}/enable`)
      .then((res) => {
        notifications.push({
          severity: "success",
          body: `The user profile (${pid}) has been successfully enabled.`
        })
        setNotifications(notifications);
      }, (error) => {
        console.error(`Enabling item user profile (${pid}) has failed.`, error)
        notifications.push({
          severity: "error",
          body: `The user profile (${pid}) could not be enabled. Please contact the administrator.`
        })
        setNotifications(notifications);
      })
      .catch(`Enabling of user profile (${pid}) failed unexpectedly.`)
    setItems(null)
  }


  if (items) {
    return <Layout>
      <Typography variant="h1" gutterBottom>Administer Profiles</Typography>
      <Typography variant="h2" gutterBottom>Pending User Profiles</Typography>
      <Table className="table table-striped">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Handle</TableCell>
            <TableCell>Enabled</TableCell>
            <TableCell>Commands</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            items.map((item) =>
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.handle}</TableCell>
                  <TableCell>{item.enabled ? "yes" : "no"}</TableCell>
                  <TableCell>
                    <Button onClick={(e) => makeMember(e, item.id)} color="primary">Make Member</Button>{' '}
                    {item.enabled ?
                        <WarningButton onClick={(e) => disable(e, item.id)}>Disable</WarningButton> : ""}
                    {!item.enabled ?
                        <WarningButton onClick={(e) => enable(e, item.id)}>Enable</WarningButton> : ""}
                    {' '}<Button component={Link} to={"/admin/super/profile/view/" + item.id}>Inspect</Button>
                  </TableCell>
                </TableRow>
            )
          }
        </TableBody>
      </Table>
    </Layout>
  } else {
    return <Layout><Loading msg="pending profiles"/></Layout>
  }
}