import React from 'react'
import {Link} from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import {useProfile} from "../../util/hooks";

export default function (props) {
  const {
    app,
    className
  } = props

  const {profile} = useProfile();

  // todo: move the route for the profile to a more generic location, and make the profile UI more generic.
  if (app && profile) {
    if (profile.picture) {
      return <Link to={`/ensemble/profile`} className={className}><Avatar alt={profile.handle}
                                                                          src={profile.picture}/></Link>
    } else {
      return <Link to={`/ensemble/profile`} className={className}>{profile.handle}</Link>
    }
  } else {
    return <></>
  }
}