import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {clearProfile, useApplicationEnvironment, useProfile, useRequiredAttributesPopulated} from "../../util/hooks";
import {useAppContext} from "../../core/common/AppContext";
import {fetchwmp} from "../../util/fetchw";
import {prepareEntity} from "../../util/remoteentityutils";
import DynamicAttributeEditor from "../../core/common/crud/DynamicAttributeEditor";
import RequireAttribute from "../../core/common/RequireAttribute";
import DynamicAttributeRenderer from "../../core/common/crud/DynamicAttributeRenderer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveButton from '../../core/common/theme/WarholButtons'
import SectionIncomplete from "./registration/SectionIncomplete";
import {useSWRConfig} from "swr";

function YourInstrumentInfo(props) {
  const {
    open = false
  } = props

  const {profile} = useProfile();
  const {mutate} = useSWRConfig()
  const [updatedProfile, setUpdatedProfile] = useState(profile);
  const [{notifications}, updateAppContext] = useAppContext();
  const {organization} = useApplicationEnvironment()

  const [changed, setChanged] = useState(false)
  // list all attributes set in this section and the hook will confirm required ones are set
  const sectionComplete = useRequiredAttributesPopulated(profile, ["music.stagename",
    "music.instrument",
    "music.requirefolder",
    "music.requireinstrument",
    "music.requiresmartmusic",
    "music.sweatersize"
  ])

  const handleProfileUpdated = (p) => {
    setUpdatedProfile(p);
    setChanged(true)
  }

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault()
    fetchwmp("/api/profile-self-update", {
      method: "POST",
      body: prepareEntity(updatedProfile)
    })
      .then((res) => {
        notifications.push({
          severity: "success",
          body: `Your instrument information has been saved.  Please continue to the next section.`
        })
        updateAppContext({notifications: notifications})
        clearProfile(mutate)
        setChanged(false)
        }, (error => {
          switch (error.status) {
            default:
              notifications.push({
                severity: "error",
                body: "There was a problem saving your profile. Please try again. If the problem perists, contact the administrator."
              })
              updateAppContext({notifications: notifications})
          }
        })
      )
  }


  const [expanded, setExpanded] = useState(open)

  function toggle() {
    setExpanded(!expanded)
  }

  if (profile) {
    return <RequireAttribute attributes={["form.instrument.intro"]}>
      <Accordion expanded={!sectionComplete || expanded} onChange={toggle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant={"h4"}>Instrument and Music</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {!sectionComplete && <SectionIncomplete/>}
            <Typography variant={"subtitle1"}>
              <DynamicAttributeRenderer entity={organization} attribute={"form.instrument.intro"}/>
            </Typography>
            <form onSubmit={handleSubmit}>
              <fieldset>
                <DynamicAttributeEditor entity={profile} attribute="music.stagename"
                                        updateEntity={handleProfileUpdated}/>
                <DynamicAttributeEditor entity={profile} attribute="music.instrument"
                                        updateEntity={handleProfileUpdated}/>
                <DynamicAttributeEditor entity={profile} attribute="music.requirefolder"
                                        updateEntity={handleProfileUpdated}/>
                <DynamicAttributeEditor entity={profile} attribute="music.requireinstrument"
                                        updateEntity={handleProfileUpdated}/>
                <DynamicAttributeEditor entity={profile} attribute="music.requiresmartmusic"
                                        updateEntity={handleProfileUpdated}/>
                <DynamicAttributeEditor entity={profile} attribute="music.sweatersize"
                                        updateEntity={handleProfileUpdated}/>
                <SaveButton disabled={!changed} type="submit">Save and Continue</SaveButton>
              </fieldset>
            </form>
          </div>
        </AccordionDetails>
      </Accordion>
    </RequireAttribute>

  } else {
    return ""
  }
}

export default YourInstrumentInfo
