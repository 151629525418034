import {hasRole} from "../../util/securityutils";
import {useProfile} from "../../util/hooks";

const RequireRole = ({role, orElse, children}) => {
  const {profile} = useProfile();
  if (hasRole(profile, role)) {
    return children;
  } else {
    if (orElse) {
      return orElse
    } else {
      return null;
    }
  }
}

export default RequireRole