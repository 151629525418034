import React, {useEffect, useState} from 'react';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import RenderSubtypeFields from "../../common/crud/RenderSubtypeFields";
import {titleCase} from "../../../util/stringutils";
import {withRouter} from "react-router-dom";
import fetchw from "../../../util/fetchw";


function SubtypeFormForModule(props) {
  const {
    entity,
    updateEntity,
    moduleName
  } = props

  const [expanded, setExpanded] = useState(false)

  function toggle() {
    setExpanded(!expanded)
  }

  // org mod
  const [loadedSubtypes, setLoadedSubtypes] = useState(false)
  const [profileSubtype, setProfileSubtype] = useState(null);
  const [orgSubtype, setOrgSubtype] = useState(null)
  // load object
  useEffect(() => {
    if (loadedSubtypes === false) {
      let oid = entity.type === "organization" ? entity.id : entity.org;
      fetchw(`/api/super/org/${oid}/module-config/${moduleName}`)
        .then((res) => {
          setProfileSubtype(res.profileSubtype);
          setOrgSubtype(res.orgSubtype)
          setLoadedSubtypes(true)
        })
    }
  }, [entity, moduleName, setLoadedSubtypes, loadedSubtypes, setProfileSubtype, setOrgSubtype])


  if (loadedSubtypes) {
    let subtypeToUse = null;
    if (entity.type === "organization") subtypeToUse = orgSubtype
    else if (entity.type === "profile") subtypeToUse = profileSubtype
    if (subtypeToUse) {
      return (
        <Accordion expanded={expanded} onChange={toggle}>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography variant={"h4"}>{titleCase(moduleName)} Attributes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <RenderSubtypeFields entity={entity} updateEntity={updateEntity} subtypeid={subtypeToUse}/>
            </div>
          </AccordionDetails>
        </Accordion>
      )
    } else {
      return null;
    }

  } else {
    return null // not all modules actually have subtypes configured and that is ok.
  }

}

export default withRouter(SubtypeFormForModule)