import React, {createContext, useContext, useMemo, useReducer} from 'react';

const AppContext = createContext(null);

function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(`useAppContext() must be used within AppContextProvider`);
  }
  return context;
}

// usage: const [{debug}, update] = useAppContext();
function AppContextProvider(props) {

  const [state, setState] = useSpreadState({
    notifications: [],
    currentSeason: null,
  });

  const value = useMemo(() => [state, setState], [state, setState]);

  return <AppContext.Provider value={value} {...props} />;
}

function useSpreadState(initializerArg, initializer) {
  return useReducer(reducer, initializerArg, initializer);
}

function reducer(state, nextState) {
  return {...state, ...nextState};
}

export {AppContextProvider, useAppContext}