import React, {useEffect, useState} from 'react'
import {Link, withRouter} from "react-router-dom";
import {FormControl} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {prepareEntity} from "../../../util/remoteentityutils";
import {useAppContext} from "../../../core/common/AppContext";
import Loading from "../../../core/common/Loading";
import Layout from "../../common/Layout";
import DynamicAttributeEditor from "../../../core/common/crud/DynamicAttributeEditor";
import {CancelButton, SaveButton} from "../../../core/common/theme/WarholButtons";
import {useDebug, useProfile} from "../../../util/hooks";

export default withRouter(EnsembleOrgSettingsWrapper)

function EnsembleOrgSettingsWrapper(props) {
  return <Layout>
    <Typography variant={"h2"}>Organization Profile</Typography>
    <OrganizationSettings {...props} module={"ensemble"} successUrl={"/"}/>
  </Layout>
}

function OrganizationSettings(props) {
  const {module, successUrl} = props;
  const {profile} = useProfile();
  const debug = useDebug()

  const [{notifications}, setNotifications] = useAppContext();

  // load data
  const [organization, setOrganization] = useState(null)
  const [orgModules, setOrgModules] = useState(null);
  useEffect(() => {
    if (!organization && profile) {
      fetchw(`/api/super/org/${profile.org}`)
          .then((res) => {
          setOrganization(res.organization)
          setOrgModules(res.modules)
        })
    }
  }, [profile, setOrgModules, setOrganization, organization])

  const [orgSubtype, setOrgSubtype] = useState(null);

  // load object
  useEffect(() => {
    if (orgSubtype == null) {
      fetchw(`/api/super/org/${profile.org}/module-config/${module}`)
        .then((res) => {
          setOrgSubtype(res.orgSubtype);
        })
    }
  }, [profile, orgSubtype, module, setOrgSubtype])

  const [attributes, setAttributes] = useState();
  useEffect(() => {
    if (orgSubtype && attributes == null) {
      fetchw(`/api/super/subtype/${orgSubtype}/attributes`)
        .then((res) => {
          setAttributes(res)
          if (debug) console.log("Loaded attributes for subtype", res)
        }, (error) => {
          console.error("Error loading attributes for subtype: ", error);
        })
    }
  }, [debug, orgSubtype, attributes, setAttributes])

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = prepareEntity(organization);
    formData.append("orgModules", new Blob([JSON.stringify(orgModules)], {type: 'application/json'}))

    if (debug) console.log("Updated org is ", organization)
    fetchwmp("/api/super/org", {
      method: "POST",
      body: formData
    })
      .then((res) => {
          notifications.push({severity: "success", body: `Your organization settings have been updated.`})
          setNotifications(notifications)
          props.history.push(successUrl);
        }, (error => {
          switch (error.status) {
            default:
              notifications.push({
                severity: "error",
                body: "There was a problem saving your organization settings. Please try again. If the problem perists, contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }


  if (organization && attributes) {

    return (
      <form onSubmit={handleSubmit}>
        <fieldset>

          <Grid container>
            <Grid item sm={5}>
              <FormControl fullWidth style={{margin: 15}}>
                <TextField disabled={true} label="ID" variant="outlined"
                           defaultValue={organization.id}/>
              </FormControl>
              <FormControl fullWidth style={{margin: 15}}>
                <TextField disabled={true} label="Name" variant="outlined"
                           defaultValue={organization.name}/>
              </FormControl>
            </Grid>
            <Grid item sm={1}/>
            <Grid item sm={5}>
              <FormControl fullWidth style={{margin: 15}}>
                <TextField disabled={true} label="App URL Base" variant="outlined"
                           defaultValue={organization.appUrlBase}/>
              </FormControl>
              <FormControl fullWidth style={{margin: 15}}>
                <TextField disabled={true} label="Slug" variant="outlined"
                           defaultValue={organization.slug}/>
              </FormControl>
            </Grid>
            <Grid item sm={1}/>
          </Grid>

          <Grid container>
            <Grid item sm={11}>
              {attributes.map((attr, index) => (
                <DynamicAttributeEditor key={index} entity={organization} attribute={attr.name}
                                        updateEntity={setOrganization} readonly={false}/>
              ))}

            </Grid>
          </Grid>

        </fieldset>

        <SaveButton type="submit">Save</SaveButton> {' '}
        <CancelButton component={Link} to={successUrl}>Cancel</CancelButton>

      </form>


    );
  } else {
    return <Loading msg="organization settings"/>
  }
}