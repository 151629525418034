import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import fetchw from "../../util/fetchw";
import ProgramSummaryReport from "./reports/ProgramSummaryReport";
import {CircularProgress} from "@material-ui/core";
import {useDebug} from "../../util/hooks";

export default function ProgramReport(props) {
    const {
        rid
    } = props

    const debug = useDebug()
    const [report, setReport] = useState(null);

    useEffect(() => {
        if (!report) {
            fetchw(`/api/swim/report/program/${rid}`)
                .then((res) => {
                    setReport(res);
                    console.log("Loaded report", res)
                }, (error) => {
                    console.log("Error loading report ", error)
                })
        }
    }, [report, debug, rid])


    if (!report) return <CircularProgress/>

    return (
        <>
            <Typography variant="h3">Program Report</Typography>
            <Typography variant="h5">
                {report.programName}
            </Typography>
            <Typography gutterBottom>
                Dates: {report.startDate} - {report.endDate}<br/>
                Total days in complete program: {report.totalDaysInCompleteProgram}<br/>
                Club capacity: {report.clubCapacity} (target: {report.clubTargetSize})<br/>
                {report.isAverageLaneRentalRates ? "Pooling and averaging lane rental rates is enabled" : "Lane rental rates reflect actual cost per facility"}
            </Typography>

            <ProgramSummaryReport programSummaryReport={report.programSummaryReport}/>
        </>
    )
}
