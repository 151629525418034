import React, {useEffect, useState} from 'react'
import {useParams, withRouter} from "react-router-dom";
import {Checkbox, FormControl} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Layout from "../common/Layout";
import Loading from "../../common/Loading";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import CrudFormBase from "../../common/CrudFormBase";
import CrudFormComponentSimpleTransferList from "../../common/CrudFormComponentSimpleTransferList";
import {useAppContext} from "../../common/AppContext";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {prepareEntity} from "../../../util/remoteentityutils";
import {clearApplicationEnvironment, useApplicationEnvironment, useDebug} from "../../../util/hooks";
import {useSWRConfig} from "swr";

export default withRouter(Form)

function Form(props) {

    const [{notifications}, setNotifications] = useAppContext();

    // load data
    const {eid} = useParams();
    const [loadedForEdit, setLoadedForEdit] = useState(false)
    const debug = useDebug()
    const {organization} = useApplicationEnvironment()
    const [entity, setEntity] = useState({});
    useEffect(() => {
        if (eid && !loadedForEdit) {
            fetchw(`/api/super/subtype/${eid}`)
                .then((res) => {
                    if (debug) console.log("Subtype loaded:", res);
                    setEntity(res);
                    setLoadedForEdit(true)
                })
        }
  }, [debug, eid, loadedForEdit, setEntity, setLoadedForEdit])

  const [attrList, setAttrList] = useState(null)
  useEffect(
    () => {
      if (attrList == null) {
        fetchw(`/api/super/attribute/list`)
          .then(
            (res) => {
              const newMap = new Map();
              res.map((attr) => {
                newMap.set(attr.id, attr.name)
                return newMap
              })
              setAttrList(newMap);
            },
            (error) => {
              console.error("error listing attributes:", error);
            }
          );
      }
    }, [attrList, setAttrList]
  )
    const {mutate} = useSWRConfig()

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!entity.org) {
      entity.org = organization.id;
    }
    if (!entity.attributeIds) {
      entity.attributeIds = []
    }
    setEntity(entity);

    fetchwmp('/api/super/subtype', {
      method: loadedForEdit ? "POST" : "PUT",
      body: prepareEntity(entity),
    })
      .then((res) => {
        notifications.push({severity: "success", body: `The subtype '${entity.name}' was saved successfully.`})
        setNotifications(notifications)
          props.history.push('/admin/super/subtype');
          clearApplicationEnvironment(mutate)
        }, (error => {
          console.error("error adding entity", error)
          switch (error.status) {
            case 400:
              notifications.push({
                severity: "error",
                body: "Saving the subtype failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
              break;
            case 409:
              notifications.push({severity: "error", body: "Saving the user failed - duplicate entry."})
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: "Saving the user failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }

  if ((eid == null || loadedForEdit) && attrList !== null) {
    return (
      <Layout>
        <CrudFormBase singular="Content Subtype" plural="Content Subtypes" uiPath="/admin/super/subtype"
                      loadedForEdit={loadedForEdit} submitHandler={handleSubmit}>
          <input type="hidden" name="org" value={organization.id}/>
          <FormControl fullWidth style={{margin: 15}}>
            <TextField disabled={true} id="id" label="ID" variant="outlined" defaultValue={entity?.id}/>
          </FormControl>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField required id="name" label="Name" variant="outlined" defaultValue={entity?.name}
                       onBlur={(e) => {
                         setEntity({...entity, name: e.target.value})
                       }}
            />
          </FormControl>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="description" label="Description" variant="outlined" defaultValue={entity?.description}
                       multiline required
                       onBlur={(e) => {
                         setEntity({...entity, description: e.target.value})
                       }}
            />
          </FormControl>

          <FormControl component="fieldset" style={{margin: 15, display: "block"}}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={entity?.supportsPages ? true : false}
                            onChange={(e) => {
                              if (debug) console.log("Checking supportsPages")
                              setEntity({...entity, supportsPages: e.target.checked})
                            }}
                            name="supportspages"/>
                } label="Supports Pages"
              />
            </FormGroup>
          </FormControl>

          <FormControl component="fieldset" style={{margin: 15, display: "block"}}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={entity?.supportsComponents ? true : false}
                            onChange={(e) => {
                              if (debug) console.log("Checking supportsComponents")
                              setEntity({...entity, supportsComponents: e.target.checked})
                            }}
                            name="supportscomponents"/>
                } label="Supports Components"
              />
            </FormGroup>
          </FormControl>

          <CrudFormComponentSimpleTransferList
            selectionsLabel="Selected Attributes"
            initialSelectionCodes={entity.attributeIds}
            optionsLabel="Available Attributes"
            allOptionsCodeDescriptionMap={attrList}
            selectionChangedHandler={
              (newValues) => {
                entity.attributeIds = newValues;
                setEntity(entity);
              }
            }
          />

        </CrudFormBase>
      </Layout>
    );
  } else {
    return <Loading msg="subtype form"/>
  }
}