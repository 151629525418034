export const XSRF_TOKEN_KEY = '__w_xsrf_token__'
export const ACCESS_TOKEN_KEY = 'accessToken';

function oAuth2RedirectUri() {
  let port = ""
  if (window.location.port) {
    port = ":" + window.location.port
  }
  const uri = window.location.protocol + "//" + window.location.hostname + port + "/"
  // alert("Preparing redirect uri: "+uri+" 3")
  return uri
}

export function apiBaseUrl() {
  if (process.env.REACT_APP_API_BASE_URL) return process.env.REACT_APP_API_BASE_URL;
  return (process.env.REACT_APP_DEVELOPMENT_MODE === "true")
      ? window.location.protocol + "//" + window.location.hostname + ":5000"
      : window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
}

export function googleAuthUrl() {
  return apiBaseUrl() + "/oauth2/authorize/google?redirect_uri=" + oAuth2RedirectUri()
}