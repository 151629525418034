import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import {decimalHours, formatDollars} from "../../../util/stringutils";

export default function ProgramSummaryReport(props) {
    const {
        programSummaryReport
    } = props

    return (
        <>
            <Typography variant={"h5"}>Program Summary</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Group</TableCell>
                            <TableCell>Size</TableCell>
                            <TableCell>Target</TableCell>
                            <TableCell>Pool Count</TableCell>
                            <TableCell>All Pool</TableCell>
                            <TableCell>Pool Hours</TableCell>
                            <TableCell>Dryland Hours</TableCell>
                            <TableCell>Yoga Hours</TableCell>
                            <TableCell>Combined Hours</TableCell>
                            <TableCell>Profit Margin</TableCell>
                            <TableCell>Annual Fee</TableCell>
                            <TableCell>Group Revenue</TableCell>
                            <TableCell>Approx Cost Per Pool Practice</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {programSummaryReport.groupInfo.map((gi, key) => {
                            return <TableRow id={key}>
                                <TableCell>{gi.groupCode}</TableCell>
                                <TableCell>{gi.capacity}</TableCell>
                                <TableCell>{gi.targetSize}</TableCell>
                                <TableCell>{gi.weeklyPoolPracticeCount}</TableCell>
                                <TableCell>{gi.programWaterPractices}</TableCell>
                                <TableCell>{decimalHours(gi.weeklyPoolMinutes)}</TableCell>
                                <TableCell>{decimalHours(gi.weeklyDrylandMinutes)}</TableCell>
                                <TableCell>{decimalHours(gi.weeklyYogaMinutes)}</TableCell>
                                <TableCell>{decimalHours(gi.weeklyTotalMinutes)}</TableCell>
                                <TableCell>{gi.targetProfitMargin}%</TableCell>
                                <TableCell>{formatDollars(gi.annualCost)}</TableCell>
                                <TableCell>{formatDollars(gi.groupTotalRevenue)}</TableCell>
                                <TableCell>{formatDollars(gi.roughWeeklyPoolPracticeCost)}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography>
                Total revenue: {formatDollars(programSummaryReport.totalProgramRevenue)}<br/>
                Total program expense: {formatDollars(programSummaryReport.totalProgramExpense)}<br/>
                Total contingency provision: {formatDollars(programSummaryReport.contingency)}<br/>
                Total program profit (after contingency provision): {formatDollars(programSummaryReport.profit)}
            </Typography>

        </>
    )
}
