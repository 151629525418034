import {ACCESS_TOKEN_KEY, apiBaseUrl, XSRF_TOKEN_KEY} from "../core/common/constants";

/**
 * Multipart-compatible fetch function for Warhol. Sends a null content type by default.
 * @param endpoint
 * @param body
 * @param customconfig
 * @returns {Promise<Response>}
 */
export function fetchwmp(endpoint, {body, ...customconfig} = {}) {
  return fetchNoContentType(null, endpoint, {body, ...customconfig});
}

/**
 * Main warhol fetch function. The body of the post must be JSON.
 * @param endpoint
 * @param body
 * @param customconfig
 * @returns {Promise<Response>}
 */
export default function fetchw(endpoint, {body, ...customconfig} = {}) {
  return fetchNoContentType('application/json', endpoint, {body, ...customconfig});
}

/**
 * Main warhol fetch function.  Always expects a JSON response,
 * but can take any content type in the request body.
 * If found in local storage, sends back X-XSRF-TOKEN.
 * If found in local storage, will also send back a bearer token.
 * Requests that credentials be included.
 * Uses GET by default. If a body is found, uses POST.
 * @param endpoint
 * @param body
 * @param customconfig
 * @returns {Promise<Response>}
 */
function fetchNoContentType(contentType, endpoint, {body, ...customConfig} = {}) {
  const token = window.localStorage.getItem(ACCESS_TOKEN_KEY)
  const xsrfToken = window.localStorage.getItem(XSRF_TOKEN_KEY);

  const headers = {
    'Accept': 'application/json'
  }
  if (contentType) {
    headers['Content-Type'] = contentType;
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  if (xsrfToken) {
    headers['X-XSRF-TOKEN'] = xsrfToken;
    // console.log("Fetchw adding token: ", xsrfToken)
  }

  headers['X-Warhol-UI'] = 'react'

  const config = {...customConfig};
  if (customConfig && customConfig.method) {
  } else {
    config.method = body ? 'POST' : 'GET';
  }

  config.headers = {
    ...headers,
    ...customConfig.headers,
  }
  config.credentials = 'include';

  if (body) {
    if (contentType === 'application/json') {
      config.body = JSON.stringify(body)
    } else {
      config.body = body;
    }
  }

  // console.log(`fetchw: ${apiBaseUrl()}${endpoint}`, config)
  return window
      .fetch(`${apiBaseUrl()}${endpoint}`, config)
      .then((res) => {
        // console.log("fetchw raw response", res)
        // console.log("Cookies: ", document.cookie);
        return res
      })
      .then(async response => {
        const data = await response;
        if (response.status === 401) {
              // unauthorized. Credentials not/no longer valid.
              return data
            } else if (response.status === 204) { // probably from a delete - no biggie.
              return data;
            } else if (response.ok) {
              return data.json();
            } else {
              const err = {
                status: data.status,
                statusText: data.statusText,
              }
              return Promise.reject(err)
            }
          }
      )
}
