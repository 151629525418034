import React from 'react'
import Layout from "./common/Layout";
import MyProfileForm from "../core/admin/profile/MyProfileForm";
import Typography from "@material-ui/core/Typography";


export default function () {
  return <Layout>
    <Typography variant={"h2"}>Swim Profile</Typography>
    <MyProfileForm module={"swim"} successUrl={"/swim"}/>
  </Layout>
}