import React, {useEffect, useState} from 'react'
import {useLocation, withRouter} from "react-router-dom";
import fetchw from "../../../util/fetchw";
import DynamicAttributeEditor from "./DynamicAttributeEditor";
import {useDebug} from "../../../util/hooks";

function RenderSubtypeFields(props) {
  const {
    entity,
    updateEntity,
    subtypeid
  } = props;

  const location = useLocation();
  const readonly = location.pathname.indexOf("/view/") > -1;
  const debug = useDebug()
  const [attributes, setAttributes] = useState(null);

  useEffect(() => {
    if (subtypeid && attributes == null) {
      fetchw(`/api/super/subtype/${subtypeid}/attributes`)
        .then((res) => {
          setAttributes(res)
          if (debug) console.log("Loaded attributes for subtype", res)
        }, (error) => {
          console.error("Error loading attributes for subtypes: ", error);
        })
    }
  }, [debug, subtypeid, attributes, setAttributes])


  if (attributes) {
    return (
      attributes?.map((attr, index) => (
        <DynamicAttributeEditor key={index} entity={entity} attribute={attr.name}
                                updateEntity={updateEntity} readonly={readonly}/>
      ))
    )
  } else {
    return null
  }
}


export default withRouter(RenderSubtypeFields)