import React from 'react'
import Loading, {Error} from "./Loading";
import {useApplicationEnvironment} from "../../util/hooks";

function BaselineApplicationEnvironment(props) {
    const {
        children
    } = props;

    // we don't need the data, we just want to make sure it has been loaded!
    const {organization, isLoading, isError} = useApplicationEnvironment()
    if (isLoading) return <Loading msg={"application environment bootstrap information"}/>
    if (isError) return <Error msg={"application bootstrap"}/>
    console.log("Loaded organization from application environment", organization)
    return children;
}


export default BaselineApplicationEnvironment