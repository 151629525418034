import React, {useEffect, useState} from 'react'
import Layout from "../common/Layout";
import fetchw from "../../../util/fetchw";
import Loading from "../../common/Loading";
import CrudListHeadings from "../../common/CrudListHeadings";
import CrudListCommandBar from "../../common/CrudListCommandBar";
import {Table, Typography} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

export default function () {

  const [items, setItems] = useState(null)

  useEffect(
    () => {
      if (items == null) {
        fetchw(`/api/super/org/list`)
          .then(
            (res) => {
              setItems(res);
            },
            (error) => {
              console.error("error listing organizations:", error);
            }
          );
      }
    }, [items, setItems]
  )

  if (items) {
    return <Layout>
      <CrudListHeadings singular="Organization" plural="Organizations" uiPath="/admin/super/org"/>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>App URL Base</TableCell>
            <TableCell>Slug</TableCell>
            <TableCell>Enabled</TableCell>
            <TableCell>Commands</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            items.map((item) =>
              <TableRow key={item.org}>
                <TableCell><Typography>{item.org}</Typography></TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.appUrlBase}</TableCell>
                <TableCell>{item.slug}</TableCell>
                <TableCell>{item.enabled ? "yes" : "no"}</TableCell>
                <TableCell>
                  <CrudListCommandBar uiPath="/admin/super/org" apiPath="/api/super/org"
                                      entityInfo={{eid: item.org, label: item.name, enabled: item.enabled}}
                                      clearState={setItems}/>
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </Layout>
  } else {
    return <Layout><Loading msg="organizations"/></Layout>
  }
}