import React from 'react';
import Layout from "./common/Layout";
import AttendanceCommit from "./components/attendance/AttendanceCommit";

export default function AttendanceCommitPage(props) {
    return (
        <Layout>
            <AttendanceCommit/>
        </Layout>
    )
}
