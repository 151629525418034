import {useAppContext} from "../../../core/common/AppContext";

export function RenderCurrentSeasonName() {
  const [{currentSeason}] = useAppContext()

  return RenderSeasonName(currentSeason)
}

export default function RenderSeasonName(props) {
  if (props) {
    const {season} = props
    return computeSeasonName(season);
  }
  return null
}

export function computeSeasonName(season) {
  if (season) {
    const sd = new Date(season.seasonStart);
    const ed = new Date(season.seasonEnd);
    if (sd.getFullYear() === ed.getFullYear()) {
      return "" + sd.getFullYear();
    } else {
      return "" + sd.getFullYear() + " - " + ed.getFullYear()
    }
  }
  return null
}