import React, {useEffect, useState} from 'react';
import Layout from "./common/Layout";
import Typography from "@material-ui/core/Typography";
import Loading from "../core/common/Loading";
import RegistrationStatus from "./components/RegistrationStatus";
import RenderTeamDriveLink from "./components/RenderSectionTeamDriveLink";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import {makeStyles} from '@material-ui/core/styles';
import DuesStatus from "./components/DuesStatus";
import RequireRole from "../core/common/RequireRole";
import DynamicAttributeRenderer from "../core/common/crud/DynamicAttributeRenderer";
import fetchw from "../util/fetchw";
import AttendanceCommitNextEvent from "./components/attendance/AttendanceCommitNextEvent";
import {useApplicationEnvironment} from "../util/hooks";
import {Hidden} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    marginBottom: "15px"
  },
});


export default function (props) {
  const classes = useStyles(props);
  const {organization} = useApplicationEnvironment()

  // hack to load home page article for TCB
  // console.log("ensemble home page. org", organization)
  const [entity, setEntity] = useState(null);
  useEffect(() => {
    if (organization.slug === "tcb" && !entity) {
      fetchw(`/api/super/component/1`)
          .then((res) => {
            // console.log("Setting entity", res)
            setEntity(res);
          }).catch(err => {
        console.error("Error loading component 1 on tcb site", err)
      })
    }
  }, [entity, organization])

  const [mktg, setMktg] = useState(null);
  useEffect(() => {
    if (organization.slug === "tcb" && !mktg) {
      fetchw(`/api/super/component/4`)
          .then((res) => {
            // console.log("Setting entity", res)
            setMktg(res);
          }).catch(err => {
        console.error("Error loading component 4 on tcb site", err)
      })
    }
  }, [mktg, organization])

  function renderMainContentCard() {
    return <>
      <p>Welcome to {organization.name}'s new portal. The ensemble uses this platform for: </p>
      <ul>
        <li>Registration</li>
        <li>Surveys</li>
        <li>Attendance</li>
      </ul>
      <p>...and much more. If you have any questions, please do not hesitate to contact your musical director. </p>

      {/*This is a temporary hack to render the virtual recording list!*/}
      {entity ?
          <>
            <Typography variant={"h3"} gutterBottom><DynamicAttributeRenderer entity={entity}
                                                                              attribute="article.title"/></Typography>
            <DynamicAttributeRenderer entity={entity} attribute="article.body"/>
          </>
          : ""}

      {/*This is a temporary hack to render the virtual recording list!*/}
      {mktg ?
          <>
            <Typography variant={"h3"} gutterBottom>Concert Marketing Status</Typography>
            <Typography>This is a temporary section for v3.3 that displays the status of some of the marketing assets. If you're not on the marketing team you can pretty much ignore this section.</Typography>

            <Typography variant={"h5"} gutterBottom>Date</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.date"/><br />

            <Typography variant={"h5"} gutterBottom>Venue Name</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.venue-name"/><br />

            <Typography variant={"h5"} gutterBottom>Activities</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.activities"/><br />

            <Typography variant={"h5"} gutterBottom>Logistics</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.logistics"/><br />

            <Typography variant={"h5"} gutterBottom>Pieces</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.pieces"/><br />

            <Typography variant={"h5"} gutterBottom>Theme</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.theme"/><br />

            <Typography variant={"h5"} gutterBottom>Title</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.title"/><br />

            <Typography variant={"h5"} gutterBottom>Tagline</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.tagline"/><br />

            <Typography variant={"h5"} gutterBottom>Short Summary</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.short-summary"/><br />

            <Typography variant={"h5"} gutterBottom>Short Description</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.short-description"/><br />

            <Typography variant={"h5"} gutterBottom>Long Description</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.long-description"/><br />

            <Typography variant={"h5"} gutterBottom>Event Listing Updated</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.checklist.event-listing-updated"/><br />

            <Typography variant={"h5"} gutterBottom>Home Page Updated</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.checklist.homepage-updated"/><br />

            <Typography variant={"h5"} gutterBottom>Eventbrite Updated</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.checklist.eventbrite-updated"/><br />

            <Typography variant={"h5"} gutterBottom>Facebook Updated</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.checklist.facebook-updated"/><br />

            <Typography variant={"h5"} gutterBottom>LinkedIn Updated</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.checklist.linkedin-updated"/><br />

            <Typography variant={"h5"} gutterBottom>Partners Updated</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.checklist.partners-updated"/><br />

            <Typography variant={"h5"} gutterBottom>Venue Updated</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.checklist.venue-updated"/><br />

            <Typography variant={"h5"} gutterBottom>Members Exposed</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.checklist.members-exposed"/><br />

            <Typography variant={"h5"} gutterBottom>Sponsors Exposed</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.checklist.sponsors-exposed"/><br />

            <Typography variant={"h5"} gutterBottom>VIPs Exposed</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.checklist.vips-exposed"/><br />

            <Typography variant={"h5"} gutterBottom>President's Welcome</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.program.president-welcome"/><br />

            <Typography variant={"h5"} gutterBottom>Conductors Corner</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.program.conductors-corner"/><br />

            <Typography variant={"h5"} gutterBottom>Musician Spotlight</Typography>
            <DynamicAttributeRenderer entity={mktg} attribute="concert.program.musician-spotlight"/><br />
          </>
          : ""}
    </>
  }

  function renderQuickLinksCard() {
    return <Card>
      <CardContent>
        <Typography variant={"h5"} gutterBottom>Quick Links</Typography>
        <RequireRole role={"member"}>
          <div className={classes.root}><RenderTeamDriveLink/></div>
          <div className={classes.root}><AttendanceCommitNextEvent/></div>
        </RequireRole>
        <div className={classes.root}><RegistrationStatus/></div>
        <RequireRole role={"member"}>
          <div className={classes.root}><DuesStatus/></div>
        </RequireRole>
      </CardContent>
    </Card>
  }

  if (organization) {
    return <Layout>
      <Typography variant={"h2"}>Welcome!</Typography>

      <Grid container spacing={1}>
        <Hidden mdUp>
          <Grid item sm={12} md={4}>
            {renderQuickLinksCard()}
          </Grid>
        </Hidden>
        <Grid item sm={12} md={8}>
          {renderMainContentCard()}
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={4}>
            {renderQuickLinksCard()}
          </Grid>
        </Hidden>
      </Grid>


    </Layout>

  } else {
    return <Loading msg="ensemble application"/>
  }

}