import React from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import {useAppContext} from "./AppContext";
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear'
import AlertTitle from "@material-ui/lab/AlertTitle";
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
  return <MuiAlert style={{marginBottom: 10}} elevation={6} variant="filled" {...props} />;
}

export default function () {
  const [{notifications}, set] = useAppContext();

  function handleClose(index) {
    let newnots = notifications.splice(index, 1)
    set(newnots)
  }

// severity: error|warning|info|success
  if (notifications && notifications.length > 0) {
    return <section className="mt-3" id="alerts">
      {
        notifications.map((notification, index) => {
          if (notification.severity === "success") {
            return <Snackbar key={index} open={true} autoHideDuration={15000} onClose={(e) => {
              handleClose(index);
            }
            }><Alert key={index} severity={notification.severity}
                     action={<IconButton key={index} aria-label="dismiss" onClick={(e) => {
                       handleClose(index);
                     }}><ClearIcon/></IconButton>}>
              {notification.title ? <AlertTitle>{notification.title}<br/></AlertTitle> : ""}
              {notification.body}
            </Alert>
            </Snackbar>
          } else {
            return <Snackbar key={index} open={true} autoHideDuration={15000} onClose={(e) => {
              handleClose(index);
            }
            }><Alert key={index} severity={notification.severity}
                     action={<IconButton key={index} aria-label="dismiss" onClick={(e) => {
                       handleClose(index);
                     }}><ClearIcon/></IconButton>}>
              {notification.title ? <AlertTitle>{notification.title}<br/></AlertTitle> : ""}
              {notification.body}
            </Alert></Snackbar>
          }
        })
      }
    </section>
  } else {
    return ""
  }
}