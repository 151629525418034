import React from 'react'

function RenderProfileImage(props) {
  const {
    profile,
    size = 32
  } = props

  if (profile) {
    if (profile.picture) {
      return <img src={profile.picture} alt={profile.handle} width={size} height={size}/>
    }
  } else {
    return <span style={{width: size, height: size}}>&nbsp;</span>
  }
}

export default RenderProfileImage