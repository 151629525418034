import React, {useEffect, useState} from 'react'
import {Link, withRouter} from "react-router-dom";
import {FormControl} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Loading from "../../common/Loading";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import {useAppContext} from "../../common/AppContext";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DynamicAttributeEditor from "../../common/crud/DynamicAttributeEditor";
import {prepareEntity} from "../../../util/remoteentityutils";
import {CancelButton, SaveButton} from "../../../core/common/theme/WarholButtons"
import {useDebug, useProfile} from "../../../util/hooks";

export default withRouter(Form)

function Form(props) {
  const {module, successUrl} = props;
  const {profile} = useProfile();
  const debug = useDebug()
  const [{notifications}, setNotifications] = useAppContext();

  // load data
  const [enhancedProfile, setEnhancedProfile] = useState(null);
  const [loadingEP, setLoadingEP] = useState(null);
  useEffect(() => {
    if (!loadingEP && !enhancedProfile && profile) {
      setLoadingEP(true)
      fetchw(`/api/super/profile/${profile.id}`)
          .then((res) => {
          setEnhancedProfile(res);
          setLoadingEP(false)
        })
    }
  }, [profile, enhancedProfile, setEnhancedProfile, loadingEP, setLoadingEP])

  // org mod
  const [profileSubtype, setProfileSubtype] = useState(null);

  // load object
  useEffect(() => {
    if (profileSubtype == null) {
      fetchw(`/api/super/org/${profile.org}/module-config/${module}`)
        .then((res) => {
          setProfileSubtype(res.profileSubtype);
        })
    }
  }, [profile, profileSubtype, module, setProfileSubtype])

  const [attributes, setAttributes] = useState();
  useEffect(() => {
    if (profileSubtype && attributes == null) {
      fetchw(`/api/super/subtype/${profileSubtype}/attributes`)
        .then((res) => {
          setAttributes(res)
          if (debug) console.log("Loaded attributes for subtype", res)
        }, (error) => {
          console.error("Error loading attributes for subtype: ", error);
        })
    }
  }, [debug, profileSubtype, attributes, setAttributes])

  const updateProfileOnEP = (p) => {
    const ep = enhancedProfile;
    ep.profile = p;
    setEnhancedProfile(ep);
  }

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault()
    if (debug) console.log("Updated ep is ", enhancedProfile)
    fetchwmp("/api/super/profile", {
      method: "POST",
      body: prepareEntity(enhancedProfile.profile)
    })
      .then((res) => {
          notifications.push({severity: "success", body: `Your profile has been updated.`})
          setNotifications(notifications)
          props.history.push(successUrl);
        }, (error => {
          switch (error.status) {
            default:
              notifications.push({
                severity: "error",
                body: "There was a problem saving your profile. Please try again. If the problem perists, contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }


  if (enhancedProfile && attributes) {
    const rsz = enhancedProfile.profile.roles.length;

    return (
      <form onSubmit={handleSubmit}>
        <fieldset>

          <Grid container>
            <Grid item sm={5}>
              <FormControl fullWidth style={{margin: 15}}>
                <TextField disabled={true} label="Profile ID" variant="outlined"
                           defaultValue={enhancedProfile.profile.id}/>
              </FormControl>
              <FormControl fullWidth style={{margin: 15}}>
                <TextField id="handle" label="Profile Handle" variant="outlined" required={true}
                           defaultValue={enhancedProfile.profile.handle}
                           onChange={(e) => {
                             const ep = enhancedProfile;
                             ep.profile.handle = e.target.value;
                             setEnhancedProfile(ep);
                           }}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1}/>
            <Grid item sm={5}>
              <FormControl fullWidth style={{margin: 15}}>
                <TextField disabled={true} label="Login" variant="outlined"
                           defaultValue={enhancedProfile.user.login}/>
              </FormControl>
              <FormControl fullWidth style={{margin: 15}}>
                <TextField disabled={true} label="Organization" variant="outlined"
                           defaultValue={enhancedProfile.organization.name}/>
              </FormControl>
            </Grid>
            <Grid item sm={1}/>
          </Grid>

          <Grid container>
            <Grid item sm={11}>
              <Typography variant={"body1"}>
                Active Roles: <i>
                {enhancedProfile.profile.roles.map((role, idx) => {
                  return <span key={idx}>{role}{rsz > idx + 1 ? ', ' : ' '}</span>
                })}</i>
              </Typography>

              <FormControl fullWidth style={{margin: 15}}>
                <TextField id="email" variant="outlined"
                           label="Email Address"
                           helperText={"Enter the primary email address you would like to use for communication with the ensemble through this app.  Note that if this differs from any of the organization's mailing lists, you may have to update those addresses as well."}
                           defaultValue={enhancedProfile.profile.email}
                           onChange={(e) => {
                             const ep = enhancedProfile;
                             ep.profile.email = e.target.value
                             setEnhancedProfile(ep)
                           }}
                />
              </FormControl>

            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={8}>
              <FormControl fullWidth style={{margin: 15}}>
                <TextField id="pictureurl" label="Profile Image URL" variant="outlined"
                           helperText={"Enter the URL of an online avatar image you would like to use for your profile image.  Note that uploads are not yet suppported. This value defaults to the URL of your login user's avatar."}
                           defaultValue={enhancedProfile.profile.picture}
                           onChange={(e) => {
                             const ep = enhancedProfile;
                             ep.profile.picture = e.target.value
                             setEnhancedProfile(ep)
                           }}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1}/>
            <Grid item sm={1}>
              {enhancedProfile.profile.picture ?
                <img style={{marginTop: 25}} src={enhancedProfile.profile.picture} width={100}
                     alt={enhancedProfile.profile.handle}/>
                : ""
              }
            </Grid>
            <Grid item sm={1}/>
          </Grid>
          <Grid container>
            <Grid item sm={11}>
              {attributes.map((attr, index) => (
                <DynamicAttributeEditor key={index} entity={enhancedProfile.profile} attribute={attr.name}
                                        updateEntity={updateProfileOnEP} readonly={false}/>
              ))}

            </Grid>
          </Grid>

        </fieldset>

        <SaveButton type="submit">Save</SaveButton> {' '}
        <CancelButton component={Link} to={successUrl}>Cancel</CancelButton>

      </form>


    );
  } else {
    return <Loading msg="profile"/>
  }
}