import React, {useEffect, useState} from 'react'
import {useLocation, useParams, withRouter} from "react-router-dom";
import {FormControl} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import {useAppContext} from "../../../core/common/AppContext";
import Layout from "../../common/Layout";
import CrudFormBase from "../../../core/common/CrudFormBase";
import Loading from "../../../core/common/Loading";
import {prepareEntity} from "../../../util/remoteentityutils";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default withRouter(Form)

function Form(props) {

  const location = useLocation();
  const readonly = location.pathname.indexOf("/view/") > -1;

  const [{notifications}, setNotifications] = useAppContext();

  // load data
  const {eid} = useParams();
  const [loadedForEdit, setLoadedForEdit] = useState(false)
  const [entity, setEntity] = useState(null);
  useEffect(() => {
    if (eid && !loadedForEdit) {
      fetchw(`/api/ensemble/admin/instrument/${eid}`)
        .then((res) => {
          setEntity(res);
          setLoadedForEdit(true)
        })
    }
  }, [eid, loadedForEdit, setEntity, setLoadedForEdit])


  const [sections, setSections] = useState(null)
  useEffect(
    () => {
      if (sections == null) {
        fetchw(`/api/ensemble/admin/section/list`)
          .then(
            (res) => {
              setSections(res);
            },
            (error) => {
              console.error("error listing entities:", error);
            }
          );
      }
    }, [sections, setSections]
  )

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const method = loadedForEdit ? "POST" : "PUT"
    fetchwmp("/api/ensemble/admin/instrument", {
      method: method,
      body: prepareEntity(entity)
    })
      .then((res) => {
          notifications.push({severity: "success", body: `The instrument '${entity.name}' was saved successfully.`})
          setNotifications(notifications)
          props.history.push('/ensemble/admin/instrument');
        }, (error => {
          console.error("error adding entity", error)
          switch (error.status) {
            case 400:
              notifications.push({
                severity: "error",
                body: "Saving the instrument failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
              break;
            case 409:
              notifications.push({severity: "error", body: "Saving the instrument failed - duplicate entry."})
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: "Saving the instrument failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }

  if ((eid == null || loadedForEdit) && sections) {
    return (
      <Layout>
        <CrudFormBase singular="Instrument" plural="Instruments" uiPath="/ensemble/admin/instrument"
                      loadedForEdit={loadedForEdit} submitHandler={handleSubmit}>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField disabled={true} id="id" label="ID" variant="outlined"
                       defaultValue={entity?.id}
            />
          </FormControl>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField id={`entity_name`}
                       label="Name"
                       disabled={readonly}
                       variant="outlined"
                       defaultValue={entity?.name ? entity.name : ""}
                       InputLabelProps={{
                         shrink: true,
                       }}
                       onChange={(e) => {
                         setEntity({...entity, name: e.target.value})
                       }}
            />
          </FormControl>


          <FormControl fullWidth style={{margin: 15}} disabled={readonly}>
            <InputLabel id="sectionLabel">Section</InputLabel>
            <Select
              labelId="sectionLabel"
              id="sectionId"
              value={entity?.sectionId ? entity.sectionId : ''}
              onChange={(e) => {
                setEntity({...entity, sectionId: e.target.value})
              }
              }
            >
              {
                sections.map((p) => {
                  return <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>

        </CrudFormBase>
      </Layout>
    );
  } else {
    return <Loading msg="instrument form"/>
  }
}