import React, {useEffect, useState} from 'react'
import {useGlobalContext} from "./GlobalContext";
import {withCookies} from 'react-cookie';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Backdrop from "@material-ui/core/Backdrop";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Typography} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";
import {clearAppInfo, clearApplicationEnvironment, useDebug} from "../../util/hooks";
import {useSWRConfig} from 'swr'

export default withCookies(Switcher);

function Switcher(props) {
  const {
    children,
    cookies
  } = props;
  const {cache} = useSWRConfig()
  const previousCookieVal = cookies.get("dev_org_slug");
  const debug = useDebug()
  const [{dev_org_slug}, update] = useGlobalContext()
  const {mutate} = useSWRConfig()
  const [selection, setSelection] = useState(previousCookieVal)
  const [clickCount, setClickCount] = useState(0)

  useEffect(() => {
    if (selection != null && selection !== "null") {
      if (debug) console.debug("Updating global context to clear previous site info and set new dev_org_slug to: " + selection)
      update(
          {
            dev_org_slug: selection,
            profile: null, // loaded once org is known
          });
      const prevCookie = cookies.get("dev_org_slug");
      if (debug) console.debug("Setting cookie to remember selection. Old value is '" + prevCookie + "' and new value is: '" + selection + "'. Toggle count: " + clickCount + ".");
      cookies.remove("dev_org_slug");
      let d = new Date();
      const minutes = 5 * 60;
      d.setTime(d.getTime() + (minutes * 60 * 1000));
      cookies.set("dev_org_slug", selection, {path: "/", expires: d});
      console.log("Clearing AppInfo, ApplicationEnvironment and other related caches");
      clearAppInfo(mutate)
      clearApplicationEnvironment(mutate)
      cache.clear();
    }
  }, [cache, mutate, debug, selection, update, cookies, clickCount])

  const options = ["tcb", "eyb", "ejyb", "lsc", "kd"]

  function Chooser(props) {
    const {
      style
    } = props;

    return <span style={style}>
      {options.map((choice) => {
        return <span key={choice}><Button component={Link} size="small" color={"default"} to={"/"}
                                          onClick={(e) => {
                                            if (debug) console.debug("Choosing '" + choice + "'.  Current selection is '" + selection + "'")
                                            setClickCount(clickCount + 1)
                                            setSelection(null)
                                            setSelection(choice)
                                          }}>{choice}</Button>{' '}</span>
      })}
    </span>
  }

  if (showManualSiteSwitcher()) {
    if (dev_org_slug === null || dev_org_slug == null) {
      // if (debug) console.debug("Development mode - prompting user to select site")
      return (
          <>
            <DevelopmentModeSelectSite/>
            <DevelopmentModeFooter chooser={<Chooser/>}/>
          </>
      )
    } else {
      // if (debug) console.debug("Development mode - site selected - showing site")
      return <>
        {children}
        <DevelopmentModeFooter chooser={<Chooser/>}/>
      </>
    }
  } else {
    // if (debug) console.debug("Production mode - site should be known - showing site.")
    return <>{children}</>
  }
}

export function showManualSiteSwitcher() {
  const host = window.location.hostname;
  if (host === "127.0.0.1" || host === "localhost") {
    //console.log("Found development mode by hostname")
    return true;
  }

  if (process.env.REACT_APP_SHOW_SITE_SWITCHER === "true") {
    //console.log("Found development mode by process.env");
    return true;
  }
}


const developmentModeFooterHeight = 36
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  footerNav: {
    position: "fixed",
    top: "auto",
    bottom: 0,
    zIndex: 10000,
    height: developmentModeFooterHeight,
    backgroundColor: blue[700],
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    paddingTop: 3,
    paddingBottom: 3,
  },
  footerSpacer: {
    height: developmentModeFooterHeight,
    paddingTop: 3,
    paddingBottom: 3,
  },
  chooser: {}
}))

export function DevelopmentModeFooter({chooser}) {
  const classes = useStyles();
  if (showManualSiteSwitcher()) {
    return (
        <nav className={classes.footerNav}>
          <span className={classes.chooser}>{chooser}</span>
        </nav>
    )
  }
  return null;
}

export function DevelopmentModeFooterSpacer() {
  const classes = useStyles();
  if (showManualSiteSwitcher()) {
    return <span className={classes.footerSpacer}>&nbsp;</span>
  }

  return null
}


export function DevelopmentModeSelectSite({children}) {
  const classes = useStyles();
  return (<>
    <CssBaseline/>
    <Backdrop open className={classes.backdrop}>
      <Container maxWidth="sm">
        <Card>
          <CardContent>
            <Typography align="center" variant="h6">Warhol Development Mode</Typography>
            <Typography align="center" variant="h6">Site Selection</Typography>
            <hr/>
            <Typography variant="body1">
              You are running in development mode. Please select a site so that
              a customer experience can be emulated.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Backdrop>
  </>);
}