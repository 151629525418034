import React from 'react'
import Typography from "@material-ui/core/Typography";
import Layout from "../common/Layout";
import HelpDash from "../../core/help/HelpDash";
import Configuration from "../../core/help/super/Configuration";

function HelpHome() {
  return <Layout>
    <Typography variant={"h2"}>Help Centre</Typography>
    <HelpDash/>
    <Configuration/>
  </Layout>
}

export default HelpHome