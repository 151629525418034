import React from 'react';
import Typography from "@material-ui/core/Typography";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import Layout from "./common/Layout";
import ProgramReport from "./components/ProgramReport";
import ScheduleReport from "./components/ScheduleReport";

export default function SchedulePlanner() {


    return (
        <Layout>
            <Typography variant="h1">Hello world from Schedule Planner</Typography>
            <Typography variant="h3">Build your club program schedule</Typography>

            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select the Scenario</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={"10"}
                >
                    <MenuItem value={10}>Sample 1</MenuItem>
                    <MenuItem value={20}>Sample 2</MenuItem>
                    <MenuItem value={30}>Sample 3</MenuItem>
                </Select>
            </FormControl>

            <ProgramReport rid={1}/>
            <ScheduleReport rid={1}/>

        </Layout>
    )
}
