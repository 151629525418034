import React from 'react';
import Alert from "@material-ui/lab/Alert";

export default function SectionIncomplete() {
    return (
        <>
            <Alert severity="warning">
                This section is incomplete.
            </Alert>
        </>
    )
}
