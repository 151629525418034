import React, {useEffect, useState} from 'react'
import {Link, useLocation, useParams, withRouter} from "react-router-dom";
import {Checkbox, FormControl} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Layout from "../common/Layout";
import Loading from "../../common/Loading";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import {titleCase} from "../../../util/stringutils";
import CrudFormBase from "../../common/CrudFormBase";
import {useAppContext} from "../../common/AppContext";
import Grid from "@material-ui/core/Grid";
import {prepareEntity} from "../../../util/remoteentityutils";
import SubtypeFormForModule from "../profile/SubtypeFormForModule";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {EditButton, SaveButton} from '../../../core/common/theme/WarholButtons'
import {useDebug, useProfile} from "../../../util/hooks";

const useStyles = makeStyles((theme) => ({
  attributeForm: {
    margin: theme.spacing(1),
    padding: theme.spacing(2)
  }
}))
export default withRouter(Form)

function Form(props) {
  const location = useLocation();
  const readonly = location.pathname.indexOf("/view/") > -1;
  const classes = useStyles();

  const {profile} = useProfile();
  const debug = useDebug();
  const [{notifications}, setNotifications] = useAppContext();

  // lookup lists
  const [allModules, setAllModules] = useState(null)

  // refresher bug workaround
  const [refresher, setRefresher] = useState(0);

  // load data
  const {org} = useParams();
  const [loadedForEdit, setLoadedForEdit] = useState(false)
  const [organization, setOrganization] = useState({});
  const [orgModules, setOrganizationModules] = useState([]);
  useEffect(() => {
    if (org && !loadedForEdit) {
      fetchw(`/api/super/org/${org}`)
        .then((res) => {
          setOrganization(res.organization);
          setOrganizationModules(res.modules);
          setLoadedForEdit(true)
        })
    }
  }, [org, loadedForEdit, setOrganization, setOrganizationModules, setLoadedForEdit])

  // lookup lists
  useEffect(() => {
    if (allModules === null) {
      fetchw('/api/super/module/list')
        .then(
          (res) => {
            setAllModules(res);
          },
          (error) => {
            console.error("Failure reading modules", error);
          }
        );
    }
  }, [allModules, setAllModules])


  // checkbox handler
  const handleModuleCheckbox = (event) => {
    if (event.target.checked) {
      if (!orgModules.includes(event.target.name)) {
        orgModules.push(event.target.name)
      }
    } else {
      const idx = orgModules.indexOf(event.target.name);
      if (idx > -1) {
        orgModules.splice(idx, 1)
      }
    }
    setOrganizationModules(orgModules)
    setRefresher(refresher + 1); // Force repaint
  }

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const method = loadedForEdit ? "POST" : "PUT"
    const body = prepareEntity(organization);
    if (debug) console.log("orgModules is", orgModules)
    body.append("orgModules", new Blob([JSON.stringify(orgModules)], {type: 'application/json'}))
    fetchwmp("/api/super/org", {
      method: method,
      body: body
    })
      .then((res) => {
          notifications.push({
            severity: "success",
            body: `The organization '${organization.name}' was saved successfully.`
          })
          setNotifications(notifications)
          props.history.push('/admin/super/org');
        }, (error => {
          console.error("error adding organization", error)
          switch (error.status) {
            case 400:
              notifications.push({
                severity: "error",
                body: "Saving the organization failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
              break;
            case 409:
              notifications.push({severity: "error", body: "Saving the organization failed - duplicate entry."})
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: "Saving the organization failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }

  if (profile != null && allModules !== null && (org == null || loadedForEdit)) {
    const canConfigureModules = String(profile.org) === String(org);
    return (
      <Layout>
        <CrudFormBase singular="Organization" plural="Organizations" uiPath="/admin/super/org"
                      loadedForEdit={loadedForEdit} submitHandler={handleSubmit}>
          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="name" label="Name" variant="outlined"
                       defaultValue={organization?.name} required
                       onChange={(e) => {
                         setOrganization({...organization, name: e.target.value})
                       }}
            />
          </FormControl>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="appUrlBase" label="App URL Base" variant="outlined"
                       defaultValue={organization?.appUrlBase} required
                       onChange={(e) => {
                         setOrganization({...organization, appUrlBase: e.target.value})
                       }}
            />
          </FormControl>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="slug" label="Slug" variant="outlined"
                       helperText="Short code to be used in URLs to denote this organization. Must be unique. Do not include any slashes."
                       defaultValue={organization?.slug} required
                       onChange={(e) => {
                         setOrganization({...organization, slug: e.target.value})
                       }}
            />
          </FormControl>
          <FormControl component="fieldset" style={{margin: 15, display: "block"}}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={organization?.enabled ? true : false}
                            onChange={(e) => {
                              setOrganization({...organization, enabled: e.target.checked})
                            }}
                            name="enabled"/>
                } label="Enabled"
              />
            </FormGroup>
          </FormControl>

          <FormControl component="fieldset" style={{margin: 15, display: "block"}}>
            <FormLabel component="legend">Modules</FormLabel>
            <FormGroup>
              {
                allModules.map((mod) => {
                  return <Grid container key={mod}>
                    <Grid item xs={3}>
                      <FormControlLabel key={mod}
                                        control={<Checkbox checked={orgModules?.includes(mod)}
                                                           onChange={handleModuleCheckbox}
                                                           name={mod}/>
                                        }
                                        label={titleCase(mod)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <EditButton disabled={!readonly || !canConfigureModules} component={Link}
                                  to={"/admin/super/org/configure/" + org + "/" + mod}>Configure</EditButton>
                    </Grid>
                  </Grid>
                })
              }
            </FormGroup>
          </FormControl>
        </CrudFormBase>
        {organization &&
        <Paper elevation={3} className={classes.attributeForm}>
          <form onSubmit={handleSubmit}>
            <SubtypeFormForModule entity={organization} updateEntity={setOrganization} moduleName={"core"}/>
            <SubtypeFormForModule entity={organization} updateEntity={setOrganization} moduleName={"ensemble"}/>
            <SaveButton type="submit">Save</SaveButton>
          </form>
        </Paper>}
      </Layout>
    );
  } else {
    return <Loading msg="organization form"/>
  }
}