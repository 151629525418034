import React from 'react'
import {Tooltip} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RequireRole from "../../core/common/RequireRole";
import {Link} from "react-router-dom";
import EditButton from "../../core/common/theme/WarholButtons"
import Button from "../../core/common/theme/WarholButtons"

const useStyles = makeStyles((theme) => ({
  profilePhoto: {
    width: 128,
    height: 128,
    borderRadius: 128,
  },
  tooltip: {
    minWidth: 350,
    maxWidth: 600,
  },
  tooltipLink: {
    color: 'inherit',
    "&:hover": {
      color: 'inherit'
    },
  }
}))

function ProfileTooltip(props) {
  const {
    uiprofile,
    children,
    placement
  } = props;
  const classes = useStyles();

  return <Tooltip classes={{tooltip: classes.tooltip}}
                  placement={placement}
                  title={<ProfileTooltipOverlay uiprofile={uiprofile}/>} interactive>
    {children}
  </Tooltip>
}


function ProfileTooltipOverlay({uiprofile}) {
  const classes = useStyles();

  return <>
    <Grid container>
      <Grid item sm={5}>
        <img className={classes.profilePhoto} src={uiprofile.picture} alt={uiprofile.fallbackStageName}/>
      </Grid>
      <Grid item sm={7}>
        <Typography variant={"h5"}>{uiprofile.fallbackStageName}</Typography>
        <Typography variant={"h6"}>{uiprofile.instrument}</Typography>
        <Typography variant={"body2"}><strong>Email: </strong><a className={classes.tooltipLink}
                                                                 href={`mailto:${uiprofile.email}`}>{uiprofile.email}</a></Typography>
        <Typography variant={"body2"}><strong>Phone: </strong><a className={classes.tooltipLink}
                                                                 href={`tel:${uiprofile.phone}`}>{uiprofile.phone}</a></Typography>
        <RequireRole role={"orgadmin"}>
          <Typography variant={"body2"} gutterBottom><strong>Roles: </strong>{uiprofile.roles.map((r, index) => {
            if (index > 0) return ", " + r
            else return r
          })}</Typography>
          <Button component={Link} to={`/admin/super/profile/view/${uiprofile.pid}`}>View</Button>{' '}
          <EditButton component={Link} to={`/admin/super/profile/edit/${uiprofile.pid}`}>Edit</EditButton>
        </RequireRole>
      </Grid>
    </Grid>
  </>
}

export default ProfileTooltip