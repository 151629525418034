import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {Link, NavLink} from "react-router-dom";
import LogoutLink from "./LogoutLink";
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import CategoryIcon from '@material-ui/icons/Category';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import HomeIcon from '@material-ui/icons/Home';
import RequireRole from "./RequireRole";
import HelpIcon from '@material-ui/icons/Help';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import EmailIcon from "@material-ui/icons/Email";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import CheckIcon from "@material-ui/icons/Check";
import PaymentIcon from "@material-ui/icons/Payment";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import ListIcon from "@material-ui/icons/List";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import ScheduleIcon from "@material-ui/icons/Schedule";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import {useApplicationEnvironment} from "../../util/hooks";
import {titleCase} from "../../util/stringutils";
import {getFirstAttributeValue} from "../../util/remoteentityutils";
import RequireModule from "./RequireModule";
import DebugLink from "./DebugLink";


const useStyles = makeStyles((theme) => ({
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
}));

function NavDrawer() {
    const classes = useStyles();
    const {organization} = useApplicationEnvironment()
    const duesLabel = titleCase(getFirstAttributeValue(organization, "org.label.ensemble.dues"))

    return (
        <div>
            <div className={classes.toolbar}/>
            <Divider/>

            <List>
                <ListItem button component={Link} to="/ensemble">
                    <ListItemIcon><HomeIcon/></ListItemIcon>
                    <ListItemText>Home</ListItemText>
                </ListItem>
                <RequireRole role="member">
                    <ListItem button component={NavLink} to="/ensemble/dues">
                        <ListItemIcon><LocalAtmIcon/></ListItemIcon>
                        <ListItemText>{duesLabel}</ListItemText>
                    </ListItem>
                    <ListItem button component={NavLink} to="/ensemble/section">
                        <ListItemIcon><SupervisorAccountIcon/></ListItemIcon>
                        <ListItemText>My Section</ListItemText>
                    </ListItem>
                    <ListItem button component={NavLink} to="/ensemble/contact">
                        <ListItemIcon><ContactPhoneIcon/></ListItemIcon>
                        <ListItemText>My Contact Info</ListItemText>
                    </ListItem>
                    <ListItem button component={NavLink} to="/ensemble/roster">
                        <ListItemIcon><PeopleIcon/></ListItemIcon>
                        <ListItemText>Roster</ListItemText>
                    </ListItem>
                    <ListItem button component={NavLink} to="/ensemble/communication-centre">
                        <ListItemIcon><EmailIcon/></ListItemIcon>
                        <ListItemText>Communication Centre</ListItemText>
                    </ListItem>
                    <RequireRole role="orgadmin">
                        <ListItem button component={NavLink} to="/ensemble/admin/registration">
                            <ListItemIcon><PeopleIcon/></ListItemIcon>
                            <ListItemText>Registration Admin</ListItemText>
                        </ListItem>
                    </RequireRole>
                    <RequireRole role="orgadmin">
                        <ListItem button component={NavLink} to="/ensemble/admin/event">
                            <ListItemIcon><EventAvailableIcon/></ListItemIcon>
                            <ListItemText>Events</ListItemText>
                        </ListItem>
                    </RequireRole>
                    <ListItem button component={NavLink} to="/ensemble/attendance/commit">
                        <ListItemIcon><EventAvailableIcon/></ListItemIcon>
                        <ListItemText>Attendance RSVP</ListItemText>
                    </ListItem>
                </RequireRole>
                <RequireRole role="attendanceTaker">
                    <ListItem button component={NavLink} to="/ensemble/attendance/report">
                        <ListItemIcon><CheckIcon/></ListItemIcon>
                        <ListItemText>Attendance Reports</ListItemText>
                    </ListItem>
                    <ListItem button component={NavLink} to="/ensemble/attendance/season">
                        <ListItemIcon><EventAvailableIcon/></ListItemIcon>
                        <ListItemText>Attendance Summary</ListItemText>
                    </ListItem>
                </RequireRole>
                <RequireRole role="finance">
                    <ListItem button component={NavLink} to="/ensemble/dues/list">
                        <ListItemIcon><PaymentIcon/></ListItemIcon>
                        <ListItemText>Administer Dues</ListItemText>
                    </ListItem>
                </RequireRole>
            </List>


            <RequireRole role={"developer"}>{/* TODO: MANAGER*/}
                <List aria-labelledby="content-list-subheader"
                      subheader={
                          <ListSubheader id="content-list-subheader">
                              <Typography variant="h6">Content</Typography>
                          </ListSubheader>
                      }>
                    <ListItem button component={NavLink} to="/admin/super/component">
                        <ListItemIcon><FeaturedVideoIcon/></ListItemIcon>
                        <ListItemText>Components</ListItemText>
                    </ListItem>
                    <ListItem button component={NavLink} to="/admin/super/page">
                        <ListItemIcon><WebAssetIcon/></ListItemIcon>
                        <ListItemText>Pages</ListItemText>
                    </ListItem>
                </List>
            </RequireRole>

            <RequireRole role="orgadmin">
                <List aria-labelledby="report-list-subheader"
                      subheader={
                          <ListSubheader id="report-list-subheader">
                              <Typography variant="h6">Manage Access</Typography>
                          </ListSubheader>
                      }>
                    <RequireRole role={"sysadmin"}>
                        <ListItem button component={NavLink} to="/admin/super/org">
                            <ListItemIcon><BusinessIcon/></ListItemIcon>
                            <ListItemText>Organizations</ListItemText>
                        </ListItem>
                        <ListItem button component={NavLink} to="/admin/super/user">
                            <ListItemIcon><PeopleIcon/></ListItemIcon>
                            <ListItemText>Users</ListItemText>
                        </ListItem>
                    </RequireRole>
                    <ListItem button component={NavLink} to="/admin/super/profile">
                        <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                        <ListItemText>All Profiles</ListItemText>
                    </ListItem>
                    <ListItem button component={NavLink} to="/ensemble/admin/pending-users">
                        <ListItemIcon><PeopleIcon/></ListItemIcon>
                        <ListItemText>Pending Profiles</ListItemText>
                    </ListItem>
                </List>
            </RequireRole>


            <RequireRole role="manager">

                <List aria-labelledby="report-list-subheader"
                      subheader={
                          <ListSubheader id="report-list-subheader">
                              <Typography variant="h6">Reports</Typography>
                          </ListSubheader>
                      }>
                    <RequireRole role="attendanceTaker">
                        <ListItem button component={NavLink} to="/ensemble/attendance/report">
                            <ListItemIcon><CheckIcon/></ListItemIcon>
                            <ListItemText>Attendance Reports</ListItemText>
                        </ListItem>
                        <ListItem button component={NavLink} to="/ensemble/attendance/season">
                            <ListItemIcon><EventAvailableIcon/></ListItemIcon>
                            <ListItemText>Attendance Summary</ListItemText>
                        </ListItem>
                    </RequireRole>
                    <RequireRole
                        role={"orgadmin"}>{/* TODO: DOWNGRADE THESE reports to secure actions instead of the whole report*/}
                        <ListItem button component={NavLink} to="/ensemble/reports/enabled-profiles">
                            <ListItemIcon><PeopleIcon/></ListItemIcon>
                            <ListItemText>Enabled Profiles</ListItemText>
                        </ListItem>
                        <ListItem button component={NavLink} to="/ensemble/reports/disabled-profiles">
                            <ListItemIcon><PeopleIcon/></ListItemIcon>
                            <ListItemText>Disabled Profiles</ListItemText>
                        </ListItem>
                        <ListItem button component={NavLink} to="/ensemble/reports/privileged-profiles">
                            <ListItemIcon><PeopleIcon/></ListItemIcon>
                            <ListItemText>Privileged Profiles</ListItemText>
                        </ListItem>
                        <ListItem button component={NavLink} to="/ensemble/reports/member-options">
                            <ListItemIcon><PeopleIcon/></ListItemIcon>
                            <ListItemText>Member Options</ListItemText>
                        </ListItem>
                        <ListItem button component={NavLink} to="/ensemble/reports/all-users">
                            <ListItemIcon><PeopleIcon/></ListItemIcon>
                            <ListItemText>All Profiles (CSV)</ListItemText>
                        </ListItem>
                    </RequireRole>
                    <ListItem disabled button component={NavLink} to="/ensemble/admin/seasons">
                        <ListItemIcon><ListIcon/></ListItemIcon>
                        <ListItemText>Concert Security List</ListItemText>
                    </ListItem>
                    <ListItem disabled button component={NavLink} to="/ensemble/admin/not-yet-implemented">
                        <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                        <ListItemText>Incomplete Profiles</ListItemText>
                    </ListItem>
                    <ListItem disabled button component={NavLink} to="/ensemble/admin/pending-users">
                        <ListItemIcon><FolderSpecialIcon/></ListItemIcon>
                        <ListItemText>Folders to Order</ListItemText>
                    </ListItem>
                </List>
            </RequireRole>
            <RequireRole role={"developer"}>
                <List aria-labelledby="developer-list-subheader"
                      subheader={
                          <ListSubheader id="developer-list-subheader">
                              <Typography variant="h6">Developer</Typography>
                          </ListSubheader>
                      }>
                    <ListItem button component={NavLink} to="/admin/super/attribute">
                        <ListItemIcon><EditAttributesIcon/></ListItemIcon>
                        <ListItemText>Attributes</ListItemText>
                    </ListItem>
                    <RequireRole role={"sysadmin"}>
                        <ListItem button component={NavLink} to="/admin/super/editor">
                            <ListItemIcon><CreateIcon/></ListItemIcon>
                            <ListItemText>Editors</ListItemText>
                        </ListItem>
                        <ListItem button component={NavLink} to="/admin/super/renderer">
                            <ListItemIcon><DescriptionIcon/></ListItemIcon>
                            <ListItemText>Renderers</ListItemText>
                        </ListItem>
                    </RequireRole>
                    <ListItem button component={NavLink} to="/admin/super/subtype">
                        <ListItemIcon><CategoryIcon/></ListItemIcon>
                        <ListItemText>Subtypes</ListItemText>
                    </ListItem>
                </List>
            </RequireRole>


            <RequireRole role="orgadmin">
                <List aria-labelledby="admin-list-subheader"
                      subheader={
                          <ListSubheader id="admin-list-subheader">
                              <Typography variant="h6">Initial Setup</Typography>
                          </ListSubheader>
                      }>
                    <ListItem button component={NavLink} to="/ensemble/admin/org">
                        <ListItemIcon><BusinessIcon/></ListItemIcon>
                        <ListItemText>Ensemble Setup</ListItemText>
                    </ListItem>
                    <ListItem button component={NavLink} to="/ensemble/admin/section">
                        <ListItemIcon><SupervisorAccountIcon/></ListItemIcon>
                        <ListItemText>Sections</ListItemText>
                    </ListItem>
                    <ListItem button component={NavLink} to="/ensemble/admin/instrument">
                        <ListItemIcon><MusicNoteIcon/></ListItemIcon>
                        <ListItemText>Instruments</ListItemText>
                    </ListItem>
                </List>

                <List aria-labelledby="admin-list-subheader"
                      subheader={
                          <ListSubheader id="admin-list-subheader">
                              <Typography variant="h6">Annual Setup</Typography>
                          </ListSubheader>
                      }>
                    <ListItem button component={NavLink} to="/ensemble/admin/season">
                        <ListItemIcon><ScheduleIcon/></ListItemIcon>
                        <ListItemText>Seasons</ListItemText>
                    </ListItem>
                    <ListItem button component={NavLink} to="/ensemble/admin/event">
                        <ListItemIcon><EventAvailableIcon/></ListItemIcon>
                        <ListItemText>Events</ListItemText>
                    </ListItem>
                </List>
            </RequireRole>
            <RequireRole role={"sysadmin"}>
                <List aria-labelledby="superuser-list-subheader"
                      subheader={
                          <ListSubheader id="superuser-list-subheader">
                              <Typography variant="h6">Superuser Tools</Typography>
                          </ListSubheader>
                      }>
                    <ListItem button component={NavLink} to="/admin/super">
                        <ListItemIcon><HomeIcon/></ListItemIcon>
                        <ListItemText>Admin Home</ListItemText>
                    </ListItem>
                    <ListItem button component={NavLink} to="/admin/super/tools">
                        <ListItemIcon><WebAssetIcon/></ListItemIcon>
                        <ListItemText>Superuser Tools</ListItemText>
                    </ListItem>
                    <LogoutLink showFlush={true} showLogout={false}/>
                    <DebugLink/>
                </List>
            </RequireRole>

            <RequireModule module={"swim"}>
                <RequireRole role={"sysadmin"}>
                    <List aria-labelledby="swim-list-subheader"
                          subheader={
                              <ListSubheader id="swim-list-subheader">
                                  <Typography variant="h6">Swim Menu</Typography>
                              </ListSubheader>
                          }>
                        <ListItem button component={Link} to="/swim">
                            <ListItemIcon><HomeIcon/></ListItemIcon>
                            <ListItemText>Home</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to="/swim/schedule-planner">
                            <ListItemIcon><ScheduleIcon/></ListItemIcon>
                            <ListItemText>Schedule Planner</ListItemText>
                        </ListItem>
                    </List>
                </RequireRole>
            </RequireModule>
            <Divider/>

            <List>
                <ListItem button component={NavLink} to="/admin/help">
                    <ListItemIcon><HelpIcon/></ListItemIcon>
                    <ListItemText>Docs and Help</ListItemText>
                </ListItem>
                <LogoutLink/>
            </List>
        </div>
    );

}

export default NavDrawer
