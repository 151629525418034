import React from 'react';
import {ThemeProvider} from "@material-ui/core"
import {createMuiTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow
} from "@material-ui/core/colors";
import {getFirstAttributeValue} from "../../../util/remoteentityutils";
import {useApplicationEnvironment} from "../../../util/hooks";

export const warholThemes = {
  redTheme: {
    name: "redTheme",
    description: "Red",
    menubar: red[500],
    primary: red,
    secondary: blue
  },
  pinkTheme: {
    name: "pinkTheme",
    description: "Pink",
    menubar: pink[500],
    primary: pink,
    secondary: blue
  },
  purpleTheme: {
    name: "purpleTheme",
    description: "Purple",
    menubar: purple[500],
    primary: purple,
    secondary: cyan
  },
  deepPurpleTheme: {
    name: "deepPurpleTheme",
    description: "Deep Purple",
    menubar: deepPurple[500],
    primary: deepPurple,
    secondary: teal
  },
  indigoTheme: {
    name: "indigoTheme",
    description: "Indigo",
    menubar: indigo[500],
    primary: indigo,
    secondary: green
  },
  blueTheme: {
    name: "blueTheme",
    description: "Blue",
    menubar: blue[800],
    primary: blue,
    secondary: red
  },
  lightBlueTheme: {
    name: "lightBlueTheme",
    description: "Light Blue",
    menubar: lightBlue[500],
    primary: lightBlue,
    secondary: lime
  },
  cyanTheme: {
    name: "cyanTheme",
    description: "Cyan",
    menubar: cyan[500],
    primary: cyan,
    secondary: yellow
  },
  tealTheme: {
    name: "tealTheme",
    description: "Teal",
    menubar: teal[800],
    primary: teal,
    secondary: amber
  },
  greenTheme: {
    name: "greenTheme",
    description: "Green",
    menubar: lightGreen[600],
    primary: lightGreen,
    secondary: amber
  },
  lightGreenTheme: {
    name: "lightGreenTheme",
    description: "Light Green",
    menubar: lightGreen[600],
    primary: lightGreen,
    secondary: deepOrange
  },
  limeTheme: {
    name: "limeTheme",
    description: "Lime",
    menubar: lime[500],
    primary: lime,
    secondary: pink
  },
  yellowTheme: {
    name: "yellowTheme",
    description: "Yellow",
    menubar: yellow[500],
    primary: yellow,
    secondary: red
  },
  amberTheme: {
    name: "amberTheme",
    description: "Amber",
    menubar: amber[500],
    primary: amber,
    secondary: blue
  },
  orangeTheme: {
    name: "orangeTheme",
    description: "Orange",
    menubar: orange[500],
    primary: orange,
    secondary: blue
  },
  deepOrangeTheme: {
    name: "deepOrangeTheme",
    description: "Deep Orange",
    menubar: deepOrange[500],
    primary: deepOrange,
    secondary: lime
  },
  defaultTheme: {
    name: "defaultTheme",
    description: "Default"
  }
}

export function useSelectedTheme(organization) {
  return getSelectedTheme(organization)
}

function getSelectedTheme(organization) {
  let wt = null;
  if (!organization) {
    wt = warholThemes["defaultTheme"]
    wt.note = "No organization set. Using default theme."
  }
  const selectedValue = getFirstAttributeValue(organization, "org.theme")
  if (selectedValue) {
    wt = warholThemes[selectedValue]
    if (wt) {
      wt.note = "Selected theme."
    } else {
      wt = warholThemes["defaultTheme"]
      wt.note = "Invalid theme configuration '" + selectedValue + "'. Using default theme."
    }
  } else {
    wt = warholThemes["defaultTheme"]
    wt.note = "No theme selected. Using default."
  }
  return wt;
}

const WarholThemeProvider = ({children}) => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const {organization} = useApplicationEnvironment()
  const warholTheme = getSelectedTheme(organization)
  const theme = createMuiTheme({
    palette: {
      type: prefersDarkMode ? 'dark' : 'light',
      spacing: 8,
      primary: warholTheme.primary,
      secondary: warholTheme.secondary,
    },
    typography: {
      h1: {
        fontSize: "3.5rem",
        fontWeight: 300
      },
      h2: {
        fontSize: "2.75rem"
      },
      h3: {
        fontSize: "2.25rem"
      }
    }
  });

  theme.props = {
    MuiButton: {
      variant: "contained"
    },
  }

  if (warholTheme.menubar) {
    theme.overrides = {
      MuiAppBar: {
        root: {},
        colorPrimary: {
          backgroundColor: warholTheme.menubar
        },
      }
    }

  }
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default WarholThemeProvider;