import React from 'react';
import EnsembleAppInfo from "./common/EnsembleAppInfo";
import EnsureAttribute from "../core/common/EnsureAttribute";
import CommonRouter from "../core/common/CommonRouter";

export default function () {
  return (
    <EnsureAttribute attributes={[
        "org.theme",
        "org.email.from.name",
        "org.email.from.emailaddress",
        "org.email.contact-email",
        "org.label.profile.email",
        "org.label.profile.email.helptext",
        "org.label.ensemble.dues",
        "form.contactinfo.title",
        "season.dues"]}>
      <EnsembleAppInfo>
          <CommonRouter/>
      </EnsembleAppInfo>
    </EnsureAttribute>
  )
}