import {useEffect, useReducer, useRef} from 'react';
import {useGlobalContext} from "../core/common/GlobalContext";
import fetchw from "./fetchw";
import {getFirstAttributeValue} from "./remoteentityutils";
import useSWR from 'swr'

const logger = useSWRNext => {
    return (key, fetcher, config) => {
        // Add logger to the original fetcher.
        const extendedFetcher = (...args) => {
            console.debug('SWR Request:', key)
            return fetcher(...args)
        }

        // Execute the hook with the new fetcher.
        return useSWRNext(key, extendedFetcher, config)
    }
}

export const swrParams = (debug) => {
    if (debug) {
        return {
            revalidateOnFocus: false,
            dedupingInterval: 10 * 1000,
            use: [logger]
        }
    } else {
        return {
            revalidateOnFocus: false,
            dedupingInterval: 10 * 1000,
        }
    }

}
export function useDebug() {
    const [{debug}] = useGlobalContext()
    return debug
}

const APP_INFO_ENDPOINT = `/api/bootstrap`
export const clearAppInfo = mutate => {
    console.info("Nuking application environment")
    mutate(APP_INFO_ENDPOINT)
}
export const useAppInfo = () => {
    // this data is not mutable by the app in any way
    // therefore no need for mutator support
    // also, obviously, don't revalidate!
    const {data, error} = useSWR(APP_INFO_ENDPOINT, fetchw, {
        revalidateOnFocus: false,
        dedupingInterval: 15 * 60 * 1000,
        use: [logger]
    })

    return {
        organization: data?.org,
        appinfo: extractAppInfo(data),
        isLoading: !error && !data,
        isError: error
    }
}
const extractAppInfo = data => {
    if (data) {
        return {
            app_name: data.org?.name + " " + data.app_name,
            app_version: data.app_version,
            app_build: data.app_build,
            short_app_name: data.short_app_name
        }
    } else {
        return null
    }
}

const APPLICATION_ENVIRONMENT_ENDPOINT = `/api/bootstrap2`
export const clearApplicationEnvironment = mutate => {
    console.info("Nuking application environment")
    mutate(APPLICATION_ENVIRONMENT_ENDPOINT)
}
export const useApplicationEnvironment = () => {
    // Fetch the data but do NOT revalidate on focus - this
    // is pretty static data used by the app to bootstrap itself.
    // Revalidating this will nuke the whole app!
    const {data, error} = useSWR(APPLICATION_ENVIRONMENT_ENDPOINT, fetchw, {
        revalidateOnFocus: false,
        dedupingInterval: 15 * 60 * 1000,
        use: [logger]
    })
    return {
        organization: data?.organization,
        orgModules: data?.orgModules,
        roleRegistry: extractRoleMap(data?.roles),
        modules: data?.modules,
        attributeMap: extractAttributeMap(data?.attributes),
        attributeEditors: data?.attributeEditors,
        attributeRenderers: data?.attributeRenderers,

        subtypes: data?.subtypes,
        subtypesForPages: extractSubtypesForPagesMap(data?.subtypes),
        subtypesForComponents: extractSubtypesForComponentsMap(data?.subtypes),
        unclassifiedSubtypes: extractUnclassifiedSubtypeMap(data?.subtypes),

        isLoading: !error && !data,
        isError: error
    }
}

const extractAttributeMap = attributes => {
    if (attributes) {
        const newMap = new Map();
        attributes.map((obj) => {
            // enter them both by name and by id for convenience.
            // attributes are uniquely named for an organization
            // an organization cannot have two attributes with teh same name
            // todo: urgent: update this constraint in the db and clean up tcb and eyb data to force this
            newMap.set(obj.id, obj)
            newMap.set(obj.name, obj)
            return null
        })
        return newMap
    } else {
        return null
    }
}

const extractRoleMap = roles => {
    if (roles) {
        const newMap = new Map();
        roles.map((obj) => {
            newMap.set(obj.rid, obj)
            return null
        })
        return newMap
    } else {
        return null
    }
}

const extractUnclassifiedSubtypeMap = subtypes => {
    if (subtypes) {
        const newMap = new Map();
        subtypes.map((st) => {
            if (st.supportsPages !== true && st.supportsComponents !== true) {
                newMap.set(st.id, st)
            }
            return null
        })
        return newMap
    } else {
        return null
    }
}

const extractSubtypesForPagesMap = subtypes => {
    if (subtypes) {
        const newMap = new Map();
        subtypes.map((st) => {
            if (st.supportsPages) {
                newMap.set(st.id, st)
            }
            return null
        })
        return newMap
    } else {
        return null
    }
}

const extractSubtypesForComponentsMap = subtypes => {
    if (subtypes) {
        const newMap = new Map();
        subtypes.map((st) => {
            if (st.supportsComponents) {
                newMap.set(st.id, st)
            }
            return null
        })
        return newMap
    } else {
        return null
    }
}

export function useAllOrganizations() {
  const [{allOrganizations, allOrganizationsLoading}, update] = useGlobalContext()
  const destroyedRef = useRef(false);
  useEffect(() => {
    return () => {
      destroyedRef.current = true;
    }
  }, [])
  useEffect(() => {
    if (!allOrganizations && !allOrganizationsLoading) {
      update({allOrganizationsLoading: true})
      fetchw("/api/super/org/list")
        .then(
          (res) => {
            const newMap = new Map();
            // console.log("Found organization map", res)
            res.map((org) => {
              newMap.set(org.org, org)
              return null
            })
            update({
              allOrganizations: newMap,
              allOrganizationsLoading: false
            })
          },
          (error) => {
            console.error("error listing organizations:", error);
            update({allOrganizationsLoading: false})
          }
        );
    }
  }, [allOrganizations, allOrganizationsLoading, update])
  return allOrganizations
}

export const clearProfile = mutate => {
    console.info("Clearing profile")
    mutate(`/api/profile`)

}

export function useProfile() {
    const {data, error} = useSWR(`/api/profile`, fetchw, {
        use: [logger],
        dedupingInterval: 15 * 60 * 1000
    })

    return {
        profile: data,
        isLoading: !error && !data,
        isError: error
    }
}

export const useInstrumentMap = () => {
  const cache = useRef({});

  const initialState = {
    instrumentMap_status: 'idle',
    instrumentMap_error: null,
    instrumentMap_data: [],
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return {...initialState, instrumentMap_status: 'fetching'};
      case 'FETCHED':
        return {...initialState, instrumentMap_status: 'fetched', instrumentMap_data: action.payload};
      case 'FETCH_ERROR':
        return {...initialState, instrumentMap_status: 'error', instrumentMap_error: action.payload};
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;


    const fetchData = async () => {
      dispatch({type: 'FETCHING'});
      if (cache.current["result"]) {
        const data = cache.current["result"];
        dispatch({type: 'FETCHED', payload: data});
      } else {
        fetchw(`/api/ensemble/admin/instrument/list`)
          .then(
            (res) => {
              const data = new Map();
              res.map((obj) => {
                data.set(obj.id, obj)
                return null
              })
              cache.current["result"] = data;
              if (cancelRequest) return;
              dispatch({type: 'FETCHED', payload: data});
            },
            (error) => {
              if (cancelRequest) return;
              dispatch({type: 'FETCH_ERROR', payload: error});
            }
          );
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, []);

  return state;
};

export const useSeasons = () => {
  const cache = useRef({});

  const initialState = {
    seasons_status: 'idle',
    seasons_error: null,
    seasons_data: [],
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return {...initialState, seasons_status: 'fetching'};
      case 'FETCHED':
        return {...initialState, seasons_status: 'fetched', seasons_data: action.payload};
      case 'FETCH_ERROR':
        return {...initialState, seasons_status: 'error', seasons_error: action.payload};
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;


    const fetchData = async () => {
      dispatch({type: 'FETCHING'});
      if (cache.current["result"]) {
        const data = cache.current["result"];
        dispatch({type: 'FETCHED', payload: data});
      } else {
        fetchw(`/api/ensemble/admin/season/list`)
          .then(
            (res) => {
              const data = res;
              cache.current["result"] = data;
              if (cancelRequest) return;
              dispatch({type: 'FETCHED', payload: data});
            },
            (error) => {
              if (cancelRequest) return;
              dispatch({type: 'FETCH_ERROR', payload: error});
            }
          );
      }
    };

      fetchData();

      return function cleanup() {
          cancelRequest = true;
      };
  }, []);

    return state;
};

export const useRequiredAttributesPopulated = (entity, attributesToCheck) => {
    const {attributeMap} = useApplicationEnvironment()
    if (!attributeMap) return false
    for (const atr of attributesToCheck) {
        const val = getFirstAttributeValue(entity, atr)
        const attributeDef = attributeMap.get(atr)
        // console.log("Checking req attr in", entity)
        // console.log("Checking req attr val. attributeDef", attributeDef)
        // console.log("Checking req attr val. val", val)
        if (attributeDef && attributeDef.required && !val) return false;
    }
    return true
}

export const useDuesStatus = (pid, seasonid) => {
    const cache = useRef({});


    const initialState = {
        dues_status: 'idle',
        dues_error: null,
        dues_data: [],
    };

  const [state, dispatch] = useReducer((state, action) => {

    switch (action.type) {
      case 'FETCHING':
        return {...initialState, dues_status: 'fetching'};
      case 'FETCHED':
        return {...initialState, dues_status: 'fetched', dues_data: action.payload};
      case 'FETCH_ERROR':
        return {...initialState, dues_status: 'error', dues_error: action.payload};
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;


    if (!pid || !seasonid) return;
    const cachekey = "ds" + pid + "_" + seasonid;


    const fetchData = async () => {

      dispatch({type: 'FETCHING'});
      if (cache.current[cachekey]) {
        const data = cache.current[cachekey];
        dispatch({type: 'FETCHED', payload: data});
      } else {
        fetchw(`/api/ensemble/dues/${seasonid}/status/${pid}`)
          .then(
            (res) => {
              const data = res;
              cache.current[cachekey] = data;
              if (cancelRequest) return;
              dispatch({type: 'FETCHED', payload: data});
            },
            (error) => {
              if (cancelRequest) return;
              dispatch({type: 'FETCH_ERROR', payload: error});
            }
          );
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [pid, seasonid]);

  return state;
};