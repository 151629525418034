import React from 'react';
import Layout from "./common/Layout";
import Typography from "@material-ui/core/Typography";

export default function () {

  return (
    <Layout>
      <Typography variant="h1">Hello world from SwimApp</Typography>
      <p>Nothing to see here. Move along.</p>
    </Layout>
  );
}