import React, {useEffect, useState} from 'react'
import {useParams, withRouter} from "react-router-dom";
import {Checkbox, FormControl} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Layout from "../common/Layout";
import Loading from "../../common/Loading";
import fetchw from "../../../util/fetchw";
import CrudFormBase from "../../common/CrudFormBase";
import {useAppContext} from "../../common/AppContext";
import RequireRole from "../../common/RequireRole";
import AccessDeniedMessage from "../../common/AccessDeniedMessage";

export default withRouter(Form)

function Form(props) {

  const [{notifications}, setNotifications] = useAppContext();

  // load data
  const {uid} = useParams();
  const [loadedForEdit, setLoadedForEdit] = useState(false)
  const [user, setTheUser] = useState({});
  useEffect(() => {
    if (uid && !loadedForEdit) {
      fetchw(`/api/super/user/${uid}`)
        .then((res) => {
          setTheUser(res);
          setLoadedForEdit(true)
        })
    }
  }, [uid, loadedForEdit, setTheUser, setLoadedForEdit])

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const method = loadedForEdit ? "POST" : "PUT"
    fetchw("/api/super/user", {
      method: method,
      body: user
    })
      .then((res) => {
          notifications.push({severity: "success", body: `The user '${user.login}' was saved successfully.`})
          setNotifications(...notifications, {severity: "success", body: "User saved"})
          props.history.push('/admin/super/user');
        }, (error => {
          switch (error.status) {
            case 409:
              notifications.push({severity: "error", body: "Saving user failed - duplicate"})
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: "Saving the user failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }

  if ((uid == null || loadedForEdit)) {
    return (
      <Layout>
        <RequireRole role={"sysadmin"} orElse={<AccessDeniedMessage/>}>
          <CrudFormBase singular="User" plural="Users" uiPath="/admin/super/user"
                        loadedForEdit={loadedForEdit} submitHandler={handleSubmit}>
            <FormControl fullWidth style={{margin: 15}}>
              <TextField disabled={true} id="uid" label="uid" variant="outlined"
                         defaultValue={user?.uid}
              />
            </FormControl>

            <FormControl fullWidth style={{margin: 15}}>
              <TextField id="login" label="Login" variant="outlined"
                         defaultValue={user?.login}
                         onChange={(e) => {
                           setTheUser({...user, login: e.target.value})
                         }}
              />
            </FormControl>

            <FormControl fullWidth style={{margin: 15}}>
              <TextField id="handle" label="Handle" variant="outlined"
                         defaultValue={user?.handle}
                         onChange={(e) => {
                           setTheUser({...user, handle: e.target.value})
                         }}
              />
            </FormControl>

            <FormControl fullWidth style={{margin: 15}}>

              <TextField id="pictureurl" label="Picture URL" variant="outlined"
                         defaultValue={user?.picture}
                         onChange={(e) => {
                           setTheUser({...user, picture: e.target.value})
                         }}
              />
              {user?.picture ?
                <img style={{marginTop: 10}} src={user.picture} width={50}
                     alt={user?.handle ? user.handle : ""}/>
                : ""
              }
            </FormControl>

            <FormControl component="fieldset" style={{margin: 15, display: "block"}}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={user?.enabled ? true : false}
                              onChange={(e) => {
                                setTheUser({...user, enabled: e.target.checked})
                              }}
                              name="enabled"/>
                  } label="Enabled"
                />
              </FormGroup>
            </FormControl>
          </CrudFormBase>
        </RequireRole>
      </Layout>
    );
  } else {
    return <Loading msg="user form"/>
  }
}