import React, {useEffect, useState} from 'react'
import fetchw from "../../../util/fetchw";
import Layout from "../../common/Layout";
import CrudListHeadings from "../../../core/common/CrudListHeadings";
import Loading from "../../../core/common/Loading";
import CrudListCommandBar from "../../../core/common/CrudListCommandBar";
import {Table} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

export default function () {
  const [items, setItems] = useState(null)

  useEffect(
    () => {
      if (items == null) {
        fetchw(`/api/ensemble/admin/event/list?desc=true`)
          .then(
            (res) => {
              setItems(res);
            },
            (error) => {
              console.error("error listing entities:", error);
            }
          );
      }
    }, [items, setItems]
  )

  if (items) {
    return <Layout>
      <CrudListHeadings singular="Event" plural="Events" uiPath="/ensemble/admin/event"/>
      <Table className="table table-striped">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>Commands</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            items.map((item, index) =>
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.eventType}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.startTime}</TableCell>
                <TableCell>
                  <CrudListCommandBar uiPath="/ensemble/admin/event" apiPath="/api/ensemble/admin/event"
                                      canDelete={true}
                                      entityInfo={{
                                        eid: item.id,
                                        label: item.name,
                                        enabled: true
                                      }}
                                      clearState={setItems}/>
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </Layout>
  } else {
    return <Layout><Loading msg="events"/></Layout>
  }
}