import React from 'react';
import Footer from "../../core/common/Footer";
import Alerts from "../../core/common/Alerts";
import {makeStyles} from "@material-ui/core/styles";
import {CommonLayout} from "../../core/common/CommonLayout";
import NavDrawer from "../../core/common/NavDrawer";

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

function DrawerLayout(props) {
  const {
    children
  } = props;
  const classes = useStyles();

  return (
      <CommonLayout appslug="ensemble" toolbarClassName={classes.toolbar} drawerComponent={NavDrawer}>
          <Alerts/>
          {children}
          <Footer/>
      </CommonLayout>
  );
}

export default DrawerLayout
