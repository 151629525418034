import React from 'react';
import {TableCell, TableRow} from "@material-ui/core";
import PrettyDate from "../../../core/common/PrettyDate";
import {EditButton} from "../../../core/common/theme/WarholButtons";
import {Link} from "react-router-dom";

const AttendanceReportRow = (props) => {
    const {event} = props;
    const eventStartDate = Date.parse(event.startTime)
    const isPast = eventStartDate < new Date().getTime()
    return <TableRow hover selected={isPast}>
        <TableCell><PrettyDate
            unixDate={eventStartDate}
            format="ccc MMM dd yyyy 'at' h:mm a"
        /></TableCell>
        <TableCell>{event.name}</TableCell>
        <TableCell><EditButton disabled={isPast} component={Link}
                               to={`/ensemble/attendance/expected/${event.id}`}>Expected</EditButton>
            {' '}<EditButton component={Link} to={`/ensemble/attendance/track/${event.id}`}>Track</EditButton>
            {' '}<EditButton disabled={!isPast} component={Link}
                             to={`/ensemble/attendance/actual/${event.id}`}>Actual</EditButton></TableCell>
    </TableRow>
}

export default AttendanceReportRow