import React from 'react'
import {useApplicationEnvironment} from "../../util/hooks";

function RequireAttributes({attributes, children}) {
  const {attributeMap} = useApplicationEnvironment()
  if (attributeMap && attributes) {
    for (var i = 0; i < attributes.length; i++) {
      const a = attributes[i]
      if (attributeMap.get(a) == null) return "";
    }
    return children;
  }
  return <></>
}

export default RequireAttributes