import React, {useEffect, useState} from 'react';
import Layout from "../../common/Layout";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {useAppContext} from "../../../core/common/AppContext";
import Loading from "../../../core/common/Loading";
import fetchw from "../../../util/fetchw";
import AttendanceReportRow from "./AttendanceReportRow";
import {useDebug} from "../../../util/hooks";

export default function AttendanceReportHome() {
    const debug = useDebug()
    const [{currentSeason}] = useAppContext();
    const [evnts, setEvnts] = useState(null);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (!loaded && currentSeason) {
            const uri = `/api/ensemble/attendance/list-season-events/${currentSeason.id}`
            fetchw(uri)
                .then((res) => {
                        if (res.status === 204) {
                            if (debug) console.debug("No events found");
                        } else {
                            setEvnts(res);
                            setLoaded(true)
                            if (debug) console.debug("Returned events", res)
                        }
                    }, (error) => {
                        console.error("error listing entities:", error);
                        setLoaded(true)
                    }
                );

        }

    }, [currentSeason, evnts, loaded, debug]);

    if (!currentSeason) return <Layout><Typography>No season selected</Typography></Layout>
    if (!loaded) return <Layout><Loading msg={"Attendance Reports & Tracking"}/></Layout>
    if (!evnts) return <Layout><Typography>No events found.</Typography></Layout>

    return (
        <>
            <Layout>
                <Typography variant={"h2"}>Attendance Reports &amp; Tracking</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Choose</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {evnts.map((evnt, index) => {
                            return <AttendanceReportRow key={index} event={evnt}/>
                        })}
                    </TableBody>
                </Table>
            </Layout>
        </>
    )
}

