import React, {useEffect, useState} from 'react';
import {useProfile} from "../../util/hooks";
import fetchw from "../../util/fetchw";
import {getFirstAttributeValue} from "../../util/remoteentityutils";
import Button from "@material-ui/core/Button";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

function RenderTeamDriveLink() {
  const {profile} = useProfile();

  const instrumentId = getFirstAttributeValue(profile, "music.instrument")

  const [instrument, setInstrument] = useState(null);
  const [instrumentLoading, setInstrumentLoading] = useState(false);
  useEffect(() => {
    if (!instrument && !instrumentLoading && instrumentId) {
      setInstrumentLoading(true);
      fetchw(`/api/ensemble/admin/instrument/${instrumentId}`).then((res) => {
        setInstrument(res)
      })
    }
  }, [instrument, instrumentLoading, instrumentId, setInstrument, setInstrumentLoading])

  const [section, setSection] = useState(null);
  const [sectionLoading, setSectionLoading] = useState(false)
  useEffect(() => {
    if (instrument && !section && !sectionLoading) {
      setSectionLoading(true)
      fetchw(`/api/ensemble/admin/section/${instrument.sectionId}`)
        .then((res) => {
          setSection(res);
        })
    }
  }, [section, sectionLoading, setSection, setSectionLoading, instrument])


  if (section) {
    const teamDriveUrl = getFirstAttributeValue(section, "section.teamdriveurl")
    if (teamDriveUrl) {
      return <>
        <Button
          fullWidth
          endIcon={<OpenInNewIcon/>}
          onClick={(e) => {
            window.open(teamDriveUrl)
          }}>{section.name} Team Drive</Button>
      </>
    } else {
      return null
    }
  } else {
    return null
  }
}

export default RenderTeamDriveLink
