import React, {useEffect, useState} from 'react'
import {useParams, withRouter} from "react-router-dom";
import {FormControl} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Layout from "../common/Layout";
import Loading from "../../common/Loading";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import CrudFormBase from "../../common/CrudFormBase";
import {useAppContext} from "../../common/AppContext";
import CrudFormAttributeSubtypeFields from "../../common/CrudFormAttributeSubtypeFields";
import {prepareEntity} from "../../../util/remoteentityutils";
import {useApplicationEnvironment} from "../../../util/hooks";

export default withRouter(Form)

function Form(props) {

  const [{notifications}, setNotifications] = useAppContext();

  // load data
  const {eid} = useParams();
  const [loadedForEdit, setLoadedForEdit] = useState(false)
  const {organization, subtypesForPages} = useApplicationEnvironment()
  const [entity, setEntity] = useState({});
  const [selectedSubtype, setSelectedSubtype] = useState(null);
  useEffect(() => {
    if (eid && !loadedForEdit && subtypesForPages) {
      fetchw(`/api/super/page/${eid}`)
        .then((res) => {
          setEntity(res);
          setLoadedForEdit(true)
          setSelectedSubtype(subtypesForPages.get(res.subtype))
        })
    }
  }, [eid, loadedForEdit, setEntity, setLoadedForEdit, subtypesForPages, setSelectedSubtype])
  useEffect(() => {
    if (entity != null && entity.subtype != null && selectedSubtype?.id !== entity?.subtype) {
      setSelectedSubtype(subtypesForPages.get(entity.subtype))
    }
  }, [entity, selectedSubtype, setSelectedSubtype, subtypesForPages])

  function updateEntity(newEntity) {
    setEntity(newEntity);
  }

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!entity.org) {
      entity.org = organization.id;
    }
    fetchwmp('/api/super/page', {
      method: loadedForEdit ? "POST" : "PUT",
      body: prepareEntity(entity)
    })
      .then((res) => {
          notifications.push({
            severity: "success",
            body: `The '${selectedSubtype.name}' page '${entity.name}' was saved successfully.`
          })
        setNotifications(notifications)
        props.history.push('/admin/super/page');
        }, (error => {
          console.error("error adding entity", error)
          switch (error.status) {
            case 400:
              notifications.push({
                severity: "error",
                body: `Saving the ${selectedSubtype.name} page failed unexpectedly. Please contact the administrator.`
              })
              setNotifications(notifications)
              break;
            case 409:
              notifications.push({
                severity: "error",
                body: `Saving the ${selectedSubtype.name} page failed - duplicate.`
              })
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: `Saving the ${selectedSubtype.name} page failed unexpectedly. Please contact the administrator.`
              })
              setNotifications(notifications)
          }
        })
      )

  }

  if (eid == null || (loadedForEdit && selectedSubtype != null)) {
    return (
      <Layout>
        <CrudFormBase singular="Page" plural="Pages" uiPath="/admin/super/page"
                      loadedForEdit={loadedForEdit} submitHandler={handleSubmit}>
          <FormControl fullWidth style={{margin: 15}}>
            <TextField disabled={true} id="id" label="ID" variant="outlined" defaultValue={entity?.id}/>
          </FormControl>
          <FormControl fullWidth required style={{margin: 15}}>
            <TextField id="name" label="Name" variant="outlined" defaultValue={entity?.name}
                       onChange={(e) => {
                         setEntity({...entity, name: e.target.value})
                       }}
            />
          </FormControl>
          <FormControl fullWidth style={{margin: 15}}>
            <TextField required id="url" label="URL" variant="outlined"
                       helperText={"URL for this page, starting with / (e.g. /my/cool/page)"}
                       defaultValue={entity?.url ? entity.url : ''}
                       onChange={(e) => {
                         setEntity({...entity, url: e.target.value})
                       }}
            />
          </FormControl>
          <CrudFormAttributeSubtypeFields entity={entity} updateEntity={updateEntity} isPage={true}/>
        </CrudFormBase>
      </Layout>
    );
  } else {
    return <Loading msg="page form"/>
  }
}