import React, {useEffect, useState} from 'react'
import Typography from "@material-ui/core/Typography";
import ParentContactInfo from "./ParentContactInfo";
import EmergencyInfo from "./EmergencyInfo";
import YourInstrumentInfo from "./YourInstrumentInfo";
import Dues from "./Dues";
import ContactInfo from "./ContactInfo";
import {useAppContext} from "../../core/common/AppContext";
import fetchw from "../../util/fetchw";
import Button from "@material-ui/core/Button";
import RegistrationFinalSteps from "./RegistrationFinalSteps";
import {RenderCurrentSeasonName} from "../admin/season/SeasonUtils";
import Loading from "../../core/common/Loading";
import {useApplicationEnvironment, useDebug} from "../../util/hooks";

export default function () {
    const debug = useDebug()
    const {organization} = useApplicationEnvironment()
    const [{currentSeason}] = useAppContext()

    const [seasonRegistration, setSeasonRegistration] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loading && !loaded && currentSeason) {
            setLoading(true)
            fetchw(`/api/ensemble/season/register/${currentSeason.id}`)
          .then((res) => {
            setLoaded(true)
            setLoading(false)
            if (debug) console.log("seasonRegistration", res)
            if (res.status === 204) {
              if (debug) console.log("Not registered yet.")
            } else {
              setSeasonRegistration(res);
            }
          })
    }
  }, [debug, loaded, loading, currentSeason])

  function startRegistration() {
    fetchw(`/api/ensemble/season/register/${currentSeason.id}`, {method: "PUT"})
        .then((res) => {
          if (debug) console.log("RES", res)
          setSeasonRegistration(res);
        }, (error) => {
          console.log("ERROR GETTING SEASON REGISTRATION", error)
        })
  }

  if (!loaded) return <Loading msg={"Loading registration wizard"}/>

  if (!seasonRegistration) {
    return <>
      <Typography variant="h3"><RenderCurrentSeasonName/> Season Registration</Typography>
      <p>You are not yet registered. Press 'Start' to begin, and be sure to complete all of the sections.</p>
      <Button fullWidth color={"primary"} onClick={startRegistration}>Start</Button>
    </>
  } else {
    return <section>
      <Typography variant="h3"><RenderCurrentSeasonName/> Season Registration</Typography>
      <p>To register for {organization.name} for the season, please fill in the information in each of the sections
        below.
        If your details change throughout the season, please update it here.</p>
      <p>If you have any questions, please contact the musical director.</p>
      <ContactInfo/>
      <ParentContactInfo/>
      <EmergencyInfo/>
      <YourInstrumentInfo/>
      <Dues/>
      <RegistrationFinalSteps open={!seasonRegistration.complete}/>
    </section>
  }
}