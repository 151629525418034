import React from 'react';

import {Route, Switch} from 'react-router-dom';

import Home from "../../ensemble/Home";
import EnsembleProfile from "../../ensemble/EnsembleProfile";

import {default as SeasonList} from "../../ensemble/admin/season/List";
import {default as SeasonForm} from "../../ensemble/admin/season/Form";

import {default as SectionList} from "../../ensemble/admin/section/List";
import {default as SectionForm} from "../../ensemble/admin/section/Form";

import {default as InstrumentList} from "../../ensemble/admin/instrument/List";
import {default as InstrumentForm} from "../../ensemble/admin/instrument/Form";

import {default as EventList} from "../../ensemble/admin/event/List";
import {default as EventForm} from "../../ensemble/admin/event/Form";
import PendingProfiles from "../../ensemble/admin/profile/PendingProfiles";
import OrganizationSettings from "../../ensemble/admin/org/OrganizationSettings";
import RegistrationPage from "../../ensemble/RegistrationPage";
import DuesPage from "../../ensemble/DuesPage";
import HelpHome from "../../ensemble/help/HelpHome";
import SectionPage from "../../ensemble/SectionPage";
import ContactInfoPage from "../../ensemble/ContactInfoPage";
import Roster from "../../ensemble/reports/Roster";
import EnabledUsers, {DisabledUsers, MemberOptions, PrivilegedUsers} from "../../ensemble/reports/AllUsers";
import AdministerDuesPayments from "../../ensemble/admin/dues/AdministerDuesPayments";
import AttendanceCommitPage from "../../ensemble/AttendanceCommitPage";
import AdministerRegistrationPage from "../../ensemble/admin/registration/AdministerRegistrationPage";
import AttendanceReportHome from "../../ensemble/reports/attendance/AttendanceReportHome";
import ExpectedAttendanceReport from "../../ensemble/reports/attendance/ExpectedAttendanceReport";
import TrackAttendance from "../../ensemble/reports/attendance/TrackAttendance";
import ActualAttendanceReport from "../../ensemble/reports/attendance/ActualAttendanceReport";
import CommunicationCentre from "../../ensemble/CommunicationCentre";
import Welcome from "./Welcome";
import Login from "../Login";
import AdminHome from "../admin/AdminHome";
import OrgList from "../admin/org/OrgList";
import OrgForm from "../admin/org/OrgForm";
import ModuleConfig from "../admin/org/ModuleConfig";
import UserList from "../admin/user/UserList";
import UserForm from "../admin/user/UserForm";
import ProfileList from "../admin/profile/ProfileList";
import ProfileForm from "../admin/profile/ProfileForm";
import AttrList from "../admin/attribute/AttrList";
import AttrForm from "../admin/attribute/AttrForm";
import SubtypeList from "../admin/subtype/SubtypeList";
import SubtypeForm from "../admin/subtype/SubtypeForm";
import EditorList from "../admin/editor/List";
import EditorForm from "../admin/editor/Form";
import RendererList from "../admin/renderer/List";
import RendererForm from "../admin/renderer/Form";
import ComponentList from "../admin/component/ComponentList";
import ComponentForm from "../admin/component/ComponentForm";
import PageList from "../admin/page/PageList";
import PageForm from "../admin/page/PageForm";
import ConvenienceTools from "../admin/tools/ConvenienceTools";
import FourOhFour from "../FourOhFour";
import SwimProfile from "../../swim/SwimProfile";
import SwimHome from "../../swim/SwimHome";
import SchedulePlanner from "../../swim/SchedulePlanner";
import AllUsersCSV from "../../ensemble/reports/AllUsersCSV";
import AttendanceGrid from "../../ensemble/reports/attendance/AttendanceGrid";

export default function () {
        return (
            <Switch>
                    {/*CORE*/}
                    <Route exact path='/' component={Welcome}/>
                    <Route exact path='/welcome' component={Welcome}/>
                    <Route exact path='/login' component={Login}/>

                    <Route path='/admin/help' component={HelpHome}/>


                    <Route exact path='/admin/super' component={AdminHome}/>

                    <Route exact path='/admin/super/org' component={OrgList}/>
                    <Route exact path='/admin/super/org/add' component={OrgForm}/>
                    <Route exact path='/admin/super/org/edit/:org' component={OrgForm}/>
                    <Route exact path='/admin/super/org/view/:org' component={OrgForm}/>
            <Route exact path='/admin/super/org/configure/:org/:mid' component={ModuleConfig}/>

            <Route exact path='/admin/super/user' component={UserList}/>
            <Route exact path='/admin/super/user/add' component={UserForm}/>
            <Route exact path='/admin/super/user/edit/:uid' component={UserForm}/>
            <Route exact path='/admin/super/user/view/:uid' component={UserForm}/>

            <Route exact path='/admin/super/profile' component={ProfileList}/>
            <Route exact path='/admin/super/profile/add' component={ProfileForm}/>
            <Route exact path='/admin/super/profile/edit/:pid' component={ProfileForm}/>
            <Route exact path='/admin/super/profile/view/:pid' component={ProfileForm}/>

            <Route exact path='/admin/super/attribute' component={AttrList}/>
            <Route exact path='/admin/super/attribute/add' component={AttrForm}/>
            <Route exact path='/admin/super/attribute/edit/:aid' component={AttrForm}/>
            <Route exact path='/admin/super/attribute/view/:aid' component={AttrForm}/>

            <Route exact path='/admin/super/subtype' component={SubtypeList}/>
            <Route exact path='/admin/super/subtype/add' component={SubtypeForm}/>
            <Route exact path='/admin/super/subtype/edit/:eid' component={SubtypeForm}/>
            <Route exact path='/admin/super/subtype/view/:eid' component={SubtypeForm}/>

            <Route exact path='/admin/super/editor' component={EditorList}/>
            <Route exact path='/admin/super/editor/add' component={EditorForm}/>
            <Route exact path='/admin/super/editor/edit/:eid' component={EditorForm}/>
            <Route exact path='/admin/super/editor/view/:eid' component={EditorForm}/>

            <Route exact path='/admin/super/renderer' component={RendererList}/>
            <Route exact path='/admin/super/renderer/add' component={RendererForm}/>
            <Route exact path='/admin/super/renderer/edit/:eid' component={RendererForm}/>
            <Route exact path='/admin/super/renderer/view/:eid' component={RendererForm}/>

            <Route exact path='/admin/super/component' component={ComponentList}/>
            <Route exact path='/admin/super/component/add' component={ComponentForm}/>
            <Route exact path='/admin/super/component/edit/:eid' component={ComponentForm}/>
            <Route exact path='/admin/super/component/view/:eid' component={ComponentForm}/>

            <Route exact path='/admin/super/page' component={PageList}/>
            <Route exact path='/admin/super/page/add' component={PageForm}/>
            <Route exact path='/admin/super/page/edit/:eid' component={PageForm}/>
            <Route exact path='/admin/super/page/view/:eid' component={PageForm}/>

            <Route exact path='/admin/super/tools' component={ConvenienceTools}/>

            <Route path='/admin/super/*' component={FourOhFour}/>

            {/*ENSEMBLE*/}
            <Route path='/ensemble/help' component={HelpHome}/>
            <Route exact path='/ensemble/admin/pending-users' component={PendingProfiles}/>
            <Route exact path='/ensemble/profile' component={EnsembleProfile}/>
            <Route exact path='/ensemble/dues' component={DuesPage}/>
            <Route exact path='/ensemble/section' component={SectionPage}/>
            <Route exact path='/ensemble/register' component={RegistrationPage}/>
            <Route exact path='/ensemble/contact' component={ContactInfoPage}/>
                <Route exact path='/ensemble/roster' component={Roster}/>
                <Route exact path='/ensemble/reports/all-users' component={AllUsersCSV}/>
                <Route exact path='/ensemble/reports/enabled-profiles' component={EnabledUsers}/>
            <Route exact path='/ensemble/reports/disabled-profiles' component={DisabledUsers}/>
            <Route exact path='/ensemble/reports/privileged-profiles' component={PrivilegedUsers}/>
            <Route exact path='/ensemble/reports/member-options' component={MemberOptions}/>
            <Route exact path='/ensemble' component={Home}/>
            <Route exact path='/ensemble/attendance/commit' component={AttendanceCommitPage}/>
            <Route exact path='/ensemble/attendance/report' component={AttendanceReportHome}/>
            <Route exact path='/ensemble/attendance/season' component={AttendanceGrid}/>
            <Route exact path='/ensemble/attendance/expected/:eid' component={ExpectedAttendanceReport}/>
            <Route exact path='/ensemble/attendance/track/:eid' component={TrackAttendance}/>
            <Route exact path='/ensemble/attendance/actual/:eid' component={ActualAttendanceReport}/>
            <Route exact path='/ensemble/communication-centre' component={CommunicationCentre}/>

            {/*------------------- ADMINISTRATIVE SETTINGS --------------------*/}
            <Route exact path='/ensemble/admin/org' component={OrganizationSettings}/>

            <Route exact path='/ensemble/admin/season' component={SeasonList}/>
            <Route exact path='/ensemble/admin/season/add' component={SeasonForm}/>
            <Route exact path='/ensemble/admin/season/edit/:eid' component={SeasonForm}/>
            <Route exact path='/ensemble/admin/season/view/:eid' component={SeasonForm}/>

                    <Route exact path='/ensemble/admin/section' component={SectionList}/>
                    <Route exact path='/ensemble/admin/section/add' component={SectionForm}/>
                    <Route exact path='/ensemble/admin/section/edit/:eid' component={SectionForm}/>
                    <Route exact path='/ensemble/admin/section/view/:eid' component={SectionForm}/>

                    <Route exact path='/ensemble/admin/instrument' component={InstrumentList}/>
                    <Route exact path='/ensemble/admin/instrument/add' component={InstrumentForm}/>
                    <Route exact path='/ensemble/admin/instrument/edit/:eid' component={InstrumentForm}/>
                    <Route exact path='/ensemble/admin/instrument/view/:eid' component={InstrumentForm}/>

                    <Route exact path='/ensemble/admin/event' component={EventList}/>
                    <Route exact path='/ensemble/admin/event/add' component={EventForm}/>
                    <Route exact path='/ensemble/admin/event/edit/:eid' component={EventForm}/>
                    <Route exact path='/ensemble/admin/event/view/:eid' component={EventForm}/>

                    <Route exact path='/ensemble/admin/registration' component={AdministerRegistrationPage}/>
                    <Route exact path='/ensemble/dues/list' component={AdministerDuesPayments}/>

                    {/*SWIM*/}
                    <Route exact path='/swim/profile' component={SwimProfile}/>
                    <Route exact path='/swim' component={SwimHome}/>
                    <Route exact path='/swim/schedule-planner' component={SchedulePlanner}/>

            </Switch>
    );
}