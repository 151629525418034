import React from 'react';
import Layout from "../../common/Layout";
import {Box, Button, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {useParams} from "react-router-dom";
import {useDebug} from "../../../util/hooks";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import Loading from "../../../core/common/Loading";
import PrettyDate from "../../../core/common/PrettyDate";
import {DangerButton, SuccessButton} from "../../../core/common/theme/WarholButtons";
import Table from "@material-ui/core/Table";
import useSWR from "swr";
import {useAppContext} from "../../../core/common/AppContext";
import CommitmentLegend from "./CommitmentLegend";

export function TrackAttendance() {

    const {eid} = useParams();
    const debug = useDebug()
    const {data, error, mutate} = useSWR(eid ? `/api/ensemble/attendance/expected/${eid}` : null, fetchw, {
        revalidateOnFocus: false,
        dedupingInterval: 15 * 60 * 1000,
    })

    const [{notifications}, updateAppContext] = useAppContext();

    const recordAttendance = async player => {
        // update database
        const pid = player.pid
        const answer = player.didAttend ? "yes" : "no"
        if (debug) console.debug("Tracked attendance '" + answer + "' for ", player)
        fetchwmp(`/api/ensemble/attendance/track/${eid}/${pid}/${answer}`, {
            method: "PUT"
        })
            .then((res) => {
                    // replace the local data object with a new one
                    const newData = {
                        event: data.event,
                        musiciansByInstrument: []
                    }
                    for (const instrumentInfo of data.musiciansByInstrument) {
                        const newII = {
                            players: [],
                            instrument: instrumentInfo.instrument
                        }

                        for (const p2 of instrumentInfo.players) {
                            if (p2.pid === player.pid) {
                                newII.players.push({...player})
                            } else {
                                newII.players.push(p2)
                            }
                        }
                        newData.musiciansByInstrument.push(newII)
                    }

                    // update local data store
                    mutate({data, ...newData}, false)
                }, (error => {
                    console.error("error recording attendance commitment approval", error)
                    switch (error.status) {
                        case 400:
                            notifications.push({
                                severity: "error",
                                body: "Saving attendance has failed unexpectedly. Please contact the administrator."
                            })
                            updateAppContext(notifications)
                            break;
                        default:
                            notifications.push({
                                severity: "error",
                                body: "Saving attendance has failed unexpectedly. Please contact the administrator."
                            })
                            updateAppContext(notifications)
                    }
                })
            )
    }


    if (!data && !error) return <Layout><Loading msg={"Loading expected attendance report"}/></Layout>
    if (!data) return <Layout><Typography>No data found.</Typography></Layout>

    const eventStartDate = Date.parse(data.event.startTime)
    const instrumentInfo = data.musiciansByInstrument;

    return (
        <Layout>
            <Typography variant={"h2"}>Take Attendance</Typography>
            <Typography variant={"h5"}>{data.event.name}</Typography>
            <Typography variant={"h6"} gutterBottom={true}>
                <PrettyDate
                    unixDate={eventStartDate}
                    format="ccc MMM dd yyyy 'at' h:mm a"
                />
            </Typography>
            <CommitmentLegend/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Musician and Commitment</TableCell>
                        <TableCell>Controls</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        instrumentInfo.map((ii, index) => {
                            const instrument = ii.instrument;
                            const ppl = ii.players;
                            if (hasPeople(ppl)) {
                                return <React.Fragment key={index}>
                                    <TableRow>
                                        <TableCell colSpan={2}><Typography variant={"h6"}>{instrument.name}</Typography></TableCell>
                                    </TableRow>
                                    {ppl.map((player, pi) => {
                                        return <TakeAttendanceRow key={pi} player={player}
                                                                  recordAttendance={recordAttendance}/>
                                    })}
                                </React.Fragment>
                            } else {
                                return null
                            }
                        })
                    }
                </TableBody>
            </Table>

        </Layout>
    )
}

const hasPeople = (players) => {
    for (const n in players) {
        return true;
    }
    return false
}

const TakeAttendanceRow = props => {
    const {
        player,
        recordAttendance
    } = props

    let color = "warning.main" // no response
    if (player.yes) color = "success.main"
    if (player.no) color = "error.main"
    if (player.maybe) color = "warning.main"

    const trackYes = p => {
        recordAttendance({...p, attendanceRecorded: true, didAttend: true})
    }
    const trackNo = p => {
        recordAttendance({...p, attendanceRecorded: true, didAttend: false})
    }

    const RecordedButtons = props => {
        const {player} = props
        if (!player) return null;
        if (player.didAttend) {
            return <React.Fragment>
                <SuccessButton disabled size={"small"} onClick={() => trackYes(player)}>Present</SuccessButton>
                <Button size={"small"} onClick={() => trackNo(player)}>Absent</Button>
            </React.Fragment>
        } else {
            return <React.Fragment>
                <Button size={"small"} onClick={() => trackYes(player)}>Present</Button>
                <DangerButton disabled size={"small"} onClick={() => trackNo(player)}>Absent</DangerButton>
            </React.Fragment>
        }
    }

    const NotRecordedButtons = props => {
        const {player} = props
        if (!player) return null;
        return <React.Fragment>
            <Button size={"small"} onClick={() => trackYes(player)}>Present</Button>
            <Button size={"small"} onClick={() => trackNo(player)}>Absent</Button>
        </React.Fragment>
    }

    return <TableRow color={color}>
        <TableCell>
            <Box color={color}>
                <Typography>
                    {player.uiprofile.phandle}
                </Typography>
            </Box>
        </TableCell>
        <TableCell>
            {
                player.attendanceRecorded ? <RecordedButtons player={player}/> : <NotRecordedButtons player={player}/>
            }
        </TableCell>
    </TableRow>
}

export default TrackAttendance