import React from 'react'
import Typography from "@material-ui/core/Typography";

export function HelpDash() {
  return <>
    <Typography variant={"body1"}>
      Welcome to the digital office help centre. We are actively developing documentation for this brand new
      platform.
      Your input would be greatly appreciated. Please submit suggestions by email to <a
      href={"mailto:support@kingsway.digital?subject=Digital Office Input"}>support@kingsway.digital</a>.
    </Typography>
  </>
}

export default HelpDash