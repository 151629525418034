import React from 'react';
import {useApplicationEnvironment} from "../../util/hooks";

function RequireModule({module, children}) {
  const {orgModules} = useApplicationEnvironment()

  if (orgModules && orgModules.includes(module)) {
    return <>{children}</>;
  } else {
    return <></>;
  }
}

export default RequireModule;