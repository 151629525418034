import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import ProfileMenu from "./ProfileMenu";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import * as PropTypes from "prop-types";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import {Link} from "react-router-dom";
import HelpIcon from "@material-ui/icons/Help";
import {DevelopmentModeFooterSpacer} from "./DevelopmentSiteSwitcher";
import {useAppInfo, useApplicationEnvironment} from "../../util/hooks";
import Loading, {Error} from "./Loading";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.drawer + 1,
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  gap: {
    flexGrow: 1
  }

}));

export function CommonLayout(props) {
  const {
    window,
    title,
    appslug,
    toolbarClassName,
    drawerComponent,
    children
  } = props

  const DrawerComponent = drawerComponent;
  const classes = useStyles();
  const theme = useTheme();
  const {appinfo, isLoading, isError} = useAppInfo()
  const {organization, isLoading: aeLoading, isError: aeError} = useApplicationEnvironment()

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  if (isLoading || aeLoading) return <Loading msg={"common layout"}/>
  if (isError || aeError) return <Error msg={"common layout"}/>


  return <div className={classes.root}>
    <CssBaseline/>
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon/>
        </IconButton>
        <IconButton>
          {/*Todo: move this to the database */}
          <Avatar variant="rounded" alt={appinfo.app_name} src={`/themes/${organization?.slug}/images/logo.png`}/>
        </IconButton>

        <Hidden smDown>
          <Typography className={classes.gap} variant="h6" noWrap>
            {title ? title : appinfo.app_name}
          </Typography>
        </Hidden>
        <Hidden mdUp>
          <Typography className={classes.gap} variant="h6" noWrap>
            {title ? title : appinfo.short_app_name}
          </Typography>
        </Hidden>
        <IconButton component={Link} to={`/${appslug}/help`}>
          <HelpIcon/>
        </IconButton>
        <ProfileMenu app={appslug} className={classes.link}/>
      </Toolbar>
    </AppBar>
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerComponent/>
          <DevelopmentModeFooterSpacer/>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <DrawerComponent/>
          <DevelopmentModeFooterSpacer/>
        </Drawer>
      </Hidden>
    </nav>
    <main className={classes.content}>
      <div className={toolbarClassName}/>
      {children}
      <DevelopmentModeFooterSpacer/>
    </main>

  </div>;
}

CommonLayout.propTypes = {
  title: PropTypes.any,
  classes: PropTypes.any,
  onClick: PropTypes.func,
  container: PropTypes.func,
  theme: PropTypes.any,
  open: PropTypes.bool,
  drawer: PropTypes.any,
  children: PropTypes.any
};
