import React from 'react';
import Loading, {Error} from "./Loading";
import {withCookies} from "react-cookie";
import Helmet from "react-helmet";
import {useAppInfo} from "../../util/hooks";

export default withCookies(AppInfo)

function AppInfo(props) {
    const {
        children
    } = props;
    const {organization, appinfo, isLoading, isError} = useAppInfo()
    if (isLoading) return <Loading msg={"application information"}/>
    if (isError) {
        console.log("Bootstrap error: ", isError)
        return <Error msg={`application information`}/>
    }
    console.log("Loaded organization from app info", organization)
    return <>
        <Helmet>
            <title>{appinfo.app_name}</title>
        </Helmet>
        {children}
    </>
}