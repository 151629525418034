import React from 'react'
import RequireRole from "../../common/RequireRole";
import Typography from "@material-ui/core/Typography";
import {Table, TableBody} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

export default function () {

  return <RequireRole role={"sysadmin"}>
    <Typography variant={"h2"}>Ensemble Administration and Setup</Typography>
    <Typography variant={"h3"}>Attribute Editors and Renderers</Typography>
    <p>
      Editors and renderers can be created and modified by sysadmins only. This is to ensure that code being deployed
      is verified by sysadmins. All users can read and list them so that UI Screens can be presented.
    </p>

    <Typography variant={"h4"}>Editors</Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Code Name</TableCell>
          <TableCell>Configuration</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Instrument Picker</TableCell>
          <TableCell><code>InstrumentPicker</code></TableCell>
          <TableCell/>
        </TableRow>
        <TableRow>
          <TableCell>Require Folder Editor</TableCell>
          <TableCell><code>RequireFolderEditor</code></TableCell>
          <TableCell/>
        </TableRow>
        <TableRow>
          <TableCell>Theme Selector</TableCell>
          <TableCell><code>ThemeSelector</code></TableCell>
          <TableCell/>
        </TableRow>
      </TableBody>
    </Table>

    <Typography variant={"h4"}>Renderers</Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Code Name</TableCell>
          <TableCell>Configuration</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Instrument Picker</TableCell>
          <TableCell><code>InstrumentNameDisplay</code></TableCell>
          <TableCell/>
        </TableRow>
      </TableBody>
    </Table>

    <Typography variant={"h3"}>General System Configuration Attributes</Typography>
    <p>The following attributes need to be configured to enable emails from the server</p>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Label</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Required</TableCell>
          <TableCell>Multiple</TableCell>
          <TableCell>Renderer</TableCell>
          <TableCell>Editor</TableCell>
          <TableCell>Help Text</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>org.theme</TableCell>
          <TableCell>Theme</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell><code>ThemeSelector</code></TableCell>
          <TableCell>Select a colour theme for the organization. Custom themes can be created. Please contact the
            administrator</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>org.email.from.name</TableCell>
          <TableCell>Email Sender</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Organization Email Sender Name</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>org.email.from.emailaddress</TableCell>
          <TableCell>Email Address</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Email address from which emails from this system will be
            sent. This address must be registered with the system.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>org.email.contact-email</TableCell>
          <TableCell>Contact Email Address</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Email address that can be used to email this organization.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>org.label.profile.email</TableCell>
          <TableCell>Profile Email Address Label</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Enter the label that will be used when the profile email address is displayed. e.g.: Primary
            contact email address</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>org.label.profile.email.helptext</TableCell>
          <TableCell>Profile Email Address Help Text</TableCell>
          <TableCell>text</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Detailed description that is to be used under the profile email address form field. e.g.: Enter the
            primary email address you would like to use for communication with the ensemble through this app. It does
            not
            need to be the email address you used to sign in. Note that if this differs from any of the organization's
            mailing lists, you may have to update those addresses as well.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>org.label.ensemble.dues</TableCell>
          <TableCell>Dues label</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Enter the name that describes whether the organization charges "dues" or "fees" or "membership
            fees", etc.</TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <Typography variant={"h3"}>Ensemble Registration Requirements</Typography>
    <Typography variant={"h4"}>Contact Info Component</Typography>
    <p>This renders the main fields in the Contact Info component, which is used in registration and as a stand-alone
      component on the system. It is rendered if the <code>form.contactinfo.intro</code> attribute is detected.
      These attributes are stored on the user's profile. Users can also edit
      their <code>email</code> and <code>handle</code> profile attributes as well.</p>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Label</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Required</TableCell>
          <TableCell>Multiple</TableCell>
          <TableCell>Renderer</TableCell>
          <TableCell>Editor</TableCell>
          <TableCell>Help Text</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>form.contactinfo.intro</TableCell>
          <TableCell>Contact Info Form Introduction</TableCell>
          <TableCell>text</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Enter the contact information section description. This appears above the form.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>form.contactinfo.title</TableCell>
          <TableCell>Contact Info Form Title</TableCell>
          <TableCell>text</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Enter the title for the contact info form. e.g. Contact Information</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.firstname</TableCell>
          <TableCell>First Name</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.lastname</TableCell>
          <TableCell>Last Name</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.phone</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>Please enter the best phone number to use to reach you.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.address</TableCell>
          <TableCell>Address</TableCell>
          <TableCell>text</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.city</TableCell>
          <TableCell>City</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.province</TableCell>
          <TableCell>State/Province</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.postalcode</TableCell>
          <TableCell>Postal Code</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.country</TableCell>
          <TableCell>Country</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.school</TableCell>
          <TableCell>School</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.grade</TableCell>
          <TableCell>Grade</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.volunteer</TableCell>
          <TableCell>Volunteer</TableCell>
          <TableCell>text</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>Please indicate how you can volunteer to help with the successful operation of the band. A link to
            our volunteer opportunities is available on the home page of this app, as well as on the member page of our
            website.</TableCell>
        </TableRow>

      </TableBody>
    </Table>

    <Typography variant={"h4"}>Parent Info</Typography>
    <p>This section renders the Parent Information section of the ensemble registration component. It is rendered if
      the <code>form.parentinfo.intro</code> attribute is detected. Only Parent 1 information is required.
      These attributes are stored on the user's profile.</p>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Label</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Required</TableCell>
          <TableCell>Multiple</TableCell>
          <TableCell>Renderer</TableCell>
          <TableCell>Editor</TableCell>
          <TableCell>Help Text</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>form.parentinfo.intro</TableCell>
          <TableCell>Parent Info Form Introduction</TableCell>
          <TableCell>text</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Enter the parent information section description. This appears above the form.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.parent.1.firstname</TableCell>
          <TableCell>Parent 1 First Name</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.parent.1.lastname</TableCell>
          <TableCell>Parent 1 Last Name</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.parent.1.email</TableCell>
          <TableCell>Parent 1 Email Address</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.parent.1.phone</TableCell>
          <TableCell>Parent 1 Phone Number</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.parent.2.firstname</TableCell>
          <TableCell>Parent 2 First Name</TableCell>
          <TableCell>string</TableCell>
          <TableCell>no</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.parent.2.lastname</TableCell>
          <TableCell>Parent 2 Last Name</TableCell>
          <TableCell>string</TableCell>
          <TableCell>no</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.parent.2.email</TableCell>
          <TableCell>Parent 2 Email Address</TableCell>
          <TableCell>string</TableCell>
          <TableCell>no</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ci.parent.2.phone</TableCell>
          <TableCell>Parent 2 Phone Number</TableCell>
          <TableCell>string</TableCell>
          <TableCell>no</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>

      </TableBody>
    </Table>

    <Typography variant={"h4"}>Emergency Info</Typography>
    <p>This section renders the Information Information section of the ensemble registration component. It is rendered
      if
      the <code>form.emergency.intro</code> attribute is detected. These attributes are stored on the user's profile.
    </p>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Label</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Required</TableCell>
          <TableCell>Multiple</TableCell>
          <TableCell>Renderer</TableCell>
          <TableCell>Editor</TableCell>
          <TableCell>Help Text</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>form.emergency.intro</TableCell>
          <TableCell>Emergency Info Form Introduction</TableCell>
          <TableCell>text</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Enter the emergency information section description. This appears above the form.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>mi.emergencycontact</TableCell>
          <TableCell>Emergency Contact Name</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>Please enter the name of someone we should contact on your behalf in case of emergency.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>mi.emergencycontactphone</TableCell>
          <TableCell>Emergency Contact Phone</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>Please enter the telephone number we should call in case of emergency</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>mi.importantmedicalnotes</TableCell>
          <TableCell>Important medical notes</TableCell>
          <TableCell>text</TableCell>
          <TableCell>no</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>Please enter any imporant medical notes that would be useful in case of an emergency.</TableCell>
        </TableRow>


      </TableBody>
    </Table>

    <Typography variant={"h4"}>Instrument and Music Info</Typography>
    <p>This section renders the Instrument and Music Information section of the ensemble registration component. It is
      rendered if
      the <code>form.instrument.intro</code> attribute is detected. These attributes are stored on the user's profile.
    </p>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Label</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Required</TableCell>
          <TableCell>Multiple</TableCell>
          <TableCell>Renderer</TableCell>
          <TableCell>Editor</TableCell>
          <TableCell>Help Text</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>form.instrument.intro</TableCell>
          <TableCell>Instrument and Music Form Introduction</TableCell>
          <TableCell>text</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Enter the instrument and music information section description. This appears above the
            form.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>music.stagename</TableCell>
          <TableCell>Stage Name</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>Please enter your name exactly as you'd like it to appear in our concert programs and on your music
            folder. Typically members simply use their first name and last name.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>music.instrument</TableCell>
          <TableCell>Instrument</TableCell>
          <TableCell>integer</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell>Instrument Picker</TableCell>
          <TableCell>Instrument Picker</TableCell>
          <TableCell>lease select the instrument you intend to play in the ensemble the most this season. This is the
            instrument under which your name will be listed in our programs.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>music.requirefolder</TableCell>
          <TableCell>Do you require a folder?</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell>Require Folder Chooser</TableCell>
          <TableCell>Please indicate whether or not you need the band to order you a music folder, using your stage name
            as indicated. We provide members with one music folder at no cost. Replacement folders can be ordered for
            a nominal fee.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>music.requireinstrument</TableCell>
          <TableCell>Instrument Required</TableCell>
          <TableCell>boolean</TableCell>
          <TableCell>no</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>Do you need to borrow an instrument?</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>music.requiresmartmusic</TableCell>
          <TableCell>Interested in Smartmusic</TableCell>
          <TableCell>boolean</TableCell>
          <TableCell>no</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>Please check this box if you are interested in working with SmartMusic this season.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>music.sweatersize</TableCell>
          <TableCell>Sweater Size</TableCell>
          <TableCell>string</TableCell>
          <TableCell>no</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>Please enter your sweater size (adult sizes)</TableCell>
        </TableRow>


      </TableBody>
    </Table>

    <Typography variant={"h4"}>Section Info</Typography>
    <p>This section renders the Section Information section of the ensemble registration component. It is rendered if
      the <code>form.section.intro</code> attribute is detected. These attributes are stored on the user's profile.</p>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Label</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Required</TableCell>
          <TableCell>Multiple</TableCell>
          <TableCell>Renderer</TableCell>
          <TableCell>Editor</TableCell>
          <TableCell>Help Text</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>form.section.intro</TableCell>
          <TableCell>Section Form Introduction</TableCell>
          <TableCell>text</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Enter the section information section description. This appears above the form.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>section.teamdriveurl</TableCell>
          <TableCell>Team Drive URL</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <Typography variant={"h4"}>Dues Info</Typography>
    <p>This section renders the Dues Information section of the ensemble registration component. It is rendered if
      the <code>form.dues.intro</code> attribute is detected. These attributes are stored on the season object.</p>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Label</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Required</TableCell>
          <TableCell>Multiple</TableCell>
          <TableCell>Renderer</TableCell>
          <TableCell>Editor</TableCell>
          <TableCell>Help Text</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>form.dues.intro</TableCell>
          <TableCell>Dues Form Introduction</TableCell>
          <TableCell>text</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>Enter the dues information section description. This appears above the form.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>season.dues</TableCell>
          <TableCell>Dues</TableCell>
          <TableCell>decimal</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>season.external-payment-link</TableCell>
          <TableCell>Payment Link</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>External page hosting dues payment information or the payment option itself. Opens in a new
            window.</TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <Typography variant={"h4"}>Next Steps Info</Typography>
    <p>This section renders the Next Steps section of the ensemble registration component. It is rendered if
      the <code>form.nextsteps.title</code> attribute is detected. These attributes are stored on the organization
      object.</p>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Label</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Required</TableCell>
          <TableCell>Multiple</TableCell>
          <TableCell>Renderer</TableCell>
          <TableCell>Editor</TableCell>
          <TableCell>Help Text</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>form.nextsteps.title</TableCell>
          <TableCell>Next Steps Title</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>The title of the Next Steps section. e.g. Next Steps</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>form.nextsteps.subtitle</TableCell>
          <TableCell>Next Steps Subtitle</TableCell>
          <TableCell>string</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>The subtitle of the Next Steps section. e.g. Thank you for submitting your
            registration!</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>form.nextsteps.body</TableCell>
          <TableCell>Next Steps paragraph</TableCell>
          <TableCell>text</TableCell>
          <TableCell>yes</TableCell>
          <TableCell>no</TableCell>
          <TableCell><code></code></TableCell>
          <TableCell><code></code></TableCell>
          <TableCell>The body of the Next Steps section. e.g. Your information will be reviewed shortly. If additional
            information is required, you will be contacted, otherwise it will be marked complete and you are all set.
            Payments will be manually verified and recorded in this system. When this is complete you will receive an
            email notification. This may take a couple of weeks, as this feature is still under construction. It should
            be done by the end of November 2020 though. Thanks for your patience and enjoy the ensemble!</TableCell>
        </TableRow>
      </TableBody>
    </Table>


    <Typography variant={"h3"}>Subtype Configuration</Typography>
    <p>It is necessary to configure at least two subtypes for each enabled module to ensure that the system can function
      correctly.
      These should be configured on behalf of the customer by the sysadmin.</p>
    <ul>
      <li>Organization Subtype</li>
      <li>Profile Subtype</li>
    </ul>
    <p>To configure these, go to the Admin UI, click on Organizations, Inspect the organization, and click on the
      CONFIGURE button beside each module that is enabled.</p>
    <p>The following fields are required in each subtype:</p>
    <ul>
      <li><em>Ensemble Organization Subtype</em>
        <ul>
          <li><code>org.theme</code></li>
          <li><code>org.email.from.name</code></li>
          <li><code>org.email.from.emailaddress</code></li>
          <li><code>org.label.profile.email</code></li>
          <li><code>org.label.profile.email.helptext</code></li>
          <li><code>org.label.ensemble.dues</code></li>
          <li><code>form.contactinfo.intro</code></li>
          <li><code>form.contactinfo.title</code></li>
          <li><code>form.parentinfo.intro</code> (if used)</li>
          <li><code>form.emergency.intro</code> (if used)</li>
          <li><code>form.instrument.intro</code></li>
          <li><code>form.section.intro</code> (if used)</li>
          <li><code>form.dues.intro</code></li>
        </ul>
      </li>
      <li><em>Ensemble Profile Subtype</em>
        <ul>
          <li>n/a - create one with no attributes</li>
        </ul>
      </li>
      <li><em>Core Organization Subtype</em>
        <ul>
          <li>n/a - do not create and do not assign</li>
        </ul>
      </li>
      <li><em>Core Profile Subtype</em>
        <ul>
          <li>n/a - do not create and do not assign</li>
        </ul>
      </li>
    </ul>
    <p>Create these in the subtype section of the Admin UI. Then, inspect the organization and click on CONFIGURE beside
      each module to select the subtypes.</p>


    <Typography variant={"h2"}>Ensemble Module Configuration</Typography>
    <p>Now that the admin is finished, configure the site for users. Follow these steps:</p>
    <ol>
      <li>Be sure that the above setup tasks are complete</li>
      <li>Check with the administrator to make sure that the database and codebase are correct and ready to go. A
        software update may be required.
      </li>
      <li>Create a season (set dues and payment link)</li>
      <li>Click on the Setup link & fill in all of the sections.<br/>
        <strong>NOTE</strong>: If you see a section that you don't intend to use (like parent information), don't
        include it in the Organization Ensemble subtype.
      </li>
      <li>Log in as a user and try out registration.<br/>
        <strong>NOTE</strong>: If you see an attribute on the form that you don't intend to use, delete the attribute
        from the system. Be careful doing this though, as it will cause all related data to be deleted as well.
      </li>
    </ol>

  </RequireRole>
}