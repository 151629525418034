import React, {useEffect, useState} from 'react'
import Layout from "../common/Layout";
import fetchw from "../../../util/fetchw";
import Loading from "../../common/Loading";
import CrudListHeadings from "../../common/CrudListHeadings";
import CrudListCommandBar from "../../common/CrudListCommandBar";
import {Table} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

export default function () {
  const [items, setItems] = useState(null)

  useEffect(
    () => {
      if (items == null) {
        fetchw(`/api/super/attribute/list`)
          .then(
            (res) => {
              setItems(res);
            },
            (error) => {
              console.error("error listing attributes:", error);
            }
          );
      }
    }, [items, setItems]
  )

  if (items) {
    return <Layout>
      <CrudListHeadings singular="Attribute" plural="Attributes" uiPath="/admin/super/attribute"/>
      <Table className="table table-striped">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Label</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Commands</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            items.map((item) =>
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.label}</TableCell>
                <TableCell>{item.datatype}</TableCell>
                <TableCell>
                  <CrudListCommandBar uiPath="/admin/super/attribute" apiPath="/api/super/attribute" canDelete={true}
                                      entityInfo={{eid: item.id, label: item.name, enabled: true}}
                                      clearState={setItems}/>
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </Layout>
  } else {
    return <Layout><Loading msg="attributes"/></Layout>
  }
}