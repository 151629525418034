import React from 'react'

import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ErrorIcon from '@material-ui/icons/Error';
import {useDebug} from "../../util/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function Loading({msg}) {
  const classes = useStyles();
  const debug = useDebug()
  return (
    <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='150px'
        flexDirection='column'
        width={1}
    >
      <span className={classes.root}>
        <CircularProgress/>
        <Typography variant={"h5"}>Loading {debug ? msg : ""}</Typography>
      </span>
    </Box>
  );
}

export function Error({msg}) {
  const classes = useStyles();
  const debug = useDebug()
  console.error("An error has been detected: " + msg)
  return (
      <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='150px'
          flexDirection='column'
          width={1}
      >
      <span className={classes.root}>
        <ErrorIcon/>
        <Typography variant={"h5"}>We're sorry, an error has occurred. {debug ? msg : ""}</Typography>
      </span>
      </Box>
  );
}