import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RequireAttribute from "../../core/common/RequireAttribute";
import DynamicAttributeRenderer from "../../core/common/crud/DynamicAttributeRenderer";
import {useApplicationEnvironment} from "../../util/hooks";

function RegistrationFinalSteps(props) {
  const {
    open = false
  } = props

  const {organization} = useApplicationEnvironment()

  const [expanded, setExpanded] = useState(open)

  function toggle() {
    setExpanded(!expanded)
  }

  if (!organization) return null;

  return <RequireAttribute attributes={["form.nextsteps.title"]}>
    <Accordion expanded={expanded} onChange={toggle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        <Typography variant={"h4"}>
          <DynamicAttributeRenderer entity={organization} attribute={"form.nextsteps.title"}/>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
      <span>
        <Typography variant={"h6"} gutterBottom>
          <DynamicAttributeRenderer entity={organization} attribute={"form.nextsteps.subtitle"}/>
        </Typography>
        <Typography variant={"body1"} gutterBottom>
           <DynamicAttributeRenderer entity={organization} attribute={"form.nextsteps.body"}/>
        </Typography>
      </span>
      </AccordionDetails>
    </Accordion>
  </RequireAttribute>
}

export default RegistrationFinalSteps
