import React from 'react';
import Layout from "../../common/Layout";
import {Box, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {useParams} from "react-router-dom";
import fetchw from "../../../util/fetchw";
import Loading from "../../../core/common/Loading";
import PrettyDate from "../../../core/common/PrettyDate";
import Table from "@material-ui/core/Table";
import useSWR from "swr";
import CommitmentLegend from "./CommitmentLegend";

export default function ActualAttendanceReport() {

    const {eid} = useParams();
    const {data, error} = useSWR(eid ? `/api/ensemble/attendance/expected/${eid}` : null, fetchw, {
        revalidateOnFocus: false,
        dedupingInterval: 15 * 60 * 1000,
    })

    if (!data && !error) return <Layout><Loading msg={"Loading actual attendance report"}/></Layout>
    if (!data) return <Layout><Typography>No data found.</Typography></Layout>

    const eventStartDate = Date.parse(data.event.startTime)
    const instrumentInfo = data.musiciansByInstrument;

    return (
        <Layout>
            <Typography variant={"h2"}>Actual Attendance</Typography>
            <Typography variant={"h5"}>{data.event.name}</Typography>
            <Typography variant={"h6"} gutterBottom={true}>
                <PrettyDate
                    unixDate={eventStartDate}
                    format="ccc MMM dd yyyy 'at' h:mm a"
                />
            </Typography>
            <CommitmentLegend/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Musician and Commitment</TableCell>
                        <TableCell>Actual Attendance</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        instrumentInfo.map((ii, index) => {
                            const instrument = ii.instrument;
                            const ppl = ii.players;
                            if (hasPeople(ppl)) {
                                return <React.Fragment key={index}>
                                    <TableRow>
                                        <TableCell colSpan={2}><Typography variant={"h6"}>{instrument.name}</Typography></TableCell>
                                    </TableRow>
                                    {ppl.map((player, pi) => {
                                        return <TakeAttendanceRow key={pi} player={player}/>
                                    })}
                                </React.Fragment>
                            } else {
                                return null
                            }
                        })
                    }
                </TableBody>
            </Table>

        </Layout>
    )
}

const hasPeople = (players) => {
    for (const n in players) {
        return true;
    }
    return false
}

const TakeAttendanceRow = props => {
    const {
        player,
    } = props

    let color = "warning.main" // no response
    if (player.yes) color = "success.main"
    if (player.no) color = "error.main"
    if (player.maybe) color = "warning.main"


    return <TableRow color={color}>
        <TableCell>
            <Box color={color}>
                <Typography>
                    {player.uiprofile.phandle}
                </Typography>
            </Box>
        </TableCell>
        <TableCell>
            {
                player.attendanceRecorded ? player.didAttend ?
                    <Box color={"success.main"}><Typography>present</Typography></Box> :
                    <Box color={"error.main"}><Typography>absent</Typography></Box> :
                    <Typography>not recorded</Typography>
            }
        </TableCell>
    </TableRow>
}
