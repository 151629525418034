export function titleCase(str) {
    if (str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    } else {
        return str;
    }
}

export function decimalHours(minutes) {
    if (minutes) {
        return minutes / 60
    } else {
        return minutes
    }
}

export function hoursAndMinutes(minutes) {
    if (minutes) {
        var hours = (minutes / 60);
        var rhours = Math.floor(hours);
        var leftoverMinutes = (hours - rhours) * 60;
        var rminutes = Math.round(leftoverMinutes);
        return "" + rhours + ":" + (rminutes < 10 ? "0" + rminutes : rminutes);
    } else {
        return minutes
    }
}

export function formatDollars(dollars) {
    if (dollars) {
        let val = "$" + dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const decIdx = val.indexOf('.')
        val = decIdx > 0 ? val.substring(0, decIdx + 3) : val
        return decIdx + 2 === val.length ? val + "0" : val
    } else {
        return dollars
    }
}