import React, {useEffect, useState} from 'react'
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import fetchw from "../../util/fetchw";
import {useAppContext} from "../../core/common/AppContext";
import Typography from "@material-ui/core/Typography";
import Loading from "../../core/common/Loading";
import {useDebug} from "../../util/hooks";

function RegistrationStatus() {
  const [{currentSeason}] = useAppContext()
  const debug = useDebug()
  const [seasonRegistration, setSeasonRegistration] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    // console.log("rs currentSeason", currentSeason)
    // console.log("rs debug", debug)
    // console.log("rs seasonRegistration", seasonRegistration)
    // console.log("rs loading", loading)
    // console.log("rs loaded", loaded)
    if (currentSeason && !seasonRegistration && !loaded && !loading) {
      setLoading(true)
      fetchw(`/api/ensemble/season/register/${currentSeason.id}`)
          .then((res) => {
            if (debug) console.log("RES", res)
            if (res.status === 204) {
              if (debug) console.log("Not registered yet.")
            } else {
              setSeasonRegistration(res);
            }
            setLoaded(true)
          })
          .finally(() => {
            setLoading(false)
          })
    }
  }, [currentSeason, seasonRegistration, loaded, loading, debug])

  if (!loaded) return <Button fullWidth component={Link} to={"/ensemble"}>Check Registration Status</Button>;

  if (loading) return <Loading msg={"Registration Status"}/>

  if (seasonRegistration) {
    if (seasonRegistration.complete) {
      return <Typography gutterBottom variant="h5">Registration Complete</Typography>
    } else {
      return <section>
        <Typography gutterBottom variant="h5">Registration</Typography>
        <Button fullWidth component={Link} to={"/ensemble/register"}>Pending - Update</Button>
      </section>
    }
  } else {
    return <section>
      <Button fullWidth component={Link} to={"/ensemble/register"}
              color={"primary"}>Register Here</Button>
    </section>
  }

}

export default RegistrationStatus