import React from 'react'
import {withCookies} from "react-cookie";
import {useGlobalContext} from "./GlobalContext";
import {ACCESS_TOKEN_KEY} from "./constants";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {NavLink} from "react-router-dom";
import {useAppContext} from "./AppContext";
import fetchw from "../../util/fetchw";
import RequireRole from "./RequireRole";
import {clearApplicationEnvironment, clearProfile, useDebug} from "../../util/hooks";
import {useSWRConfig} from "swr";

export default withCookies(LogoutLink);

function LogoutLink(props) {
  const {
    cookies,
    showFlush = false,
    showLogout = true
  } = props

  const {mutate} = useSWRConfig()
  const debug = useDebug()
  const [, update] = useGlobalContext()
  const [{notifications}, setNotifications] = useAppContext();

  function flushCachesAndLogout() {
    flushCaches()
    setTimeout(function () {
      logout()
    }, 3000);
  }

  function flushCaches() {
    fetchw('/api/super/cache/nuke', {method: "POST"}).then(
        (result) => {
          notifications.push({
            severity: "success",
            body: "Caches flushed successfully. You will be logged out in 3 seconds."
          })
          setNotifications(notifications)
        },
        (error) => {
          notifications.push({
            severity: "error",
            body: "Flushing caches has failed: " + error
          })
          setNotifications(notifications)
        }
    );
  }

  function logout() {
    if (debug) console.log("Logging out...")
    cookies.remove("dev_org_slug");
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    update({
      dev_org_slug: null,
    })
    clearProfile(mutate)
    clearApplicationEnvironment(mutate)
    if (debug) console.log("...logout complete.")
    window.location.href = '/'
  }

  return (
      <>
        {showFlush &&
            <RequireRole role={"sysadmin"}>
              <ListItem button component={NavLink} to={"/"} onClick={flushCachesAndLogout}>
                <ListItemIcon><MeetingRoomIcon/></ListItemIcon>
                <ListItemText>Flush Server Caches</ListItemText>
              </ListItem>
            </RequireRole>
        }
        {showLogout &&
            <ListItem button component={NavLink} to={"/"} onClick={logout}>
              <ListItemIcon><MeetingRoomIcon/></ListItemIcon>
              <ListItemText>Log out</ListItemText>
            </ListItem>
        }
      </>
  )
}

