import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import {TableBody, TableCell, TableRow} from "@material-ui/core";
import {SecondaryButton} from "../../../core/common/theme/WarholButtons";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import Loading from "../../../core/common/Loading";
import PrettyDate from "../../../core/common/PrettyDate";
import {useAppContext} from "../../../core/common/AppContext";
import {useDebug, useProfile} from "../../../util/hooks";

export const minHoursForMaybe = 60 // todo: configure this in the organization profile

export default function AttendanceCommit(props) {
    const {profile} = useProfile();
    const debug = useDebug()
    const [events, setEvents] = useState(null)
    /*
    private long eventid;
    private long pid;
    private String eventname;
    private String eventType;
    private LocalDateTime start;
    private LocalDateTime end;
    private boolean yes = false;
    private boolean maybe = false;
    private boolean no = false;
    private boolean didAttend;
     */
    useEffect(
        () => {
            if (events == null) {
                fetchw(`/api/ensemble/attendance/future/${profile.id}`)
                    .then((res) => {
                            setEvents(res);
                            if (debug) console.debug("Returned events", res)
                        }, (error) => {
                            console.error("error listing entities:", error);
                        }
                    );
            }
        }, [debug, events, profile]
    )

    const updateEventsUiCallback = (eid, answer) => {
        for (const e of events) {
            if (e.eventid === eid) {
                if (answer === "yes") {
                    e.yes = true;
                    e.maybe = false;
                    e.no = false;
                } else if (answer === "maybe") {
                    e.yes = false;
                    e.maybe = true;
                    e.no = false;
                } else if (answer === "no") {
                    e.yes = false;
                    e.maybe = false;
                    e.no = true;
                }
                setEvents(events)
            }
        }
    }
    if (!events) return <Loading msg={"upcoming events"}/>


    return (
        <>
            <Typography variant={"h3"}>Rehearsal, Concert & Event Availability</Typography>
            <Typography>Please let us know whether or not
                you will be attending the events for the season.
                Come back here to let us know of any changes.</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Choose</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        events.map((evnt, index) => {
                            const eventStartDate = Date.parse(evnt.start)
                            return <TableRow key={index}>
                                <TableCell>
                                    <PrettyDate
                                        unixDate={eventStartDate}
                                        format="ccc MMM dd yyyy 'at' h:mm a"
                                    />
                                </TableCell>
                                <TableCell>{evnt.eventname}</TableCell>
                                <TableCell>
                                    <EventAttendanceButtons evnt={evnt} minHoursForMaybe={minHoursForMaybe}
                                                            updateEventsUiCallback={updateEventsUiCallback}/>
                                </TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </>
    )
}

export const EventAttendanceButtons = (props) => {
    const {
        evnt,
        minHoursForMaybe,
        updateEventsUiCallback
    } = props

    const debug = useDebug()
    const [{notifications}, setNotifications] = useAppContext();

    const recordYes = (eid) => {
        if (debug) console.debug("Recording yes for event ", eid);
        recordCommitment(eid, "yes")
    }
    const recordMaybe = (eid) => {
        if (debug) console.debug("Recording maybe for event ", eid);
        recordCommitment(eid, "maybe")
    }
    const recordNo = (eid) => {
        if (debug) console.debug("Recording no for event ", eid);
        recordCommitment(eid, "no")
    }

    const recordCommitment = (eid, answer) => {
        fetchwmp(`/api/ensemble/attendance/commit/${eid}/${answer}`, {
            method: "PUT"
        })
            .then((res) => {
                    updateEventsUiCallback(eid, answer)
                    notifications.push({
                        severity: "success",
                        body: `Thank you. Your ${answer} commitment has been recorded.`
                    })
                    setNotifications(notifications)
                }, (error => {
                    console.error("error recording attendance commitment approval", error)
                    switch (error.status) {
                        case 400:
                            notifications.push({
                                severity: "error",
                                body: "Saving your attendance commitment has failed unexpectedly. Please contact the administrator."
                            })
                            setNotifications(notifications)
                            break;
                        default:
                            notifications.push({
                                severity: "error",
                                body: "Saving your attendance commitment has failed unexpectedly. Please contact the administrator."
                            })
                            setNotifications(notifications)
                    }
                })
            )
    }

    if (!evnt) return null;

    const eventStartDate = Date.parse(evnt.start)
    const lastMaybeDate = (eventStartDate) - (minHoursForMaybe * 60 * 60 * 1000)
    return (
        <>
            <SecondaryButton size="small" disabled={evnt.yes}
                             onClick={e => recordYes(evnt.eventid)}>Yes</SecondaryButton>
            {(new Date()) < lastMaybeDate &&
            <SecondaryButton size="small" disabled={evnt.maybe}
                             onClick={e => recordMaybe(evnt.eventid)}>Maybe</SecondaryButton>
            }
            <SecondaryButton size="small" disabled={evnt.no}
                             onClick={e => recordNo(evnt.eventid)}>No</SecondaryButton>

        </>
    )
}