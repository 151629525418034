import React, {useEffect, useState} from 'react'
import {useAppContext} from "../../common/AppContext";
import {useParams, withRouter} from "react-router-dom";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import {prepareEntity} from "../../../util/remoteentityutils";
import Layout from "../common/Layout";
import CrudFormBase from "../../common/CrudFormBase";
import {FormControl} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Loading from "../../common/Loading";
import {clearApplicationEnvironment, useApplicationEnvironment} from "../../../util/hooks";
import {useSWRConfig} from "swr";

function RendererForm(props) {

  const [{notifications}, setNotifications] = useAppContext();
  const {mutate} = useSWRConfig()

  // load data
  const {eid} = useParams();
  const [loadedForEdit, setLoadedForEdit] = useState(false)
  const {organization} = useApplicationEnvironment()
  const [entity, setEntity] = useState({});
  useEffect(() => {
    if (eid && !loadedForEdit) {
      fetchw(`/api/super/attribute-renderer/${eid}`)
        .then((res) => {
          setEntity(res);
          setLoadedForEdit(true)
        })
    }
  }, [eid, loadedForEdit, setEntity, setLoadedForEdit])

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!entity.org) {
      entity.org = organization.id;
    }
    fetchwmp('/api/super/attribute-renderer', {
      method: loadedForEdit ? "POST" : "PUT",
      body: prepareEntity(entity)
    })
      .then((res) => {
          notifications.push({
            severity: "success",
            body: `The attribute renderer '${entity.name}'  was saved successfully.`
          })
        setNotifications(notifications)
        clearApplicationEnvironment(mutate)
        props.history.push('/admin/super/renderer');
        }, (error => {
          console.error("error adding entity", error)
          switch (error.status) {
            case 400:
              notifications.push({
                severity: "error",
                body: `Saving the attribute renderer failed unexpectedly. Please contact the administrator.`
              })
              setNotifications(notifications)
              break;
            case 409:
              notifications.push({
                severity: "error",
                body: `Saving the attribute renderer failed - duplicate.`
              })
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: `Saving the attribute renderer failed unexpectedly. Please contact the administrator.`
              })
              setNotifications(notifications)
          }
        })
      )
  }

  if (eid == null || (loadedForEdit)) {
    return (
      <Layout>
        <CrudFormBase singular="Attribute Renderer" plural="Attribute Renderers" uiPath="/admin/super/renderer"
                      loadedForEdit={loadedForEdit} submitHandler={handleSubmit}>
          <FormControl fullWidth style={{margin: 15}}>
            <TextField disabled={true} id="id" label="ID" variant="outlined" defaultValue={entity?.id}/>
          </FormControl>
          <FormControl fullWidth style={{margin: 15}}>
            <TextField required id="name" label="Name" variant="outlined" defaultValue={entity?.name}
                       onChange={(e) => {
                         setEntity({...entity, name: e.target.value})
                       }}
                       helperText={"Internal name used to identify the item in menus."}
            />
          </FormControl>
          <FormControl fullWidth style={{margin: 15}}>
            <TextField required id="codeName" label="Code Name" variant="outlined" defaultValue={entity?.codeName}
                       onChange={(e) => {
                         setEntity({...entity, codeName: e.target.value})
                       }}
                       helperText={"The name of the code component that will provide this functionality.  Do not include angle brackets. Developer use only."}
            />
          </FormControl>
          <FormControl fullWidth style={{margin: 15}}>
            <TextField id="configuration"
                       label="Configuration"
                       variant="outlined"
                       defaultValue={entity?.configuration}
                       multiline
                       rowsMax={4}
                       onChange={(e) => {
                         setEntity({...entity, configuration: e.target.value})
                       }}
                       helperText={"Detailed configuration values for providing specific functionality for this component."}
            />
          </FormControl>
        </CrudFormBase>
      </Layout>
    );
  } else {
    return <Loading msg="attribute renderer form"/>
  }
}

export default withRouter(RendererForm)