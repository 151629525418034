import React, {useState} from 'react'
import {useDuesStatus} from "../../util/hooks";
import Loading from "../../core/common/Loading";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import {useAppContext} from "../../core/common/AppContext";
import fetchw from "../../util/fetchw";

function RegistrationStatusTiny({pid, seasonid}) {
  const {
    dues_status,
    dues_error,
    dues_data,
  } = useDuesStatus(pid, seasonid);

  if (dues_status === "fetched") {
    return <Show ds={dues_data} pid={pid} seasonid={seasonid}/>
  } else if (dues_status === "error") {
    return <p>FETCH ERROR: {JSON.stringify(dues_error, null, ' ')}</p>
  } else if (dues_status === "fetching") {
    return <Loading msg={"waiting for fetch"}/>
  } else { // idle
    return <Loading msg={"idle waiting for dues status"}/>
  }

}

export function Show({ds, pid, seasonid}) {
  const [registered, setRegistered] = useState(ds.didRegister)

  if (registered) {
    return <span><UnregisterButton pid={pid} seasonid={seasonid} setRegistered={setRegistered}/></span>
  } else {
    return <span><RegisterButton pid={pid} seasonid={seasonid} setRegistered={setRegistered}/></span>
  }
}

function RegisterButton({pid, seasonid, setRegistered}) {
  const [{notifications}, setNotifications] = useAppContext();

  function register() {
    fetchw(`/api/ensemble/season/register/${seasonid}/${pid}`, {
      method: "PUT",
    })
      .then((res) => {
          notifications.push({
            severity: "success",
            body: `The profile '${pid}' has been registered for season indicated.`
          })
          setNotifications(notifications)
          setRegistered(true)
        }, (error => {
          console.error("error registering", error)
          notifications.push({
            severity: "error",
            body: "Registering the profile failed unexpectedly. Please contact the administrator."
          })
          setNotifications(notifications)
        })
      )
  }

  return <Tooltip title={"START Registration Now"}><Button color={"secondary"} onClick={register}
                                                           variant={"text"}>No</Button></Tooltip>
}

function UnregisterButton({pid, seasonid, setRegistered}) {
  const [{notifications}, setNotifications] = useAppContext();

  function unregister() {
    fetchw(`/api/ensemble/season/register/${seasonid}/${pid}`, {
      method: "DELETE",
    })
      .then((res) => {
          notifications.push({
            severity: "success",
            body: `The profile '${pid}' has been un-registered for the season indicated.`
          })
          setNotifications(notifications)
          setRegistered(false)
        }, (error => {
          console.error("error registering", error)
          notifications.push({
            severity: "error",
            body: "Un-registering the profile failed unexpectedly. Please contact the administrator."
          })
          setNotifications(notifications)
        })
      )
  }

  return <Tooltip title={"DELETE Registration Now"}><Button onClick={unregister} variant={"text"}>Yes</Button></Tooltip>
}

export default RegistrationStatusTiny