import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {useAppContext} from "../../core/common/AppContext";
import fetchw, {fetchwmp} from "../../util/fetchw";
import {prepareEntity} from "../../util/remoteentityutils";
import DynamicAttributeEditor from "../../core/common/crud/DynamicAttributeEditor";
import RequireAttribute from "../../core/common/RequireAttribute";
import DynamicAttributeRenderer from "../../core/common/crud/DynamicAttributeRenderer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useApplicationEnvironment, useDebug, useProfile, useRequiredAttributesPopulated} from "../../util/hooks";
import SaveButton from "../../core/common/theme/WarholButtons";
import SectionIncomplete from "./registration/SectionIncomplete";

function EmergencyInfo(props) {
  const {
    open = false
  } = props

  const [{notifications}, updateAppContext] = useAppContext();
  const {profile} = useProfile();
  const debug = useDebug()
  const {organization} = useApplicationEnvironment()

  const [changed, setChanged] = useState(false)

  const [isNew, setIsNew] = useState(false);

  const handleUpdateEntity = (p) => {
    setEntity(p);
    setChanged(true)
  }

  const [entity, setEntity] = useState(null);
  useEffect(() => {
    if (!entity && profile) {
      fetchw(`/api/ensemble/my-emergency-info`)
        .then((res) => {
          if (debug) console.log("Loaded my-emergency-info", res)
          if (res.status === 204) {
            setIsNew(true)
            const e = {
              org: profile.org,
              ownerPid: profile.id
            }
            setEntity(e)
          } else {
            setEntity(res);
            setIsNew(false)
          }
          if (debug) console.log("Loaded", res)
        }, (error) => {
          console.log("Error loading medical info", error)
        })
    }
  }, [debug, profile, entity, setEntity])

  // list all attributes set in this section and the hook will confirm required ones are set
  const sectionComplete = useRequiredAttributesPopulated(entity, ["mi.emergencycontact",
    "mi.emergencycontactphone",
    "mi.importantmedicalnotes"
  ])

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault()
    if (debug) console.log("Submitting my-emergency-info", entity)
    fetchwmp("/api/ensemble/my-emergency-info", {
      method: isNew ? "PUT" : "POST",
      body: prepareEntity(entity)
    })
        .then((res) => {
        if (debug) console.log("Just finished saving medical info", res)
        notifications.push({
          severity: "success",
          body: `Your emergency information has been updated. Please continue to the next section.`
        })
        updateAppContext({notifications: notifications})
        setChanged(false)
        }, (error => {
          switch (error.status) {
            default:
              notifications.push({
                severity: "error",
                body: "There was a problem saving your emergency info. Please try again. If the problem persists, contact the administrator."
              })
              updateAppContext({notifications: notifications})
          }
        })
      )
  }

  const [expanded, setExpanded] = useState(open)

  function toggle() {
    setExpanded(!expanded)
  }

  if (entity) {
    return <RequireAttribute attributes={["form.emergency.intro"]}>
      <Accordion expanded={!sectionComplete || expanded} onChange={toggle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant={"h4"}>Emergency Contact Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {!sectionComplete && <SectionIncomplete/>}
            <Typography variant={"subtitle1"}>
              <DynamicAttributeRenderer entity={organization} attribute={"form.emergency.intro"}/>
            </Typography>
            <form onSubmit={handleSubmit}>
              <fieldset>
                <DynamicAttributeEditor entity={entity} attribute="mi.emergencycontact"
                                        updateEntity={handleUpdateEntity}/>
                <DynamicAttributeEditor entity={entity} attribute="mi.emergencycontactphone"
                                        updateEntity={handleUpdateEntity}/>
                <DynamicAttributeEditor entity={entity} attribute="mi.importantmedicalnotes"
                                        updateEntity={handleUpdateEntity}/>
                <SaveButton disabled={!changed} type="submit">Save and Continue</SaveButton>
              </fieldset>
            </form>
          </div>
        </AccordionDetails>
      </Accordion>
    </RequireAttribute>

  } else {
    return ""
  }
}

export default EmergencyInfo