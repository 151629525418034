import React from 'react'
import {Link, useLocation, withRouter} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {CancelButton, CreateButton, EditButton, SaveButton} from '../../core/common/theme/WarholButtons'

export default withRouter(FormBase)

function FormBase(props) {
  const {
    singular,
    plural,
    uiPath,
    loadedForEdit,
    submitHandler,
    children
  } = props;

  // read only?
  const location = useLocation();
  const readonly = location.pathname.indexOf("/view/") > -1;
  const editLocation = location.pathname.replace("/view/", "/edit/")
  return <>
    <Typography variant="h1" gutterBottom>Administer {plural}</Typography>

    <Typography variant="h2" gutterBottom>{readonly
      ? singular + " Details"
      : loadedForEdit
        ? "Edit " + singular
        : "Add " + singular}
    </Typography>

    <form onSubmit={submitHandler}>
      <fieldset disabled={readonly}>
        {children}
      </fieldset>

      {readonly
        ? <><EditButton component={Link} to={editLocation}>Edit</EditButton>{' '}<CancelButton
          component={Link} to={uiPath}>Back</CancelButton></>
        : <>{loadedForEdit ? <SaveButton type="submit">Save</SaveButton> :
          <CreateButton type="submit">Add</CreateButton>} &nbsp;
          <CancelButton component={Link} to={uiPath}>Cancel</CancelButton></>
      }

    </form>
  </>
}