import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import fetchw from "../../util/fetchw";
import RequireAttribute from "../../core/common/RequireAttribute";
import DynamicAttributeRenderer from "../../core/common/crud/DynamicAttributeRenderer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useApplicationEnvironment, useDebug, useProfile} from "../../util/hooks";
import NumberFormat from 'react-number-format';
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {getFirstAttributeValue} from "../../util/remoteentityutils";
import {titleCase} from "../../util/stringutils";
import {PaymentHistory} from "../admin/dues/AdministerDuesPayments";

function Dues(props) {
  const {
    open = false
  } = props

  const {profile} = useProfile()
  const {organization} = useApplicationEnvironment()
  const debug = useDebug()
  const duesLower = getFirstAttributeValue(organization, "org.label.ensemble.dues")
  const duesTitle = titleCase(duesLower)

  const [entity, setEntity] = useState(null);
  useEffect(() => {
    if (!entity && profile) {
      fetchw(`/api/ensemble/dues/status`)
          .then((res) => {
            setEntity(res);
            if (debug) console.log("Loaded Dues Status", res)
        }, (error) => {
          if (debug) console.log("Is new, or error is ", error)
          const e = {
            org: profile.org,
            ownerPid: profile.id
          }
          setEntity(e)
        })
    }
  }, [debug, profile, entity, setEntity])

  const [expanded, setExpanded] = useState(open)
  function toggle() {
    setExpanded(!expanded)
  }

  if (entity) {
    const externalPaymentLink = getFirstAttributeValue(entity.season, "season.external-payment-link")
    return <RequireAttribute attributes={["form.dues.intro"]}>
      <Accordion expanded={entity.balance > 0 || expanded} onChange={toggle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant={"h4"}>{duesTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography variant={"h5"} gutterBottom>Pay Ensemble {duesTitle}</Typography>
            <Typography variant={"subtitle1"} gutterBottom>
              <DynamicAttributeRenderer entity={organization} attribute={"form.dues.intro"}/>
            </Typography>

            <Typography gutterBottom>This season's {duesLower}: <strong><NumberFormat value={entity.dues}
                                                                                      displayType={'text'}
                                                                                      prefix={'$'}
                                                                                      thousandSeparator={true}
                                                                                      decimalScale={2}/></strong>
            </Typography>
            <Typography gutterBottom>Your current balance outstanding is: <strong>
              <NumberFormat value={entity.balance} displayType={'text'} prefix={'$'} thousandSeparator={true}
                            decimalScale={2}/></strong>
            </Typography>

            {externalPaymentLink &&
            <Button
              color={"primary"}
              endIcon={<OpenInNewIcon/>}
              onClick={(e) => {
                window.open(externalPaymentLink)
              }}>Click Here to Pay Your {duesTitle} Now</Button>
            }

            <PaymentHistory duesSummary={entity}/>
            <Alert severity="info">
              Payment history is updated manually. Please check back again soon to view recent payments.
              If the amount has not appeared within a couple of days, please contact the administrator.
              Your patience is appreciated.
            </Alert>

          </div>
        </AccordionDetails>
      </Accordion>
    </RequireAttribute>

  } else {
    return ""
  }
}




export default Dues