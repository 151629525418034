import RequireRole from "../RequireRole";
import Typography from "@material-ui/core/Typography";
import {AppBar, Paper} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React from "react";
import {
  CancelButton,
  CreateButton,
  DangerButton,
  DeleteButton,
  EditButton,
  PrimaryButton,
  SaveButton,
  SecondaryButton,
  SuccessButton,
  WarningButton
} from "./WarholButtons";
import {useSelectedTheme} from "./WarholThemeProvider";

function ThemeSummary() {
  const warholTheme = useSelectedTheme()

  return <RequireRole role={"sysadmin"}>
    <Typography
      variant={"h2"}>{warholTheme.description} Theme Overview</Typography>
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">
          Sample Menu Bar
        </Typography>
      </Toolbar>
    </AppBar>
    <Box p={1}>
      <Typography variant={"body2"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
        nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
        deserunt mollit anim id est laborum.</Typography>
    </Box>
    <Grid container>
      <Grid item xs={4}>
        <Typography component="div" variant="body1">
          <Box p={1} color="primary.main">primary.main</Box>
          <Box p={1} color="secondary.main">secondary.main</Box>
          <Box p={1} color="error.main">error.main</Box>
          <Box p={1} color="warning.main">warning.main</Box>
          <Box p={1} color="info.main">info.main</Box>
          <Box p={1} color="success.main">success.main</Box>
          <Box p={1} color="text.primary">text.primary</Box>
          <Box p={1} color="text.secondary">text.secondary</Box>
          <Box p={1} color="text.disabled">text.disabled</Box>
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Box p={1}>
              <Button>Default Button</Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={1}>
              <PrimaryButton>Primary Button</PrimaryButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={1}>
              <SecondaryButton>Secondary Button</SecondaryButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={1}>
              <DangerButton>Danger Button</DangerButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={1}>
              <WarningButton>Warning Button</WarningButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={1}>
              <SuccessButton>Success Button</SuccessButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={1}>
              <CreateButton>Create Button</CreateButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={1}>
              <SaveButton>Save Button</SaveButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={1}>
              <EditButton>Edit Button</EditButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={1}>
              <DeleteButton>Delete Button</DeleteButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={1}>
              <CancelButton>Cancel Button</CancelButton>
            </Box>
          </Grid>
          <Grid item xs={12} xxl={12}>
            <Typography variant={"body2"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
              qui officia deserunt mollit anim id est laborum.</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box bgcolor="primary.main" color="primary.contrastText" p={2}>
              primary.main
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box bgcolor="secondary.main" color="secondary.contrastText" p={2}>
              secondary.main
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box bgcolor="error.main" color="error.contrastText" p={2}>
              error.main
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box bgcolor="warning.main" color="warning.contrastText" p={2}>
              warning.main
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box bgcolor="info.main" color="info.contrastText" p={2}>
              info.main
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box bgcolor="success.main" color="success.contrastText" p={2}>
              success.main
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box bgcolor="text.primary" color="background.paper" p={2}>
              text.primary
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box bgcolor="text.secondary" color="background.paper" p={2}>
              text.secondary
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box bgcolor="text.disabled" color="background.paper" p={2}>
              text.disabled
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6}>
        <Typography variant={"h1"}>Sample h1 Heading</Typography>
        <Typography variant={"h2"}>Sample h2 Heading</Typography>
        <Typography variant={"h3"}>Sample h3 Heading</Typography>
        <Typography variant={"h4"}>Sample h4 Heading</Typography>
        <Typography variant={"h5"}>Sample h5 Heading</Typography>
        <Typography variant={"h6"}>Sample h6 Heading</Typography>
      </Grid>
      <Grid item sm={6}>
        <Box p={1}>
          <Typography variant={"body2"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            laboris
            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum
            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id est laborum.</Typography>
        </Box>
        <Paper>
          <Box p={1}>
            <Typography variant={"body2"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum
              dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id est laborum.</Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  </RequireRole>
}

export default ThemeSummary