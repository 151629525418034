import React, {useEffect, useState} from 'react'
import {useLocation, useParams, withRouter} from "react-router-dom";
import {FormControl} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import fetchw, {fetchwmp} from "../../../util/fetchw";
import {useAppContext} from "../../../core/common/AppContext";
import Layout from "../../common/Layout";
import CrudFormBase from "../../../core/common/CrudFormBase";
import Loading from "../../../core/common/Loading";
import {prepareEntity} from "../../../util/remoteentityutils";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

export default withRouter(Form)

function Form(props) {

  const location = useLocation();
  const readonly = location.pathname.indexOf("/view/") > -1;

  const [{notifications}, setNotifications] = useAppContext();

  // load data
  const {eid} = useParams();
  const [loadedForEdit, setLoadedForEdit] = useState(false)
  const [entity, setEntity] = useState(null);
  useEffect(() => {
    if (eid && !loadedForEdit) {
      fetchw(`/api/ensemble/admin/event/${eid}`)
        .then((res) => {
          setEntity(res);
          setLoadedForEdit(true)
        })
    }
  }, [eid, loadedForEdit, setEntity, setLoadedForEdit])

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const method = loadedForEdit ? "POST" : "PUT"
    fetchwmp("/api/ensemble/admin/event", {
      method: method,
      body: prepareEntity(entity)
    })
      .then((res) => {
          notifications.push({severity: "success", body: `The event '${entity.name}' was saved successfully.`})
          setNotifications(notifications)
          props.history.push('/ensemble/admin/event');
        }, (error => {
          console.error("error adding entity", error)
          switch (error.status) {
            case 400:
              notifications.push({
                severity: "error",
                body: "Saving the event failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
              break;
            case 409:
              notifications.push({severity: "error", body: "Saving the event failed - duplicate entry."})
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: "Saving the event failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }

  if ((eid == null || loadedForEdit)) {
    return (
      <Layout>
        <CrudFormBase singular="Event" plural="Events" uiPath="/ensemble/admin/event"
                      loadedForEdit={loadedForEdit} submitHandler={handleSubmit}>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField disabled={true} id="id" label="ID" variant="outlined"
                       defaultValue={entity?.id}
            />
          </FormControl>

          <FormControl fullWidth style={{margin: 15}}>
            <TextField required={true} id={`entity_name`}
                       label="Name"
                       disabled={readonly}
                       variant="outlined"
                       defaultValue={entity?.name ? entity.name : ""}
                       InputLabelProps={{
                         shrink: true,
                       }}
                       onChange={(e) => {
                         setEntity({...entity, name: e.target.value})
                       }}
            />
          </FormControl>

          <Grid container>
            <Grid item xs={7}>
              <FormControl fullWidth style={{margin: 15}} disabled={readonly} required={true}>
                <InputLabel id="eventTypeLabel">Type</InputLabel>
                <Select
                  labelId="eventTypeLabel"
                  id="eventType"
                  value={entity?.eventType ? entity.eventType : ''}
                  onChange={(e) => {
                    setEntity({...entity, eventType: e.target.value})
                  }
                  }
                >
                  <MenuItem value="rehearsal">Rehearsal</MenuItem>
                  <MenuItem value="concert">Concert</MenuItem>
                  <MenuItem value="party">Party</MenuItem>
                </Select>
              </FormControl>


              <FormControl style={{margin: 15}}>
                <TextField id={`${entity?.id}_start`}
                           label="Start"
                           disabled={readonly} required={true}
                           variant="outlined"
                           type="datetime-local"
                           defaultValue={entity?.startTime ? entity.startTime : ""}
                           InputLabelProps={{
                             shrink: true,
                           }}
                           onChange={(e) => {
                             setEntity({...entity, startTime: e.target.value})
                           }}
                />
              </FormControl>


              <FormControl style={{margin: 15}}>
                <TextField id={`${entity?.id}_endDate`}
                           label="End"
                           disabled={readonly} required={true}
                           variant="outlined"
                           type="datetime-local"
                           defaultValue={entity?.endTime ? entity.endTime : ""}
                           InputLabelProps={{
                             shrink: true,
                           }}
                           onChange={(e) => {
                             setEntity({...entity, endTime: e.target.value})
                           }}
                />
              </FormControl>
            </Grid>
          </Grid>


        </CrudFormBase>
      </Layout>
    );
  } else {
    return <Loading msg="event form"/>
  }
}