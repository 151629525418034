import React from 'react'
import {Typography} from "@material-ui/core";


function AccessDeniedMessage({msg}) {
  if (msg) {
    return <Typography>{msg}</Typography>
  } else {
    return <Typography>Access denied</Typography>
  }
}

export default AccessDeniedMessage