import React, {useEffect, useState} from 'react';
import Layout from "./common/Layout";
import Loading from "../core/common/Loading";
import {useApplicationEnvironment, useDebug, useProfile} from "../util/hooks";
import Typography from "@material-ui/core/Typography";
import {CancelButton, PrimaryButton} from "../core/common/theme/WarholButtons";
import {Button, FormControl, Grid} from "@material-ui/core";
import {hasRole} from "../util/securityutils";
import TextField from "@material-ui/core/TextField";
import fetchw, {fetchwmp} from "../util/fetchw";
import {useAppContext} from "../core/common/AppContext";
import TinyMCE from "../core/common/crud/editors/TinyMCE";
import PrettyDate from "../core/common/PrettyDate";

function CommunicationCentre() {
    const [{notifications}, setNotifications] = useAppContext();
    const {organization} = useApplicationEnvironment()
    const {profile} = useProfile()

    const [showSectionLeaderForm, setShowSectionLeaderForm] = useState(false)
    const [showSectionForm, setShowSectionForm] = useState(false)
    const [showOrgForm, setShowOrgForm] = useState(false)
    const [showMembersForm, setShowMembersForm] = useState(false)
    const [showEmailSummary, setShowEmailSummary] = useState(false)
    const [emails, setEmails] = useState(null)

    const EmailSummary = props => {
        const {
            toggle
        } = props

        useEffect(
            () => {
                if (emails == null) {
                    fetchw(`/api/ensemble/email/list`)
                        .then((res) => {
                            setEmails(res);
                                // console.debug("Returned emails", res)
                            }, (error) => {
                                console.error("error listing emails:", error);
                            }
                        );
                }
            }, [emails]
        )

        if (emails == null) {
            return <Loading msg={"loading email summaries"}/>
        }

        return  <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant={"h4"}>Email Summary</Typography>
            </Grid>

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Sender</th>
                        <th>Recipient</th>
                        <th>Subject</th>
                        <th>Sent</th>
                    </tr>
                </thead>
                {
                    emails.map((email) => {
                        const sentDate = Date.parse(email.sent)

                        return <tbody key={email.id}>
                        <tr>
                            <td>{email.id}</td>
                            <td>{email.sender.name ? `${email.sender.name} <${email.sender.email}>` : "system"}</td>
                            <td>{email.recipientDescription}</td>
                            <td>{email.subject}</td>
                            <td><PrettyDate
                                unixDate={sentDate}
                                format="ccc MMM d yyyy 'at' HH:mm:ss"
                            /></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td colSpan={4}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th><strong>TO</strong></th>
                                        <td>{email.to.map((r) => r.email).join(", ")}</td>
                                    </tr>
                                    <tr>
                                        <th><strong>CC</strong></th>
                                        <td>{email.cc.map((r) => r.email).join(", ")}</td>
                                    </tr>
                                    <tr>
                                        <th><strong>BCC</strong></th>
                                        <td>{email.bcc.map((r) => r.email).join(", ")}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5}>
                                <hr />
                            </td>
                        </tr>
                        </tbody>

                    })
                }

            </table>


            <Grid item xs={8}>
                <CancelButton onClick={e => {
                    toggle(false)
                }}>Close</CancelButton>
            </Grid>
        </Grid>
    }

    const EmailForm = props => {
        const {
            type,
            title,
            toggle
        } = props
        const labelClasses = "MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"

        const [subject, setSubject] = useState(null)
        const [body, setBody] = useState(null)

        const sendEmail = (event) => {
            const email = {}
            email.type = type
            email.subject = subject
            email.body = body

            event.preventDefault();
            const fd = new FormData()
            fd.append("jsonData", new Blob([JSON.stringify(email)], {type: "application/json"}))
            fetchwmp('/api/ensemble/email', {
                method: "PUT",
                body: fd,
            })
                .then((res) => {
                        notifications.push({severity: "success", body: `Your message has been successfully sent.`})
                        setNotifications(notifications)
                        setSubject(null)
                        setBody(null)
                        toggle(false)
                    }, (error => {
                        console.error("Error sending email", error)
                        switch (error.status) {
                            case 400:
                                notifications.push({
                                    severity: "error",
                                    body: "An error has occurred while sending your email. Please try again later."
                                })
                                setNotifications(notifications)
                                break;
                            default:
                                notifications.push({
                                    severity: "error",
                                    body: "An error has occurred while sending your email. Please try again later."
                                })
                                setNotifications(notifications)
                        }
                    })
                )
        }


        return <form onSubmit={sendEmail}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant={"h4"}>{title}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <FormControl fullWidth style={{margin: 15}}>
                        <TextField id="subject"
                                   label="Subject"
                                   required
                                   variant="outlined"
                                   defaultValue={null}
                                   onChange={(e) => {
                                       setSubject(e.target.value)
                                   }}
                        />
                    </FormControl> </Grid>
                <Grid item xs={8}>


                    <FormControl fullWidth style={{margin: 15}}>
                        <label className={labelClasses}>Body</label>
                        <TinyMCE id={`body`}
                                 initialValue={null}
                                 onEditorChange={(value) => {
                                     setBody(value)
                                 }}
                                 init={{
                                     default_link_target: '_blank',
                                     menubar: false,
                                     height: 500
                                 }}
                            /* full screen plugin not compatible with MUI page menubar
                             *  https://www.tiny.cloud/docs/plugins/opensource/fullscreen/
                             */
                                 plugins={"hr link lists code image"}
                                 toolbar={"formatselect | bold italic underline strikethrough superscript subscript | alignleft aligncenter alignright alignjustify alignnone indent outdent blockquote | numlist bullist link image hr | removeformat code |"}
                        />
                    </FormControl>


                    {/*<FormControl fullWidth style={{margin: 15}}>*/}
                    {/*    <TextField id="body"*/}
                    {/*               label="Body"*/}
                    {/*               required*/}
                    {/*               variant="outlined"*/}
                    {/*               multiline={true} rows={4} rowsMax={10}*/}
                    {/*               defaultValue={null}*/}
                    {/*               onChange={(e) => {*/}
                    {/*                   setBody(e.target.value)*/}
                    {/*               }}*/}
                    {/*    />*/}
                    {/*</FormControl>*/}
                </Grid>
                <Grid item xs={8}>
                    <PrimaryButton type="submit">Submit</PrimaryButton>{' '}
                    <CancelButton onClick={e => {
                        setSubject(null)
                        setBody(null)
                        toggle(false)
                    }}>Cancel</CancelButton>
                </Grid>
                <Grid item xs={8}>
                    <strong>{subject}</strong>
                    <Typography>
                        <div dangerouslySetInnerHTML={{__html: body}}/>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    }

    if (organization) {
        return <Layout>
            <Typography variant={"h3"}>Communication Centre</Typography>
            <Typography gutterBottom>Reach out to fellow members. Click on one of the communication buttons below.
                Inappropriate use of this utility is a violation of organization policies and will
                result in consequences. A Record of all emails sent through this tool will be retained to allow
                them to be viewed again by the sender.</Typography>
            <Grid container spacing={3}>
                <Grid item sm={4}>
                    <Button fullWidth disabled={!hasRole(profile, "member")}
                            onClick={e => setShowSectionLeaderForm(true)}>Email your section leader</Button>
                </Grid>
                <Grid item sm={6}>
                    <Typography>Instantly send an email to your section leader.</Typography>
                </Grid>

                <Grid item sm={4}>
                    <Button fullWidth disabled={!hasRole(profile, "sysadmin")}
                            onClick={e => setShowSectionForm(true)}>Email your section members</Button>
                </Grid>
                <Grid item sm={6}>
                    <Typography>Instantly send an email to everyone in the section.</Typography>
                </Grid>

                <Grid item sm={4}>
                    <Button fullWidth disabled={!hasRole(profile, "sysadmin")}
                            onClick={e => setShowMembersForm(true)}>Email all active members</Button>
                </Grid>
                <Grid item sm={6}>
                    <Typography>Send a message right now to all active members, through this app platform. This is not
                        integrated with an external mailing list platform.</Typography>
                </Grid>

                <Grid item sm={4}>
                    <Button fullWidth disabled={!hasRole(profile, "member")}
                            onClick={e => setShowOrgForm(true)}>Submit feedback to {organization.name}</Button>
                </Grid>
                <Grid item sm={6}>
                    <Typography>Send feedback to the organization leadership or administrator directly.</Typography>
                </Grid>

                <Grid item sm={4}>
                    <Button fullWidth disabled={!hasRole(profile, "sysadmin")}
                            onClick={e => setShowEmailSummary(true)}>View Sent Messages</Button>
                </Grid>
                <Grid item sm={6}>
                    <Typography>Show metadata about all messages sent by this organization</Typography>
                </Grid>

            </Grid>
            <hr/>
            {showSectionLeaderForm &&
                <EmailForm toggle={setShowSectionLeaderForm} type={"sectionLeader"} title={"Email Section Leader"}/>}
            {showSectionForm &&
                <EmailForm toggle={setShowSectionForm} type={"section"} title={"Email Section Members"}/>}
            {showOrgForm &&
                <EmailForm toggle={setShowOrgForm} type={"org"} title={`Submit Feedback to ${organization.name}`}/>}
            {showMembersForm &&
                <EmailForm toggle={setShowMembersForm} type={"members"} title={"Email all active members"}/>}
            {showEmailSummary &&
                <EmailSummary toggle={setShowEmailSummary}/>}

        </Layout>
    } else {
        return <Loading msg="communication centre"/>
    }
}


export default CommunicationCentre