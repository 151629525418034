import React, {useEffect, useState} from 'react'
import {useParams, withRouter} from "react-router-dom";
import Layout from "../common/Layout";
import Loading from "../../common/Loading";
import fetchw from "../../../util/fetchw";
import CrudFormBase from "../../common/CrudFormBase";
import {useAppContext} from "../../common/AppContext";
import {useAllOrganizations, useApplicationEnvironment, useDebug,} from "../../../util/hooks";
import {FormControl} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {titleCase} from "../../../util/stringutils";

export default withRouter(Form)

function Form(props) {

  const {org, mid} = useParams();
  const {unclassifiedSubtypes} = useApplicationEnvironment();
  const allOrganizations = useAllOrganizations();

  const [{notifications}, setNotifications] = useAppContext();
  const [entity, setEntity] = useState(null);
  const debug = useDebug()

  // load object
  useEffect(() => {
    if (org && mid) {
      const moduleName = mid;
      fetchw(`/api/super/org/${org}/module-config/${moduleName}`)
          .then((res) => {
            if (debug) console.log("Loaded entity ", res)
            setEntity(res);
          })
    }
  }, [debug, org, mid])

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    fetchw(`/api/super/org/module-config`, {
      method: "POST",
      body: entity
    })
      .then((res) => {
          notifications.push({severity: "success", body: `The module configuration was saved successfully.`})
          setNotifications(notifications)
          props.history.push('/admin/super/org');
        }, (error => {
          console.error("error saving module configuration", error)
          switch (error.status) {
            case 400:
              notifications.push({
                severity: "error",
                body: "Saving the module configuration failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
              break;
            case 409:
              notifications.push({severity: "error", body: "Saving the module configuration failed - duplicate entry."})
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: "Saving the module configuration failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }
  if (org && mid && unclassifiedSubtypes && allOrganizations) {
    const orgName = allOrganizations.get(Number(org)).name;
    const modName = mid;
    return (
        <Layout>
          <CrudFormBase singular={`${titleCase(modName)} Module`} plural={`${orgName}`} uiPath="/admin/super/org"
                        loadedForEdit={true} submitHandler={handleSubmit}>

            <Typography variant="h6" gutterBottom>Select the subtype for the organization</Typography>
            <Typography variant="body1" gutterBottom>The organization subtype defines the attributes
              that will be available to be added to the organization for this module.</Typography>
          <FormControl fullWidth required style={{margin: 15}}>
            <InputLabel id="orgLabel">Organization Subtype</InputLabel>
            <Select
              labelId="orgLabel"
              id="orgSubtype"
              value={entity?.orgSubtype ? entity.orgSubtype : ''}
              onChange={(e) => {
                setEntity({...entity, orgSubtype: e.target.value})
              }}
            >
              {
                Array.from(unclassifiedSubtypes.keys()).map(key => {
                  return <MenuItem key={key} value={key}>{unclassifiedSubtypes.get(key).name}</MenuItem>
                })
              }
            </Select>
          </FormControl>

          <Typography variant="h6" gutterBottom>Select the subtype for profiles</Typography>
          <Typography variant="body1" gutterBottom>The profile subtype defines the attributes
            that will be available to be added to user profiles for this module.</Typography>

          <FormControl fullWidth required style={{margin: 15}}>
            <InputLabel id="profileLabel">Profile Subtype</InputLabel>
            <Select
              labelId="profileLabel"
              id="profileSubtype"
              value={entity?.profileSubtype ? entity.profileSubtype : ''}
              onChange={(e) => {
                setEntity({...entity, profileSubtype: e.target.value})
              }}
            >
              {
                Array.from(unclassifiedSubtypes.keys()).map(key => {
                  return <MenuItem key={key} value={key}>{unclassifiedSubtypes.get(key).name}</MenuItem>
                })
              }
            </Select>
          </FormControl>

        </CrudFormBase>
      </Layout>
    );
  } else {
    return <Loading msg="module configuration"/>
  }
}