import React from 'react'
import Alerts from "../../common/Alerts";
import Footer from "../../common/Footer";
import {makeStyles} from "@material-ui/core/styles";
import {CommonLayout} from "../../common/CommonLayout";
import NavDrawer from "../../common/NavDrawer";


const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

function DrawerLayout(props) {
  const {
    children
  } = props;
  const classes = useStyles();

  return (
      <CommonLayout appslug="admin" title="Administration" toolbarClassName={classes.toolbar}
                    drawerComponent={NavDrawer}>
          <Alerts/>
          {children}
          <Footer/>
      </CommonLayout>
  );
}

export default DrawerLayout
