import React from 'react'
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {useConfirm} from "material-ui-confirm";
import fetchw from "../../util/fetchw";
import {useAppContext} from "./AppContext";
import {DeleteButton, EditButton, WarningButton} from "../../core/common/theme/WarholButtons"

export default function (props) {
  const {
    entityInfo: {
      eid,
      label,
      enabled
    },
    uiPath,
    apiPath,
    canDelete = true,
    clearState
  } = props;

  const [{notifications}, setNotifications] = useAppContext();

  const confirm = useConfirm()

  function handleDelete(event, eid, label) {
    confirm({description: `Are you sure you want to delete ${label} (${eid})? Deleting is forever!`})
      .then(() => {
        fetchw(`${apiPath}/${eid}`, {method: "DELETE"})
          .then((res) => {
            notifications.push({
              severity: "success",
              body: `${label} (${eid}) has been successfully deleted.`
            })
            setNotifications(notifications);
            clearState(null)
          }, (error) => {
            console.error(`Deleting item ${label} (${eid}) has failed.`, error)
            notifications.push({
              severity: "error",
              body: `${label} (${eid}) could not be deleted. Please contact the administrator.`
            })
            setNotifications(notifications);
          })
          .catch(`Deletion of ${label} (${eid}) failed unexpectedly.`)
      })
  }

  function handleDisable() {
    fetchw(`${apiPath}/${eid}/disable`)
      .then((res) => {
        notifications.push({
          severity: "success",
          body: `${label} (${eid}) has been successfully disabled.`
        })
        setNotifications(notifications);
        clearState(null)
      }, (error) => {
        console.error(`Disabling item ${label} (${eid}) has failed.`, error)
        notifications.push({
          severity: "error",
          body: `${label} (${eid}) could not be disabled. Please contact the administrator.`
        })
        setNotifications(notifications);
      })
      .catch(`Disabling of ${label} (${eid}) failed unexpectedly.`)
  }

  function handleEnable() {
    fetchw(`${apiPath}/${eid}/enable`)
      .then((res) => {
        notifications.push({
          severity: "success",
          body: `${label} (${eid}) has been successfully enabled.`
        })
        setNotifications(notifications);
        clearState(null)
      }, (error) => {
        console.error(`Enabling item ${label} (${eid}) has failed.`, error)
        notifications.push({
          severity: "error",
          body: `${label} (${eid}) could not be enabled. Please contact the administrator.`
        })
        setNotifications(notifications);
      })
      .catch(`Enabling of ${label} (${eid}) failed unexpectedly.`)
  }


  return <>
    <span style={{whiteSpace: "nowrap"}}>
    <Button component={Link} to={uiPath + "/view/" + eid}>Inspect</Button>{' '}
      <EditButton component={Link} to={uiPath + "/edit/" + eid}>Edit</EditButton>{' '}
      {canDelete ?
        <DeleteButton onClick={(e) => handleDelete(e, eid, label)}>Delete</DeleteButton>
        : ""
      }
      {!canDelete && enabled ?
        <DeleteButton onClick={(e) => handleDisable(e, eid, label)}>Disable</DeleteButton> : ""
      }
      {!canDelete && !enabled ?
        <WarningButton onClick={(e) => handleEnable(e, eid, label)} color="primary">Enable</WarningButton> : ""
      }
      </span>
  </>
}
