import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {clearProfile, useApplicationEnvironment, useProfile, useRequiredAttributesPopulated} from "../../util/hooks";
import {useAppContext} from "../../core/common/AppContext";
import {fetchwmp} from "../../util/fetchw";
import {getFirstAttributeValue, prepareEntity} from "../../util/remoteentityutils";
import TextField from "@material-ui/core/TextField";
import {FormControl} from "@material-ui/core";
import DynamicAttributeEditor from "../../core/common/crud/DynamicAttributeEditor";
import RequireAttribute from "../../core/common/RequireAttribute";
import DynamicAttributeRenderer from "../../core/common/crud/DynamicAttributeRenderer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveButton from "../../core/common/theme/WarholButtons"
import SectionIncomplete from "./registration/SectionIncomplete";
import {useSWRConfig} from "swr";

function ContactInfo(props) {
    const {
        open = false
    } = props
    const {mutate} = useSWRConfig()

    const {profile} = useProfile();
    const [updatedProfile, setUpdatedProfile] = useState(profile);
    const [{notifications}, updateAppContext] = useAppContext();
    const {organization} = useApplicationEnvironment()

    const [changed, setChanged] = useState(false)
    const [sectionComplete, setSectionComplete] = useState(false)
    // list all attributes set in this section and the hook will confirm required ones are set
    const requiredAttributesPopulated = useRequiredAttributesPopulated(profile, ["ci.firstname",
        "ci.lastname",
        "ci.phone",
        "ci.address",
        "ci.city",
        "ci.province",
        "ci.postalcode",
        "ci.country",
        "ci.school",
        "ci.grade",
        "ci.volunteer"
    ])

    const handleProfileUpdated = (p) => {
        setUpdatedProfile(p);
        setChanged(true)
    }

    useEffect(() => {
        const h = profile.handle
        const e = profile.email
        setSectionComplete(h != null && e != null && requiredAttributesPopulated)
    }, [profile, requiredAttributesPopulated])

    // form submit
    const handleSubmit = (event) => {
        event.preventDefault()
        fetchwmp("/api/profile-self-update", {
            method: "POST",
            body: prepareEntity(updatedProfile)
        })
            .then((res) => {
                    notifications.push({
                        severity: "success",
                        body: `Your contact information has been saved. Please continue to the next section.`
                    })
                updateAppContext({notifications: notifications})
                clearProfile(mutate)
                setChanged(false)
                }, (error => {
                    switch (error.status) {
                        default:
                            notifications.push({
                                severity: "error",
                                body: "There was a problem saving your profile. Please try again. If the problem perists, contact the administrator."
                            })
                            updateAppContext({notifications: notifications})
                    }
                })
            )
    }

    const [expanded, setExpanded] = useState(open)

    function toggle() {
        setExpanded(!expanded)
    }

    const contactInfoTitle = getFirstAttributeValue(organization, "form.contactinfo.title");
    const contactInfoEmailLabel = getFirstAttributeValue(organization, "org.label.profile.email")
    const contactInfoEmailHelp = getFirstAttributeValue(organization, "org.label.profile.email.helptext")
    if (profile) {
        return <RequireAttribute attributes={["form.contactinfo.intro"]}>
            <section>
                <Accordion expanded={!sectionComplete || expanded} onChange={toggle}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant={"h4"}>{contactInfoTitle}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            {!sectionComplete && <SectionIncomplete/>}
                            <Typography variant={"subtitle1"}>
                                <DynamicAttributeRenderer entity={organization} attribute={"form.contactinfo.intro"}/>
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <fieldset>
                                    <FormControl fullWidth style={{margin: 15}}>
                                        <TextField id="handle" label="Profile display name"
                                                   helperText={"Enter a name for your profile. By default this is the name attached to " +
                                                   "your login, but you can change this informal name to anything. It will be used to greet " +
                                                   "you on the site, but will not appear in any official material. Note this is not " +
                                                   "available for pending accounts."}
                                                   variant="outlined" required={true}
                                                   defaultValue={profile.handle}
                                                   onChange={(e) => {
                                                       profile.handle = e.target.value;
                                                       handleProfileUpdated(profile);
                                                   }}
                                        />
                                    </FormControl>

                                    <DynamicAttributeEditor entity={profile} attribute="ci.firstname"
                                                            updateEntity={handleProfileUpdated}/>

                                    <DynamicAttributeEditor entity={profile} attribute="ci.lastname"
                                                            updateEntity={handleProfileUpdated}/>

                                    <FormControl fullWidth style={{margin: 15}}>
                                        <TextField id="email" label={contactInfoEmailLabel}
                                                   helperText={contactInfoEmailHelp}
                                                   variant="outlined" required={true}
                                                   defaultValue={profile.email}
                                                   onChange={(e) => {
                                                       profile.email = e.target.value;
                                                       handleProfileUpdated(profile);
                                                   }}
                                        />
                                    </FormControl>
                                    <DynamicAttributeEditor entity={profile} attribute="ci.phone"
                                                            updateEntity={handleProfileUpdated}/>
                                    <DynamicAttributeEditor entity={profile} attribute="ci.address"
                                                            updateEntity={handleProfileUpdated}/>
                                    <DynamicAttributeEditor entity={profile} attribute="ci.city"
                                                            updateEntity={handleProfileUpdated}/>
                                    <DynamicAttributeEditor entity={profile} attribute="ci.province"
                                                            updateEntity={handleProfileUpdated}/>
                                    <DynamicAttributeEditor entity={profile} attribute="ci.postalcode"
                                                            updateEntity={handleProfileUpdated}/>
                                    <DynamicAttributeEditor entity={profile} attribute="ci.country"
                                                            updateEntity={handleProfileUpdated}/>
                                    <DynamicAttributeEditor entity={profile} attribute="ci.school"
                                                            updateEntity={handleProfileUpdated}/>
                                    <DynamicAttributeEditor entity={profile} attribute="ci.grade"
                                                            updateEntity={handleProfileUpdated}/>
                                    <DynamicAttributeEditor entity={profile} attribute="ci.volunteer"
                                                            updateEntity={handleProfileUpdated}/>
                                    <SaveButton disabled={!changed} type="submit">Save and Continue</SaveButton>
                                </fieldset>
                            </form>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </section>
        </RequireAttribute>

    } else {
        return ""
    }
}

export default ContactInfo