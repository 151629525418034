import React, {useEffect, useState} from 'react'
import fetchw, {fetchwmp} from "../../../util/fetchw";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useAppContext} from "../../../core/common/AppContext";
import Layout from "../../common/Layout";
import Loading from "../../../core/common/Loading";
import {FormControl, Table, TableContainer} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Link} from "react-router-dom";
import RenderSeasonName from "../season/SeasonUtils";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import ProfileTooltip from "../../components/ProfileTooltip";
import TextField from "@material-ui/core/TextField";
import {prepareEntity} from "../../../util/remoteentityutils";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import NumberFormat from 'react-number-format';
import CancelButton from "../../../core/common/theme/WarholButtons";

export default function AdministerDuesPayments() {

  const [{currentSeason}] = useAppContext()
  const [seasonList, setSeasonList] = useState(null)

  const [activeSeason, setActiveSeason] = useState(null);

  useEffect(() => {
    if (currentSeason && !activeSeason) {
      setActiveSeason(currentSeason)
    }
  }, [activeSeason, setActiveSeason, currentSeason])

  useEffect(
    () => {
      if (seasonList == null) {
        fetchw(`/api/ensemble/admin/season/list`)
          .then(
            (res) => {
              setSeasonList(res);
            },
            (error) => {
              console.error("error listing entities:", error);
            }
          );
      }
    }, [seasonList, setSeasonList]
  )


  if (seasonList && activeSeason) {
    return <Layout>
      <Typography variant="h2" gutterBottom>Dues Administration</Typography>
      <Typography variant={"h4"} gutterBottom><RenderSeasonName season={activeSeason}/> Dues Summary</Typography>
      <FormControl fullWidth>
        <InputLabel variant="outlined" id="selectSeasonLabelId">
          Select the season
        </InputLabel>
        <Select
          id={`selectSeason`}
          variant="outlined"
          defaultValue={activeSeason.id}
          value={activeSeason.id}
          label={"Select the season"} // length needs to match the InputLabel's string to ensure that a gap is left
          labelId="selectSeasonLabelId"
          onChange={(e) => {
            seasonList.map((s) => {
              if (s.id === e.target.value) {
                setActiveSeason(s);
              }
              return null;
            })
          }}
        >
          {seasonList.map((s) => {
            return <MenuItem key={s.id} value={s.id}><RenderSeasonName season={s}/></MenuItem>
          })}
        </Select>
        <FormHelperText>Usually dues would be administered for the current season, but to make updates to other seasons,
          select the season here.</FormHelperText>
      </FormControl>
      <Typography variant={"subtitle2"} gutterBottom>To set or change the dues for this season, <Link
        to={`/ensemble/admin/season/edit/${activeSeason.id}`}>click here to update the season
        directly</Link>.</Typography>


      <RegisteredMemberListing season={activeSeason}/>

    </Layout>
  } else {
    return <Layout><Loading msg="dues summary"/></Layout>
  }
}

function RegisteredMemberListing(props) {
  const [season, setSeason] = useState(null);
  const [members, setMembers] = useState(null);

  useEffect(() => {
    if (props.season !== season) {
      setSeason(props.season)
      setMembers(null)
    }
  }, [props.season, season, setSeason])

  useEffect(
    () => {
      if (season && members == null) {
        fetchw(`/api/ensemble/dues/${season.id}/list-registered-members`)
          .then((res) => {
              setMembers(res);
            }, (error) => {
              console.error("error listing entities:", error);
            }
          );
      }
    }, [members, setMembers, season]
  )

  if (members) {
    return <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Member</TableCell>
              <TableCell>Balance Due</TableCell>
              <TableCell>Commands</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              members.map((item, idx) => {
                return <MemberPaymentRow key={idx} holder={item}/>
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  }
  return <Loading msg={"registered members"}/>
}

function MemberPaymentRow({holder}) {

  function recordPayment(amount) {
    holder.duesSummary.balance = holder.duesSummary.balance - amount
    setShowForm(false)
  }

  const [showForm, setShowForm] = useState(false);

  if (!holder) return null;

  return <>
    <TableRow key={holder.profile.pid}>
      <TableCell>
        <ProfileTooltip uiprofile={holder.profile}>
          <Typography variant={"body2"}>{holder.profile.fallbackStageName} ({holder.profile.instrument})</Typography>
        </ProfileTooltip>
      </TableCell>
      <TableCell><NumberFormat value={holder.duesSummary.balance} displayType={'text'} prefix={'$'}
                               thousandSeparator={true}
                               decimalScale={2}/></TableCell>
      <TableCell>
        <Button onClick={(e => setShowForm(true))}>View History / Record Payment</Button>
      </TableCell>
    </TableRow>
    {showForm ?
      <>
        <TableRow>
          <TableCell colSpan={3}>
            <PaymentHistory duesSummary={holder.duesSummary}/>
            <ReceivePaymentForm holder={holder} paymentRecordedCallback={recordPayment}/>
            <CancelButton style={{marginTop: 15}} onClick={(e => setShowForm(false))}>Cancel</CancelButton>
          </TableCell>
        </TableRow>
      </>
      : null}
  </>

}

export function PaymentHistory({duesSummary}) {

  if (!duesSummary || !duesSummary.payments) return null;

  return <>
    <Typography variant={"h6"} gutterBottom>Payment History</Typography>
    {
      duesSummary.payments.map((pmt, idx) => {
        const isoString = pmt.datePaid;
        const datePaid = isoString.substring(0, isoString.indexOf("T") );
        return <Card key={idx} style={{marginBottom: 10}}>
          <CardContent>
            <Typography variant={"body2"}><strong>Date: </strong>{datePaid}</Typography>
            <Typography variant={"body2"}><strong>Amount: </strong>
              <NumberFormat value={pmt.paymentAmount} displayType={'text'} prefix={'$'} thousandSeparator={true}
                            decimalScale={2}/></Typography>
            <Typography variant={"body2"}><strong>Payment method: </strong>{pmt.paymentMethod}</Typography>
            <Typography variant={"body2"}><strong>Payment confirmation no: </strong>{pmt.paymentReferenceNumber}
            </Typography>
          </CardContent>
        </Card>
      })
    }
  </>
}

function ReceivePaymentForm(props) {
  const {
    holder,
    paymentRecordedCallback
  } = props;
  const [{notifications}, setNotifications] = useAppContext();
  const [paymentInfo, setPaymentInfo] = useState({})
  const submitPayment = (event) => {
    event.preventDefault();
    const duesPayment = paymentInfo;
    duesPayment.type = "duespayment"
    duesPayment.id = -1
    duesPayment.org = holder.profile.oid
    duesPayment.pid = holder.profile.pid;
    duesPayment.seasonId = holder.duesSummary.season.id
    duesPayment.datePaid = new Date()

    console.log("Payment info", duesPayment)
    notifications.push({
      severity: "info",
      body: "Processing the payment..."
    })
    setNotifications(notifications)

    fetchwmp(`/api/ensemble/dues/record-payment`, {
      method: "PUT",
      body: prepareEntity(duesPayment)
    })
      .then((res) => {
          notifications.push({
            severity: "success",
            body: `The payment of '${duesPayment.paymentAmount}' has been recorded.`
          })
          setNotifications(notifications)
          paymentRecordedCallback(duesPayment.paymentAmount)
        }, (error => {
          console.error("error recording payment", error)
          switch (error.status) {
            case 400:
              notifications.push({
                severity: "error",
                body: "Saving the payment failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
              break;
            default:
              notifications.push({
                severity: "error",
                body: "Saving the payment failed unexpectedly. Please contact the administrator."
              })
              setNotifications(notifications)
          }
        })
      )
  }

  if (!holder) return null;
  return <>
    <Typography variant={"h6"} gutterBottom>Record a payment</Typography>
    <form onSubmit={submitPayment}>
      <FormControl fullWidth style={{marginBottom: 15}}>
        <input type={"date"} id={`${holder.profile.pid}_payment_info_date`} onChange={(e) => {
          const newpi = paymentInfo;
          newpi.datePaid = e
          setPaymentInfo(newpi);
        }} />
      </FormControl>
      <FormControl fullWidth style={{marginBottom: 15}}>
        <TextField id={`${holder.profile.pid}_payment_info_amount`}
                   label="Amount Received"
                   required={true}
                   variant="outlined"
                   multiline={false}
                   onChange={(e) => {
                     const newpi = paymentInfo;
                     newpi.paymentAmount = e.target.value
                     setPaymentInfo(newpi);
                   }}
        />
      </FormControl>

      <FormControl fullWidth style={{marginBottom: 15}}>
        <InputLabel variant="outlined" id={`${holder.profile.pid}_payment_info_type_label`}>
          Payment Method
        </InputLabel>
        <Select
          id={`${holder.profile.pid}_payment_info_type`}
          variant="outlined"
          required={true}
          value={paymentInfo.paymentMethod}
          label={"Payment Method"} // length needs to match the InputLabel's string to ensure that a gap is left
          labelId={`${holder.profile.pid}_payment_info_type_label`}
          onBlur={(e) => {
            const newpi = paymentInfo;
            newpi.paymentMethod = e.target.value
            setPaymentInfo(newpi);
          }}
        >
          <MenuItem value={"paypal"}>Paypal</MenuItem>
          <MenuItem value={"cash"}>Cash</MenuItem>
          <MenuItem value={"cheque"}>Cheque</MenuItem>
          <MenuItem value={"etransfer"}>e-transfer</MenuItem>
          <MenuItem value={"partial"}>Partial</MenuItem>
          <MenuItem value={"exempt"}>Exempt</MenuItem>
          <MenuItem value={"special note"}>Special Note</MenuItem>
        </Select>
        <FormHelperText>Please select the payment type. If the member is exempted from paying dues, choose "exempt". If
          the member has made special arrangements regarding the payment, indicate that using "special
          note".</FormHelperText>
      </FormControl>

      <FormControl fullWidth style={{marginBottom: 15}}>
        <TextField id={`${holder.profile.pid}_payment_info_refno`}
                   label="Payment reference number / special note"
                   helperText={paymentInfo.method === "special note" ? "Please provide details of the special note here. This will be sent to the member in their receipt." : "Please provide a transaction reference number here. This will be sent to the member in their receipt."}
                   required={true}
                   variant="outlined"
                   multiline={true} rows={4} rowsMax={10}
                   onChange={(e) => {
                     const newpi = paymentInfo;
                     newpi.paymentReferenceNumber = e.target.value
                     setPaymentInfo(newpi);
                   }}
        />
      </FormControl>

      <Button type="submit">Submit Payment</Button>
    </form>

  </>
}
