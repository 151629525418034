import React from 'react';
import {CookiesProvider} from 'react-cookie';
import {GlobalContextProvider} from "./core/common/GlobalContext";
import DevelopmentSiteSwitcher from "./core/common/DevelopmentSiteSwitcher";
import AppInfo from "./core/common/AppInfo";
import Authenticated from "./core/common/Authenticated";
import EnsembleApp from "./ensemble/EnsembleApp";
import {ConfirmProvider} from "material-ui-confirm";
import WarholThemeProvider from "./core/common/theme/WarholThemeProvider";
import BaselineApplicationEnvironment from "./core/common/BaselineApplicationEnvironment";
import {AppContextProvider} from "./core/common/AppContext";
import {BrowserRouter} from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

function App() {
    return (
        <CookiesProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ConfirmProvider>
                    <BrowserRouter>
                        {/*Custom Logic*/}
                        <GlobalContextProvider>
                            <DevelopmentSiteSwitcher>
                                <AppInfo>{/* get the org so app can init */}
                                    <Authenticated> {/* get profile */}
                                        <BaselineApplicationEnvironment>
                                            <WarholThemeProvider>
                                                {/*<AppContextProvider>*/}
                                                {/*    <AdminApp/>*/}
                                                {/*</AppContextProvider>*/}
                                                <AppContextProvider>
                                                    <EnsembleApp/>
                                                </AppContextProvider>
                                                {/*<AppContextProvider>*/}
                                                {/*    <SwimApp/>*/}
                                                {/*</AppContextProvider>*/}
                                            </WarholThemeProvider>
                                        </BaselineApplicationEnvironment>
                                    </Authenticated>
                                </AppInfo>
                            </DevelopmentSiteSwitcher>
                        </GlobalContextProvider>
                    </BrowserRouter>
                </ConfirmProvider>
            </MuiPickersUtilsProvider>
        </CookiesProvider>
    );
}

export default App;
