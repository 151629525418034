import React, {useEffect, useState} from 'react';
import fetchw from "../../util/fetchw";
import Layout from "../common/Layout";
import Typography from "@material-ui/core/Typography";
import Loading from "../../core/common/Loading";

export default function AllUsersCsv() {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        if (!data) {
            // console.log("About to load data")
            fetchw(`/api/ensemble/reports/profiles/list/all`)
                .then((res) => {
                    setData(res);
                    // console.log("Loaded data!", res)
                }, (error) => {
                    console.log("Error loading all user data ", error)
                    setError(error)
                })
        }
    }, [data, setData])

    if (error) {
        return <Layout>
            <Typography variant={"h6"} color={"secondary"}>Administrative Report</Typography>
            <Typography variant={"h3"} gutterBottom>All Members Report (all seasons)</Typography>
            <Typography>An error has occurred and hte report cannot be loaded.</Typography>
            <pre>
                {JSON.stringify(error)}
            </pre>
        </Layout>
    }

    if (data) {
        let header = null
        const rows = []

        data.map((profile, idx) => {
            // console.log("PROFILE", profile)
            const p = profile.uiprofile
            const row = []
            const h = []
            h.push("First Name")
            row.push(p.fname)
            h.push("Last Name")
            row.push(p.lname)
            h.push("Stage Name")
            row.push(p.fallbackStageName)
            h.push("Instrument")
            row.push(p.instrument)
            h.push("Email")
            row.push(p.email)
            h.push("Phone")
            row.push(p.phone)
            let didReg = false
            profile.seasons.map((s, k) => {
                h.push(seasonName(s))
                row.push(s.didRegister ? "YES" : " ")
                if (s.didRegister) {
                    didReg = true
                }
            })
            if (header === null) {
                header = h
            }
            if (didReg) {
                rows.push(row)
            }


        })
        return <Typography>
            <div>
                {
                    header.map((s, k) => {
                        return <>{s},</>
                    })
                }<br/>
                {
                    rows.map((r, k) => {
                        return <> {r.map((f, j) => {
                            return <>{f},</>
                        })}<br/></>
                    })
                }
            </div>
        </Typography>
    } else {
        return <Loading msg="all users report"/>
    }


}

const seasonName = (duesSummary) => {
    // console.log(duesSummary)
    const startDate = duesSummary.season.seasonStart.substring(0, 4)
    const endDate = duesSummary.season.seasonEnd.substring(0, 4)
    return startDate + '-' + endDate
}